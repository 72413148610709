import Actions from 'actions'
import { push } from 'connected-react-router'
import { ROUTES } from 'consts'
import { TimelineItemTypes } from 'consts/timelineConsts'
import * as timelineGraphql from 'graphql/timeline.graphql'
import { ofType } from 'redux-observable'
import { concat, from, of } from 'rxjs'
import { catchError, filter, map, mergeMap, pluck, switchMap, tap, withLatestFrom } from 'rxjs/operators'
import { fetchAll } from 'utils/graphqlUtils'
import { logInfo } from 'utils/logUtils'
import queryString from 'query-string'
import { API, graphqlOperation } from 'aws-amplify'
import i18n from 'resources/locales/i18n'

export const fetchPatientTimelineItemsEpic = action$ =>
  action$.pipe(
    ofType(Actions.FETCH_PATIENT_TIMELINE_ITEMS),
    pluck('payload'),
    switchMap(({ patientId, startTime = new Date().getTime() }) =>
      from(
        fetchAll(
          timelineGraphql.timelineItemsByPatientIdSorted(timelineGraphql.mainFeed.timelineItem),
          {
            patientId,
            sortDirection: 'DESC',
            limit: 500
          },
          'timelineItemsByPatientIdSorted'
        )
      ).pipe(
        map(timelineItems => timelineItems.filter(item => !item._deleted)),
        tap((timelineItems = []) =>
          logInfo(`FETCH_PATIENT_TIMELINE_ITEMS_RECEIVED`, {
            took: new Date().getTime() - startTime,
            timelineItemsCount: timelineItems.length,
            sizeInKilobytes: JSON.stringify(timelineItems).length / 1024
          })
        ),
        mergeMap(timelineItems =>
          of(
            Actions.fetchPatientTimelineItemsReceived({
              data: timelineItems,
              nextToken: null
            })
          )
        ),
        catchError(err => of(Actions.fetchPatientTimelineItemsFailed(err)))
      )
    )
  )

export const navigateToLatestScanItem = action$ =>
  action$.pipe(
    ofType(Actions.FETCH_PATIENT_TIMELINE_ITEMS_RECEIVED),
    filter(() => window.location.pathname.startsWith(ROUTES.PATIENTS)),
    pluck('payload', 'data'),
    map(timelineItems => ({
      timelineItems,
      latestScanItem: timelineItems.find(item => item.type === TimelineItemTypes.GrinScan),
      queryParams: queryString.parse(window.location.search)
    })),
    filter(({ queryParams, timelineItems }) => {
      const currentlySelectedTimelineItem = queryParams.timelineItem
      const doesCurrentlySelectedTimelineItemPresent = timelineItems.find(
        item => item.id === currentlySelectedTimelineItem
      )
      return !currentlySelectedTimelineItem || !doesCurrentlySelectedTimelineItemPresent
    }),
    map(({ latestScanItem, queryParams }) =>
      push(
        `${window.location.pathname}?${queryString.stringify({
          ...queryParams,
          timelineItem: latestScanItem?.id
        })}`
      )
    )
  )

export const fetchTimelineItemEpic = action$ =>
  action$.pipe(
    ofType(Actions.FETCH_TIMELINE_ITEM),
    pluck('payload'),
    switchMap(({ timelineItemId, startTime = new Date().getTime() }) =>
      from(
        API.graphql(
          graphqlOperation(timelineGraphql.getTimelineItem(timelineGraphql.scanFeed.timelineItem), {
            id: timelineItemId
          })
        )
      ).pipe(
        tap(() =>
          logInfo(`FETCH_TIMELINE_ITEM_RECEIVED`, {
            took: new Date().getTime() - startTime,
            timelineItemId
          })
        ),
        map(res => res.data.getTimelineItem),
        mergeMap(timelineItem => of(Actions.fetchTimelineItemReceived({ timelineItem }))),
        catchError(ex => of(Actions.fetchTimelineItemFailed(ex)))
      )
    )
  )

export const scanReviewReceivedLiveUpdatesEpic = (action$, state$) =>
  action$.pipe(
    ofType(Actions.NOTIFICATION_RECEIVED),
    pluck('payload'),
    filter(({ entityType, method }) => entityType === 'ScanReview' && method === 'INSERT'),
    withLatestFrom(state$),
    map(([payload, state]) => ({
      scanReviewId: payload.scanReviewId,
      isTimelineV2: state.timelineReducer.isTimelineV2
    })),
    filter(({ isTimelineV2 }) => isTimelineV2),
    mergeMap(({ scanReviewId }) =>
      from(
        API.graphql(
          graphqlOperation(timelineGraphql.getScanReview(timelineGraphql.scanFeed.scanReview), {
            id: scanReviewId
          })
        )
      ).pipe(
        map(res => res.data.getScanReview),
        mergeMap(scanReview => of(Actions.newScanReviewReceived({ scanReview }))),
        catchError(ex =>
          of(Actions.notificationReceivedFailed({ scanReviewId, message: 'failed to fetch scan review' }))
        )
      )
    )
  )

export const timelineItemLiveUpdatesEpic = (action$, state$) =>
  action$.pipe(
    ofType(Actions.NOTIFICATION_RECEIVED),
    pluck('payload'),
    filter(({ eventType }) => eventType === 'timelineItemCreated' || eventType === 'timelineItemUpdated'),
    withLatestFrom(state$),
    map(([payload, state]) => ({
      timelineItemId: payload.timelineItemId,
      patientId: payload.patientId,
      isTimelineV2: state.timelineReducer.isTimelineV2,
      currentlyActivePatientId: state.patientsReducer.patient?.id,
      isTimelineItemInScanFeed: state.timelineReducer.scanFeed.timelineItem?.id === payload.timelineItemId
    })),
    filter(
      ({ isTimelineV2, currentlyActivePatientId, patientId }) => isTimelineV2 && currentlyActivePatientId === patientId
    ),
    switchMap(({ timelineItemId, isTimelineItemInScanFeed }) =>
      from(
        API.graphql(
          graphqlOperation(
            timelineGraphql.getTimelineItem(
              isTimelineItemInScanFeed ? timelineGraphql.scanFeed.timelineItem : timelineGraphql.mainFeed.timelineItem
            ),
            {
              id: timelineItemId
            }
          )
        )
      ).pipe(
        map(res => res.data.getTimelineItem),
        mergeMap(timelineItem => of(Actions.timelineItemLiveUpdateReceived({ timelineItem }))),
        catchError(ex =>
          of(Actions.notificationReceivedFailed({ timelineItemId, message: 'failed to fetch timeline item' }))
        )
      )
    )
  )

export const timelineSwitchScanWithAlignersEpic = action$ =>
  action$.pipe(
    ofType(Actions.TIMELINE_SWITCH_SCAN_WITH_ALIGNERS),
    pluck('payload'),
    switchMap(({ grinScanId, newWithAlignerValue }) =>
      from(
        API.put('grinServerlessApi', '/treatments/v2/scans/withAligner', {
          body: {
            grinScanId,
            withAligner: newWithAlignerValue
          }
        })
      ).pipe(
        mergeMap(res =>
          concat(
            of(Actions.timelineSwitchScanWithAlignersReceived()),
            of(
              Actions.showSnackbar({
                type: 'success',
                text: i18n.t('messages.grinScan.scanUpdatedSuccessfully')
              })
            )
          )
        ),
        catchError(err =>
          concat(
            of(
              Actions.showSnackbar({
                type: 'error',
                text: i18n.t('errors.failedToUpdateScan')
              })
            ),
            of(Actions.timelineSwitchScanWithAlignersFailed(err))
          )
        )
      )
    )
  )

export const timelineItemFetchOralRecordsTrigger = action$ =>
  action$.pipe(
    ofType(Actions.FETCH_TIMELINE_ITEM_RECEIVED),
    pluck('payload'),
    filter(
      ({ timelineItem }) =>
        timelineItem?.grinScan && JSON.parse(timelineItem.grinScan.metadata || '{}')?.isServiceAccount
    ),
    map(({ timelineItem }) => Actions.timelineFetchOralRecords({ initialScanId: timelineItem.grinScan.id }))
  )

export const timelineFetchOralRecordsEpic = action$ =>
  action$.pipe(
    ofType(Actions.TIMELINE_FETCH_ORAL_RECORDS),
    pluck('payload'),
    switchMap(({ initialScanId }) =>
      from(
        API.graphql(
          graphqlOperation(timelineGraphql.getInitialScan(timelineGraphql.scanFeed.initialScan), {
            id: initialScanId
          })
        )
      ).pipe(
        mergeMap(res =>
          of(
            Actions.timelineFetchOralRecordsReceived({
              initialScan: res.data.getInitialScan
            })
          )
        ),
        catchError(err => of(Actions.timelineFetchOralRecordsFailed(err)))
      )
    )
  )

export const timelineCompositeImageLiveUpdatesEpic = (action$, state$) =>
  action$.pipe(
    ofType(Actions.NOTIFICATION_RECEIVED),
    withLatestFrom(state$),
    filter(([action, state]) => {
      const timelineItemPayload = JSON.parse(state.timelineReducer.scanFeed.timelineItem?.payload || '{}')
      return (
        action.payload.eventType === 'compositeImageCreated' &&
        state.timelineReducer.isTimelineV2 &&
        timelineItemPayload.scans?.find(scan => scan.id === action.payload.grinScanId)
      )
    }),
    map(([action]) => Actions.timelineFetchOralRecords({ initialScanId: action.payload.initialScanId }))
  )

export const selectedTimelineItemByScanIdEpic = (action$, state$) =>
  action$.pipe(
    ofType(Actions.SELECT_TIMELINE_ITEM_BY_SCAN_ID),
    withLatestFrom(state$),
    map(([action, state]) => {
      const timelineItems = state.timelineReducer.mainFeed.timelineItems.data
      const grinScanId = action.payload.grinScanId
      const associatedTimelineItem = timelineItems.find(item =>
        JSON.parse(item.payload || '{}').scans?.find(scan => scan.id === grinScanId)
      )

      return {
        timelineItem: associatedTimelineItem,
        pathname: state.router.location.pathname,
        currentQueryString: state.router.location.search
      }
    }),
    filter(({ timelineItem }) => !!timelineItem),
    map(({ timelineItem, currentQueryString, pathname }) => {
      const queryParams = queryString.parse(currentQueryString)
      const newQueryParams = {
        ...(queryParams || {}),
        timelineItem: timelineItem.id
      }

      return push({
        pathname,
        search: queryString.stringify(newQueryParams)
      })
    })
  )
