import { GrinNotificationTypes } from '@grin-rnd/grin-api-sdk/dist/Enums/Notifications'

export const NOTIFICATION_CENTER_MAX_NOTIFICATIONS_ON_BELL = 99
export const NOTIFICATION_CENTER_POLLING_INTERVAL_MS = 30 * 1000
export const NOTIFICATION_CENTER_PAGE_SIZE = 25

export const NotificationCenterTabs = {
  All: 'all',
  Scans: 'scans',
  Messages: 'messages',
  Assigned: 'assigned',
  NewPatients: 'newPatients',
  NeedsAttention: 'needsAttention'
}

export const NotificationCenterTabsConfiguration = {
  [NotificationCenterTabs.All]: {
    NotificationTypes: []
  },
  [NotificationCenterTabs.Scans]: {
    NotificationTypes: [GrinNotificationTypes.NewScan, GrinNotificationTypes.UrgentScan]
  },
  [NotificationCenterTabs.Messages]: {
    NotificationTypes: [GrinNotificationTypes.NewMessage]
  },
  [NotificationCenterTabs.Assigned]: {
    NotificationTypes: [GrinNotificationTypes.PatientAssigned]
  },
  [NotificationCenterTabs.NewPatients]: {
    NotificationTypes: [GrinNotificationTypes.PatientAssigned]
  },
  [NotificationCenterTabs.NewPatients]: {
    NotificationTypes: [
      GrinNotificationTypes.PatientJoined,
      GrinNotificationTypes.NewRcLead,
      GrinNotificationTypes.NewRecordsAppLead
    ]
  },
  [NotificationCenterTabs.NeedsAttention]: {
    NotificationTypes: [GrinNotificationTypes.NeedsAttention]
  }
}
