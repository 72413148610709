import React, { useCallback, useMemo, useState } from 'react'
import { makeStyles } from '@material-ui/styles'
import { Fade, Grid } from '@material-ui/core'
import CreatorInfo from './CreatorInfo'
import NoteItemActions from './NoteItemActions'
import { trackEvent } from 'utils/analyticsUtils'
import NoteBody from './NoteBody'
import { useUpdateNoteMutation } from 'store/modules/PatientNotes'
import DeleteNoteModal from './DeleteNoteModal'
import useShowSnackbar from 'hooks/useShowSnackbar'
import { useTranslation } from 'react-i18next'

const useStyles = makeStyles(theme => ({
  noteItemRoot: {
    position: 'relative',
    width: '100%',
    backgroundColor: ({ isHovered }) => (isHovered ? '#e5e5e5' : '#F3F3F4'),
    borderRadius: 10,
    padding: 5,
    marginBottom: 12,
    transition: 'background-color 0.3s ease-in-out'
  }
}))

const NoteItem = React.forwardRef(
  ({ id, message, patientGrinUserId, isPinned, metadata, updatedAt, isEditable }, ref) => {
    const { t } = useTranslation()

    const [isHovered, setIsHovered] = useState(false)
    const [isEditMode, setIsEditMode] = useState(false)
    const [currentMessage, setCurrentMessage] = useState(message)
    const [isDeleteNoteModalOpen, setIsDeleteModalOpen] = useState(false)

    const shouldDisplayActions = useMemo(() => isHovered && !isEditMode, [isEditMode, isHovered])

    const classes = useStyles({ isHovered })

    const [updateNote, { isError: isFailedUpdatingNote }] = useUpdateNoteMutation()
    useShowSnackbar({
      isOpen: isFailedUpdatingNote,
      message: t('pages.patients.patientSwitchableView.notes.generalError')
    })

    const { creatorName, creatorRoleDescription, creatorUserId, assigneeName } = useMemo(() => {
      const { creatorName, creatorRoleDescription, creatorUserId, assigneeName } = JSON.parse(metadata || '{}')
      return {
        creatorName: creatorName ? creatorName.trim() : '',
        creatorRoleDescription: creatorRoleDescription ? creatorRoleDescription.trim() : '',
        creatorUserId: creatorUserId ? creatorUserId.trim() : '',
        assigneeName: assigneeName ? assigneeName.trim() : ''
      }
    }, [metadata])

    const handleToggleEditMode = useCallback(() => {
      trackEvent('Patient notes - Edit mode toggled', {
        toggle: !isEditMode ? 'off' : 'true'
      })
      setIsEditMode(!isEditMode)
    }, [isEditMode])

    const handleMessageSaved = useCallback(async () => {
      trackEvent('Patient notes - New message saved')
      setIsEditMode(!isEditMode)
      await updateNote({ id, variables: { body: currentMessage, grinUserId: patientGrinUserId } })
    }, [currentMessage, patientGrinUserId, id, isEditMode, updateNote])

    const handleMessageDiscarded = useCallback(() => {
      trackEvent('Patient notes - Message discarded')
      setCurrentMessage(message)
      setIsEditMode(false)
    }, [message])

    const handleDeleteNoteClicked = useCallback(() => {
      trackEvent('Patient notes - Delete note modal opened')
      setIsDeleteModalOpen(true)
    }, [])

    const handleTogglePinnedNote = useCallback(async () => {
      trackEvent('Patient note: Note pin toggled', {
        noteId: id,
        isPinned: !isPinned,
        source: 'Switchable view'
      })
      await updateNote({ id, variables: { isPinned: !isPinned, grinUserId: patientGrinUserId } })
    }, [patientGrinUserId, id, isPinned, updateNote])

    return (
      <>
        <Grid
          container
          direction="column"
          className={classes.noteItemRoot}
          ref={ref}
          onMouseEnter={() => setIsHovered(true)}
          onMouseLeave={() => setIsHovered(false)}
        >
          <Grid item>
            <CreatorInfo
              userId={creatorUserId}
              name={creatorName}
              roleDescription={creatorRoleDescription}
              updatedAt={updatedAt}
            />
          </Grid>
          <Grid item>
            <NoteBody
              isEditMode={isEditMode}
              message={currentMessage}
              setMessage={setCurrentMessage}
              assigneeName={assigneeName}
              onMessageSaved={handleMessageSaved}
              onMessageDiscarded={handleMessageDiscarded}
            />
          </Grid>
          {shouldDisplayActions && (
            <Fade in={shouldDisplayActions} timeout={500}>
              <Grid item>
                <NoteItemActions
                  isEditable={isEditable}
                  isPinned={isPinned}
                  onToggleEditMode={handleToggleEditMode}
                  onDeleteNoteClicked={handleDeleteNoteClicked}
                  onTogglePinnedNoteClicked={handleTogglePinnedNote}
                />
              </Grid>
            </Fade>
          )}
        </Grid>
        {isDeleteNoteModalOpen && (
          <DeleteNoteModal isOpen={isDeleteNoteModalOpen} setIsOpen={setIsDeleteModalOpen} noteId={id} />
        )}
      </>
    )
  }
)

export default NoteItem
