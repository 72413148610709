import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Grid } from '@material-ui/core'
import { useStyles } from './EditDialogStyle'
import { useTranslation } from 'react-i18next'
import Actions from 'actions'
import PrimaryButton from 'components/common/buttons/PrimaryButton'
import FormInput from 'components/common/FormInput'
import { trackEvent } from 'utils/analyticsUtils'

const PmsIntegrationsTab = () => {
  const classes = useStyles()
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const user = useSelector(state => state.supportReducer.doctorsDashboard.selectedDoctor?.user)
  const accountOwner = useSelector(state =>
    state.supportReducer.doctorsDashboard.selectedDoctor.accountOwnerId
      ? state.supportReducer.doctorsDashboard.selectedDoctor.accountOwner
      : state.supportReducer.doctorsDashboard.selectedDoctor || {}
  )

  const [dolphinClientId, setDolphinClientId] = useState('')

  const externalIds = useMemo(() => JSON.parse(user?.externalIds || '{}'), [user])

  const handleSave = useCallback(() => {
    trackEvent('Support - Edit doctor Pms Integration - saved clicked', {
      userId: user.id,
      practiceName: accountOwner.clinic?.practiceName
    })

    dispatch(
      Actions.supportUpdateUser({
        id: user.id,
        _version: user._version,
        externalIds: JSON.stringify({
          ...externalIds,
          dolphin: dolphinClientId
        })
      })
    )
  }, [dispatch, user, externalIds, dolphinClientId, accountOwner])

  useEffect(() => {
    setDolphinClientId(externalIds.dolphin)
  }, [externalIds])

  return (
    <Grid
      container
      justifyContent="space-between"
      direction="column"
      alignItems="center"
      className={classes.tabWithButtonContainer}
    >
      <Grid item className={classes.tabWithButtonContent}>
        <Grid container direction="column" spacing={2}>
          <Grid item xs={4} style={{ width: '100%' }}>
            <FormInput
              title={t('dialogs.doctorCardEditor.dolphinClientId')}
              placeholder={t('dialogs.doctorCardEditor.dolphinClientIdPlaceholder')}
              style={{ bright: true, thick: true }}
              value={dolphinClientId}
              setValue={setDolphinClientId}
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid item>
        <PrimaryButton label={t('general.save')} onClick={handleSave} />
      </Grid>
    </Grid>
  )
}

export default PmsIntegrationsTab
