import { makeStyles } from '@material-ui/core'
import DazzedParagraph14 from 'components/common/text/DazzedParagraph14'
import React, { useMemo } from 'react'

const useStyles = makeStyles(theme => ({
  noteItemRoot: {
    width: '100%',
    backgroundColor: '#F3F3F4',
    borderRadius: 10,
    padding: 10,
    marginBottom: 12
  },
  message: {
    marginLeft: 50,
    marginTop: 3
  },
  assignee: {
    color: 'var(--text-color-16)',
    fontWeight: 600
  }
}))

const Message = ({ message, assigneeName }) => {
  const classes = useStyles()

  const formattedMessage = useMemo(() => message.split('\n').map((line, index) => line || <br />), [message])
  const formattedAssignee = useMemo(() => !!assigneeName && `@${assigneeName} `, [assigneeName])

  return (
    <DazzedParagraph14 strong className={classes.message}>
      <span className={classes.assignee}>{formattedAssignee}</span>
      {formattedMessage}
    </DazzedParagraph14>
  )
}

export default Message
