import Actions from 'actions'
import { API, graphqlOperation } from 'aws-amplify'
import { push } from 'connected-react-router'
import { AWS_DATE_TIME } from 'consts/dateTimeConsts'
import ROUTES from 'consts/routesConsts'
import { saveAs } from 'file-saver'
import JSZip from 'jszip'
import moment from 'moment'
import { ofType } from 'redux-observable'
import { concat, empty, from, of } from 'rxjs'
import {
  catchError,
  filter,
  flatMap,
  ignoreElements,
  map,
  mapTo,
  mergeMap,
  pluck,
  switchMap,
  tap,
  withLatestFrom
} from 'rxjs/operators'
import { trackEvent } from 'utils/analyticsUtils'
import { calculateAvailablePatientSlots, isOnLimitedSeatsPlan, isOnFreePlan } from 'utils/billingUtils'
import { mapToCreateInitialScanInput } from 'utils/mappers/initialScansMappers'
import { mapToCreateNoteInput, mapToNoteAnalyticsPayload } from 'utils/mappers/noteMappers'
import {
  mapLeadDetailsToPatient,
  mapLeadSMToPatientSM,
  mapLeadsSMToPatientsSM,
  mapLeadToPatient,
  mapToPatientDto,
  mapToUpdateLeadInput,
  mapToUpdatePatientInput
} from 'utils/mappers/patientsMapper'
import { isMobile } from 'utils/mobileUtils'
import { stripToS3Object } from 'utils/storageUtils'
import {
  createPatientBrief,
  getPatientSearchModel,
  grinScansByLeadId,
  invitePatientSearchPatientSearchModel,
  patientBriefsByPatientIdSorted,
  searchLeadSearchModels,
  searchPatientsByTag,
  searchPatientSearchModelForLinkedAccounts,
  searchSiblingSearchModels,
  searchSiblingSearchModelsForResendInvite,
  updateLead,
  updatePatientForDoctorCard
} from '../graphql/customQueries'
import {
  createAppointment,
  createInitialScan,
  createUserNote,
  deleteAppointment,
  deleteInitialScan,
  deleteUserNote,
  updateAppointment,
  updateInitialScan,
  updateUserNote
} from '../graphql/mutations'
import { appointmentsByPatientId, getInitialScan, userNotesSorted } from '../graphql/queries'
import i18n from '../resources/locales/i18n'
import { downloadMedia } from 'utils/mediaUtils'
import { dataUrlToBase64 } from 'utils/fileUtils'
import { logError, logInfo } from 'utils/logUtils'
import fileExtension from 'file-extension'
import { fetchAll, GraphQLMutationTypes, mutateEntity } from 'utils/graphqlUtils'
import { mapToGrinScansDto } from 'utils/mappers/treatmentMapper'
import { PatientBriefModalMode } from 'consts/hiToolsConsts'
import { getPatientWithRoomAndScans } from 'utils/patientUtils'
import { v4 } from 'uuid'

export const requestPatientEpic = (action$, state$) =>
  action$.pipe(
    ofType(Actions.PATIENT_REQUESTED),
    pluck('payload', 'patientId'),
    withLatestFrom(state$),
    switchMap(([id, { chatReducer }]) => {
      return from(
        API.graphql(
          graphqlOperation(searchLeadSearchModels, {
            filter: {
              id: {
                eq: id
              }
            },
            limit: 20
          })
        )
      ).pipe(
        withLatestFrom(state$),
        switchMap(([patientSMResult, { treatmentReducer }]) => {
          const leadSM = patientSMResult?.data?.searchPatientSearchModels?.items?.[0]
          if (!leadSM) {
            logInfo(`patient not found: ${id}`, { patientId: id })
            return of(null)
          }

          if (!leadSM?.patient) {
            const leadStatusType = leadSM.lead.conversionStatus === 'added' ? 'records-only' : 'invited'

            return of(
              mapLeadToPatient({
                lead: leadSM?.lead,
                patientSM: leadSM,
                leadStatus: treatmentReducer.statuses?.data[leadSM?.lead.program].find(
                  status => status.type === leadStatusType
                )
              })
            )
          } else {
            return from(getPatientWithRoomAndScans(id)).pipe(map(mapToPatientDto))
          }
        }),
        mergeMap(patient => of(Actions.patientReceived({ patient }))),
        catchError(error => of(Actions.fetchPatientFailed(error)))
      )
    })
  )

export const triggerFetchPatientBriefEpic = action$ =>
  action$.pipe(
    ofType(Actions.PATIENT_REQUESTED),
    pluck('payload'),
    mergeMap(({ patientId }) => of(Actions.fetchPatientBrief({ patientId })))
  )

export const fetchPatientBriefEpic = (action$, state$) =>
  action$.pipe(
    ofType(Actions.FETCH_PATIENT_BRIEF),
    pluck('payload'),
    switchMap(({ patientId, nextToken }) =>
      from(
        API.graphql(
          graphqlOperation(patientBriefsByPatientIdSorted, {
            nextToken,
            patientId,
            sortDirection: 'DESC',
            limit: 10
          })
        )
      ).pipe(
        mergeMap(({ data }) => of(Actions.fetchPatientBriefReceived(data.patientBriefsByPatientIdSorted))),
        catchError(error => of(Actions.fetchPatientBriefFailed(error)))
      )
    )
  )

export const fetchPatientFailed = action$ =>
  action$.pipe(
    ofType(Actions.FETCH_PATIENT_FAILED),
    mapTo(
      Actions.showSnackbar({
        type: 'error',
        text: i18n.t('messages.somethingWentWrongContactSupport')
      })
    )
  )

export const triggerFetchPatientNotesEpic = action$ =>
  action$.pipe(
    ofType(Actions.PATIENT_RECEIVED),
    pluck('payload'),
    filter(({ patient }) => patient?.user?.id),
    mergeMap(({ patient }) => of(Actions.fetchPatientNotes({ patientGrinUserId: patient.user.id })))
  )

export const searchPatientsFailedEpic = action$ =>
  action$.pipe(
    ofType(Actions.SERACH_PATIENTS_FAILED),
    map(({ errorMessage }) =>
      Actions.showSnackbar({
        type: 'error',
        text: errorMessage || i18n.t('messages.somethingWentWrongContactSupport')
      })
    )
  )

export const onPatientSearchModelDeletedNotificationReceivedEpic = (action$, state$) =>
  action$.pipe(
    ofType(Actions.NOTIFICATION_RECEIVED),
    pluck('payload'),
    filter(notification => notification.entityType === 'PatientSearchModel' && notification.method === 'DELETE'),
    mergeMap(notification => of(Actions.removeChatRoom({ patientId: notification.entityId })))
  )

export const onPatientModifiedNotificationReceivedEpic = (action$, state$) =>
  action$.pipe(
    ofType(Actions.NOTIFICATION_RECEIVED),
    withLatestFrom(state$),
    map(([action, state]) => ({
      notification: action.payload,
      rooms: state.chatReducer.rooms,
      treatmentReducer: state.treatmentReducer
    })),
    filter(
      ({ notification, rooms }) => notification.entityType === 'PatientSearchModel' && notification.method === 'MODIFY'
    ),
    filter(({ notification, rooms }) => rooms.some(patientSM => patientSM.id === notification.entityId)),
    switchMap(({ notification, rooms, treatmentReducer }) =>
      from(API.graphql(graphqlOperation(getPatientSearchModel, { id: notification.entityId }))).pipe(
        map(res => res.data.getPatientSearchModel),
        mergeMap(patientSM =>
          concat(
            of(Actions.updateRoom(patientSM.patient ? patientSM : mapLeadSMToPatientSM(patientSM))),
            of(Actions.requestUpdateOpenedPatient(patientSM.patient ? patientSM : mapLeadSMToPatientSM(patientSM)))
          )
        ),
        catchError(err => of(Actions.notificationReceivedFailed(err)))
      )
    )
  )

export const onNewPatientNotificationReceivedEpic = (action$, state$) =>
  action$.pipe(
    ofType(Actions.NOTIFICATION_RECEIVED),
    withLatestFrom(state$),
    map(([action, state]) => ({
      notification: action.payload,
      rooms: state.chatReducer.rooms,
      tagsFilter: state.chatReducer.filter.tagsFilter,
      searchFilter: state.chatReducer.filter.searchFilter
    })),
    filter(
      ({ notification, rooms }) => notification.entityType === 'PatientSearchModel' && notification.method === 'INSERT'
    ),
    mergeMap(({ notification, rooms, tagsFilter, searchFilter }) =>
      of(
        Actions.fetchRooms({
          newSearch: true,
          tagsFilter,
          addToTop: true,
          withoutLoader: true,
          searchFilter
        })
      )
    ),
    catchError(err => of(Actions.notificationReceivedFailed(err)))
  )

export const onPatientUpdateRequested = (action$, state$) =>
  action$.pipe(
    ofType(Actions.PATIENT_OPENED_UPDATE_REQUESTED),
    withLatestFrom(state$),
    filter(([action, state]) => state.patientsReducer?.patient.id === action.payload.id),
    map(([action, state]) => action.payload),
    withLatestFrom(state$),
    switchMap(([patientSM, { treatmentReducer }]) => {
      if (!patientSM.patient) {
        const leadStatusType = patientSM.lead.conversionStatus === 'added' ? 'records-only' : 'invited'
        return of(
          Actions.requestUpdateOpenedPatientReceived(
            mapLeadToPatient({
              lead: patientSM.lead,
              patientSM,
              leadStatus: treatmentReducer.statuses?.data[patientSM?.lead.program].find(
                status => status.type === leadStatusType
              )
            })
          )
        )
      }

      return from(getPatientWithRoomAndScans(patientSM.patient.id)).pipe(
        mergeMap(patientResult => of(Actions.requestUpdateOpenedPatientReceived(patientResult))),
        catchError(err => of(Actions.fetchRejected(err)))
      )
    })
  )

export const openInvitePatientModalRequestEpic = (action$, state$) =>
  action$.pipe(
    ofType(Actions.REQUEST_OPEN_INVITE_PATIENT_MODAL),
    withLatestFrom(state$),
    map(([, state]) => ({
      doctorsPlanOverrides: state.practiceReducer.billing.planOverrides,
      grinPlan: state.practiceReducer.billing.grinPlan,
      rooms: state.chatReducer.rooms,
      isOnFreePlan: isOnFreePlan(state.practiceReducer.billing.grinPlan)
    })),
    mergeMap(({ doctorsPlanOverrides, grinPlan, rooms, isOnFreePlan }) => {
      if (isOnFreePlan && calculateAvailablePatientSlots(doctorsPlanOverrides, grinPlan, rooms) <= 0) {
        return of(Actions.setUpgradePlanWarningModalVisibility(true))
      }
      return concat(of(Actions.fetchDoctorSeats()), of(Actions.setInvitePatientModalVisibility(true)))
    })
  )

export const showWarningWhenSeatsFetched = (action$, state$) =>
  action$.pipe(
    ofType(Actions.FETCH_DOCTOR_SEATS_RECEIVED),
    withLatestFrom(state$),
    map(([action, state]) => ({
      leftSeats: action.payload.left,
      isInvitePatientModalOpen: state.patientsReducer.invite.isModalOpen,
      isOnLimitedSeatsPlan: isOnLimitedSeatsPlan(state.practiceReducer.billing.grinPlan),
      enforceLegacyPlanSeatsLimitation: state.appReducer.appconfig.app?.gaFlags?.enforceLegacyPlanSeatsLimitation
    })),
    filter(
      ({ isOnLimitedSeatsPlan, leftSeats, isInvitePatientModalOpen, enforceLegacyPlanSeatsLimitation }) =>
        isInvitePatientModalOpen && enforceLegacyPlanSeatsLimitation && isOnLimitedSeatsPlan && leftSeats <= 0
    ),
    mergeMap(() =>
      concat(of(Actions.setUpgradePlanWarningModalVisibility(true)), of(Actions.setInvitePatientModalVisibility(false)))
    )
  )

export const inviteNewPatientEpic = action$ =>
  action$.pipe(
    ofType(Actions.INVITE_NEW_PATIENT),
    map(action => {
      const { options, ...invitationPayload } = action.payload
      return {
        invitationPayload: {
          ...invitationPayload,
          patientEmail: action.payload.patientEmail?.trim()
        },
        options
      }
    }),
    flatMap(({ invitationPayload, options }) => {
      const invitiationPayloadMapped = {
        ...invitationPayload,
        email: invitationPayload.patientEmail,
        firstName: invitationPayload.patientFirstName,
        lastName: invitationPayload.patientLastName,
        program: 'rm'
      }

      const inviteRequest = API.post('grinServerlessApi', '/accounts/v2/leads/patients', {
        body: invitiationPayloadMapped
      })

      return from(inviteRequest).pipe(
        mergeMap(invitedPatient =>
          of(
            Actions.inviteNewPatientReceived({
              mobilePopup: isMobile(),
              patientName: invitationPayload.patientName,
              patientId: invitedPatient.patientId,
              invitedPatient,
              options
            })
          )
        ),
        catchError(({ response }) => {
          if (response?.data?.code.includes('userAlreadyExist')) {
            return of(
              Actions.inviteNewPatientFailed({
                patientEmail: invitationPayload.patientEmail,
                patientName: invitationPayload.patientName,
                mobilePopup: isMobile(),
                ...(isMobile() ? {} : userAlreadyExistsError)
              })
            )
          } else {
            return of(
              Actions.inviteNewPatientFailed({
                patientEmail: invitationPayload.patientEmail,
                ...invitationWentWrongError
              })
            )
          }
        })
      )
    })
  )

export const invitePatientReceivedEpic = (action$, state$) =>
  action$.pipe(
    ofType(Actions.INVITE_NEW_PATIENT_RECEIVED),
    withLatestFrom(state$),
    map(([action, state]) => ({
      patientId: action.payload.patientId,
      invitedPatient: action.payload.invitedPatient,
      options: action.payload.options
    })),
    tap(({ patientId }) => trackEvent('Invite patient - invite succeed', { patientId })),
    mergeMap(({ patientId, invitedPatient, options }) =>
      from(API.graphql(graphqlOperation(getPatientSearchModel, { id: patientId }))).pipe(
        map(res => res.data.getPatientSearchModel),
        map(patientSM => mapLeadSMToPatientSM(patientSM)),
        catchError(error => of(Actions.fetchRejected(error))),
        mergeMap(patientSM =>
          !isMobile()
            ? concat(
                of(Actions.addNewPatientChat(patientSM)),
                of(Actions.showSnackbar(patientInvitedSuccess(patientSM.name))),
                options?.shouldOpenPatientBriefModal
                  ? of(
                      Actions.togglePatientBriefModal({
                        isOpen: true,
                        patientId,
                        patientName: patientSM?.name,
                        mode: PatientBriefModalMode.NewPatient,
                        doctorId: patientSM.doctorId,
                        a_doctor: patientSM.doctorUsername,
                        treatmentType: patientSM.treatmentType,
                        analyticsSource: 'invite-patient'
                      })
                    )
                  : empty()
              )
            : of(Actions.addNewPatientChat(patientSM))
        )
      )
    )
  )

export const invitePatientFailedEpic = (action$, state$) =>
  action$.pipe(
    ofType(Actions.INVITE_NEW_PATIENT_FAILED),
    filter(action => action.payload?.type),
    tap(type =>
      trackEvent('Invite patient - invite failed', {
        reason: type
      })
    ),
    map(action => Actions.showSnackbar(action.payload))
  )

export const resendInvitePatientEpic = (action$, state$) =>
  action$.pipe(
    ofType(Actions.RESEND_PATIENT_INVITATION_REQUESTED),
    pluck('payload'),
    flatMap(({ patientEmail, patientFirstName, patientLastName, patientName, plan, patientId, isLead, _version }) =>
      (isLead
        ? from(
            API.post('grinServerlessApi', '/accounts/v2/leads/invite/resend', {
              body: {
                leadId: patientId,
                firstName: patientFirstName,
                lastName: patientLastName,
                email: patientEmail
              }
            })
          )
        : from(
            API.put('grinApi', `/accounts/patients/rm/invite/${patientId}`, {
              body: {
                patientEmail: patientEmail?.trim(),
                patientFirstName,
                patientLastName,
                patientName,
                plan,
                patientId
              }
            })
          )
      ).pipe(
        mergeMap(({ wasNewPatientCreated, message }) =>
          of(
            Actions.resendPatientInvitationReceived({
              patientName,
              wasNewPatientCreated,
              patientId,
              message
            })
          )
        ),
        catchError(({ response }) =>
          of(
            Actions.resendPatientInvitationFailed({
              response,
              patientId,
              errorMessage:
                response?.data?.code === 'userAlreadyExists' &&
                i18n.t('messages.users.emailAlreadyExists', {
                  email: patientEmail
                })
            })
          )
        )
      )
    )
  )

export const resendInviteSuccessEpic = action$ =>
  action$.pipe(
    ofType(Actions.RESEND_PATIENT_INVITATION_RECEIVED),
    pluck('payload'),
    map(({ patientName, message, isSibling }) => {
      if (isSibling) {
        trackEvent('Linked accounts - resend sent to multiple siblings')
      }
      return Actions.showSnackbar({
        text: message || i18n.t('messages.users.patientInvitedSuccess', { name: patientName }),
        time: 8000
      })
    })
  )

export const resendInviteNewEmailEpic = (action$, state$) =>
  action$.pipe(
    ofType(Actions.RESEND_PATIENT_INVITATION_RECEIVED),
    withLatestFrom(state$),
    map(([action, state]) => ({
      patientId: action.payload.patientId,
      activePatient: state.patientsReducer.patient
    })),
    filter(({ patientId, activePatient }) => patientId === activePatient?.id),
    map(({ activePatient }) => Actions.requestPatientTreatment(activePatient))
  )

export const deletePatientEpic = (action$, state$) =>
  action$.pipe(
    ofType(Actions.DELETE_PATIENT_REQUESTED),
    withLatestFrom(state$),
    map(([action, state]) => ({
      patientId: action.payload.patientId,
      patientName: action.payload.patientName,
      isLead: action.payload.isLead
    })),
    flatMap(({ patientId, patientName, isLead }) => {
      if (isLead) {
        return from(API.del('grinServerlessApi', `/accounts/v2/leads/patients/${patientId}`)).pipe(
          mergeMap(result => of(Actions.deletePatientReceived({ patientName, patientId }))),
          catchError(error => of(Actions.deletePatientFailed({ error, patientId })))
        )
      }

      return from(API.del('grinServerlessApi', `/accounts/v2/patients/${patientId}`)).pipe(
        mergeMap(result => of(Actions.deletePatientReceived({ patientName, patientId }))),
        catchError(({ response }) => {
          if (response?.data?.isSibling) {
            trackEvent('Linked accounts - delete sibling error', { patientId, patientName })
          }
          return of(Actions.deletePatientFailed({ error: response.data, patientId }))
        })
      )
    })
  )

export const deletePatientReceivedEpic = action$ =>
  action$.pipe(
    ofType(Actions.DELETE_PATIENT_RECEIVED),
    pluck('payload'),
    map(({ patientName }) =>
      Actions.showSnackbar({
        type: 'success',
        text: i18n.t('messages.users.deletedSuccessfully', {
          name: patientName
        })
      })
    )
  )

export const deletePatientReceivedRoutingEpic = (action$, state$) =>
  action$.pipe(
    ofType(Actions.DELETE_PATIENT_RECEIVED),
    withLatestFrom(state$),
    map(([action, state]) => ({
      patientId: action.payload.patientId,
      location: state.router.location
    })),
    filter(
      ({ location, patientId }) => location.pathname.includes(ROUTES.PATIENTS) && location.pathname.includes(patientId)
    ),
    mapTo(push(`${ROUTES.PATIENTS}${window.location.search}`))
  )

export const asyncPatientOperationFailedEpic = action$ =>
  action$.pipe(
    ofType(Actions.DELETE_PATIENT_FAILED, Actions.RESEND_PATIENT_INVITATION_FAILED),
    pluck('payload'),
    map(({ errorMessage, error }) =>
      Actions.showSnackbar({
        type: 'error',
        text: errorMessage || error?.message || i18n.t('messages.somethingWentWrongContactSupport')
      })
    )
  )

export const updatePatientEpic = (action$, state$) =>
  action$.pipe(
    ofType(Actions.REQUEST_PATIENT_DETAILS_UPDATE),
    pluck('payload'),
    filter(({ isLead }) => !isLead),
    map(patient => mapToUpdatePatientInput(patient)),
    switchMap(patient =>
      from(API.graphql(graphqlOperation(updatePatientForDoctorCard, { input: patient }))).pipe(
        mergeMap(({ data }) =>
          of(
            Actions.updatePatientDetailsReceived({
              patient: mapToPatientDto(data?.updatePatient),
              snackbarText: i18n.t('messages.changesSavedSuccessfully')
            })
          )
        ),
        catchError(error => of(Actions.updatePatientDetailsFailed({})))
      )
    )
  )

export const updateLeadPatientEpic = (action$, state$) =>
  action$.pipe(
    ofType(Actions.REQUEST_PATIENT_DETAILS_UPDATE),
    pluck('payload'),
    filter(({ isLead }) => isLead),
    map(patient => mapToUpdateLeadInput(patient)),
    switchMap(patient =>
      from(API.graphql(graphqlOperation(updateLead, { input: patient }))).pipe(
        mergeMap(({ data }) =>
          of(
            Actions.updatePatientDetailsReceived({
              patient: {
                id: data?.updateLead?.id,
                _version: data?.updateLead?._version,
                details: mapLeadDetailsToPatient(
                  data?.updateLead,
                  JSON.parse(data?.updateLead[`${data?.updateLead.program}Data`] || '{}')
                )
              },
              snackbarText: i18n.t('messages.changesSavedSuccessfully')
            })
          )
        ),
        catchError(error => of(Actions.updatePatientDetailsFailed({})))
      )
    )
  )

export const supportUpdatePatientDetailsFailedEpic = action$ =>
  action$.pipe(
    ofType(Actions.UPDATE_PATIENT_DETAILS_FAILED),
    mapTo(
      Actions.showSnackbar({
        type: 'error',
        text: i18n.t('messages.somethingWentWrong')
      })
    )
  )

export const requestPatientNotesEpic = action$ =>
  action$.pipe(
    ofType(Actions.REQUEST_PATIENT_NOTES),
    pluck('payload'),
    switchMap(grinUserId =>
      from(fetchAll(userNotesSorted, { grinUserId }, 'userNotesSorted')).pipe(
        map(notes => notes.filter(note => !note._deleted)),
        mergeMap(userNotes => of(Actions.patientNotesReceived(userNotes))),
        catchError(err =>
          concat(
            of(Actions.requestPatientNotesFailed(err)),
            of(
              Actions.showSnackbar({
                type: 'error',
                text: i18n.t('dialogs.notes.fetchPatientNotesError')
              })
            )
          )
        )
      )
    )
  )

export const createPatientNoteEpic = (action$, state$) =>
  action$.pipe(
    ofType(Actions.CREATE_PATIENT_NOTE),
    pluck('payload'),
    withLatestFrom(state$),
    map(mapToCreateNoteInput),
    switchMap(input =>
      from(API.graphql(graphqlOperation(createUserNote, { input }))).pipe(
        map(response => response.data.createUserNote),
        mergeMap(response => of(Actions.createPatientNoteReceived(response))),
        catchError(err => of(Actions.createPatientNoteFailed(err)))
      )
    )
  )

export const createPatientNoteReceivedEpic = (action$, state$) =>
  action$.pipe(
    ofType(Actions.CREATE_PATIENT_NOTE_RECEIVED),
    pluck('payload'),
    withLatestFrom(state$),
    map(mapToNoteAnalyticsPayload),
    tap(mixpanelPayload => trackEvent('Patient Note: note created', mixpanelPayload)),
    ignoreElements()
  )

export const createPatientNoteFailedEpic = action$ =>
  action$.pipe(
    ofType(Actions.CREATE_PATIENT_NOTE_FAILED),
    map(() =>
      Actions.showSnackbar({
        type: 'error',
        text: i18n.t('dialogs.notes.createPatientNoteError')
      })
    )
  )

export const deletePatientInitialScanItemEpic = action$ =>
  action$.pipe(
    ofType(Actions.DELETE_PATIENT_INITIAL_SCAN_ITEM),
    pluck('payload'),
    map(payload => Actions.requestUpdatePatientInitialScan(payload))
  )

export const updatePatientInitialScanEpic = action$ =>
  action$.pipe(
    ofType(Actions.REQUEST_UPDATE_PATIENT_INITIAL_SCAN),
    pluck('payload'),
    switchMap(({ notes, oralImages, panoramics, stls, id, date }) =>
      from(API.graphql(graphqlOperation(getInitialScan, { id }))).pipe(
        map(response => response.data.getInitialScan),
        switchMap(({ _version, id }) =>
          from(
            API.graphql(
              graphqlOperation(updateInitialScan, {
                input: {
                  id,
                  _version,
                  comment: notes,
                  oralImages: oralImages?.map(stripToS3Object),
                  panoramics: panoramics?.map(stripToS3Object),
                  stls: stls?.map(stripToS3Object),
                  date: moment(date).format(AWS_DATE_TIME)
                }
              })
            )
          ).pipe(
            mergeMap(response => of(Actions.savePatientInitialScanReceived(response.data.updateInitialScan))),
            catchError(error => of(Actions.savePatientInitialScanFailed(error)))
          )
        ),
        catchError(error => of(Actions.savePatientInitialScanFailed(error)))
      )
    )
  )

export const deleteRecordsSetEpic = action$ =>
  action$.pipe(
    ofType(Actions.DELETE_RECORDS_SET),
    pluck('payload'),
    switchMap(({ id }) =>
      from(API.graphql(graphqlOperation(getInitialScan, { id }))).pipe(
        map(response => response.data.getInitialScan),
        switchMap(({ id, _version }) =>
          from(API.graphql(graphqlOperation(deleteInitialScan, { input: { id, _version } }))).pipe(
            mergeMap(response => of(Actions.deleteRecordsSetReceived(response.data.deleteInitialScan))),
            catchError(error => of(Actions.deleteRecordsSetFailed(error)))
          )
        ),
        catchError(error => of(Actions.deleteRecordsSetFailed(error)))
      )
    )
  )

export const createPatientInitialScanEpic = (action$, state$) =>
  action$.pipe(
    ofType(Actions.REQUEST_CREATE_PATIENT_INITIAL_SCAN),
    withLatestFrom(state$),
    map(([action, state]) => mapToCreateInitialScanInput(action, state)),
    switchMap(input =>
      from(API.graphql(graphqlOperation(createInitialScan, { input }))).pipe(
        mergeMap(response => of(Actions.savePatientInitialScanReceived(response.data.createInitialScan))),
        catchError(error => of(Actions.savePatientInitialScanFailed(error)))
      )
    )
  )

export const savePatientInitialScanReceivedEpic = action$ =>
  action$.pipe(
    ofType(Actions.SAVE_PATIENT_INITIAL_SCAN_RECEIVED),
    map(() =>
      Actions.showSnackbar({
        type: `success`,
        text: i18n.t('dialogs.patientInfo.records.itemsSavedSuccessfully')
      })
    )
  )

export const savePatientInitialScanFailedEpic = action$ =>
  action$.pipe(
    ofType(Actions.SAVE_PATIENT_INITIAL_SCAN_FAILED),
    map(() =>
      Actions.showSnackbar({
        type: `error`,
        text: i18n.t('dialogs.patientInfo.records.itemsUploadingError')
      })
    )
  )

export const deleteRecordsSetReceivedEpic = action$ =>
  action$.pipe(
    ofType(Actions.DELETE_RECORDS_SET_RECEIVED),
    map(() =>
      Actions.showSnackbar({
        type: `success`,
        text: i18n.t('dialogs.patientInfo.records.itemsDeletedSuccessfully')
      })
    )
  )

export const deleteRecordsSetFailedEpic = action$ =>
  action$.pipe(
    ofType(Actions.DELETE_RECORDS_SET_FAILED),
    map(() =>
      Actions.showSnackbar({
        type: `error`,
        text: i18n.t('dialogs.patientInfo.records.itemsDeletingError')
      })
    )
  )

export const downloadPatientFilesZipEpic = action$ =>
  action$.pipe(
    ofType(Actions.DOWNLOAD_PATIENT_FILES_ZIP),
    pluck('payload'),
    tap(payload => logInfo('DOWNLOAD_PATIENT_FILES_ZIP', payload)),
    switchMap(({ files, name, doNotShowSnackbar, isLocal }) =>
      from(
        Promise.all(files.map(file => (isLocal || file.isLocal ? dataUrlToBase64(file.data) : downloadMedia(file))))
      ).pipe(
        mergeMap(response => {
          const zip = new JSZip()
          files.forEach(({ key, outputFilename }, index) => {
            if (outputFilename) {
              const extension = fileExtension(key)
              outputFilename += extension ? `.${extension}` : '.jpg'
            } else {
              const splitted = key.split('/')
              outputFilename = splitted[splitted.length - 1]
            }

            zip.file(outputFilename, response[index], { base64: true })
          })
          return from(zip.generateAsync({ type: 'blob' })).pipe(
            map(content => {
              saveAs(content, `${name}.zip`)
              return Actions.downloadPatientFilesZipSuccess(!doNotShowSnackbar)
            })
          )
        }),
        catchError(err => of(Actions.downloadPatientFilesZipFailed(err)))
      )
    )
  )

export const downloadPatientFilesZipSuccessEpic = action$ =>
  action$.pipe(
    ofType(Actions.DOWNLOAD_PATIENT_FILES_ZIP_SUCCESS),
    pluck('payload'),
    map(
      showSnackbar =>
        showSnackbar &&
        Actions.showSnackbar({
          type: `success`,
          text: i18n.t('dialogs.patientInfo.records.itemsDonwloadedSuccessfully')
        })
    )
  )
export const downloadPatientFilesZipFailedEpic = action$ =>
  action$.pipe(
    ofType(Actions.DOWNLOAD_PATIENT_FILES_ZIP_FAILED),
    map(() =>
      Actions.showSnackbar({
        type: `error`,
        text: i18n.t('dialogs.patientInfo.records.itemsDownloadingError')
      })
    )
  )

export const assignTagToPatientEpic = action$ =>
  action$.pipe(
    ofType(Actions.ASSIGN_PATIENT_TAG),
    pluck('payload'),
    flatMap(({ tag, patientId }) =>
      from(
        API.post('grinApi', '/treatments/v1/tags/patientTags', {
          body: { tag, patientId }
        })
      ).pipe(
        mergeMap(data => of(Actions.assignPatientTagReceived(data))),
        catchError(({ response }) => of(Actions.assignPatientTagFailed(response)))
      )
    )
  )

export const removePatientTagEpic = action$ =>
  action$.pipe(
    ofType(Actions.REMOVE_PATIENT_TAG),
    pluck('payload'),
    flatMap(({ tag, patientId }) =>
      from(
        API.put('grinApi', `/treatments/v1/tags/patientTags/delete`, {
          body: { tag, patientId }
        })
      ).pipe(
        mergeMap(data => of(Actions.removePatientTagReceived(data))),
        catchError(({ response }) => of(Actions.removePatientTagFailed(response)))
      )
    )
  )

export const assignTagToPatientFailed = action$ =>
  action$.pipe(
    ofType(Actions.ASSIGN_PATIENT_TAG_FAILED, Actions.REMOVE_PATIENT_TAG_FAILED),
    mapTo(
      Actions.showSnackbar({
        type: 'error',
        text: i18n.t('messages.tags.failedToSaveTag')
      })
    )
  )

export const transferPatientEpic = (action$, state$) =>
  action$.pipe(
    ofType(Actions.TRANSFER_PATIENT),
    pluck('payload'),
    switchMap(({ patientId, doctorId, dataTypes }) =>
      from(
        API.post('grinServerlessApi', '/accounts/v3/patients/transfer/init', {
          body: {
            patientId,
            doctorId,
            dataTypes
          }
        })
      ).pipe(
        mergeMap(data =>
          concat(of(Actions.transferPatientReceived()), of(Actions.updateTreatmentStatusReceived(data)))
        ),
        catchError(({ response }) => of(Actions.transferPatientFailed(response)))
      )
    )
  )

export const transferPatientSuccessEpic = action$ =>
  action$.pipe(
    ofType(Actions.TRANSFER_PATIENT_RECEIVED),
    map(() =>
      Actions.showSnackbar({
        type: 'success',
        text: i18n.t('dialogs.transferPatient.transferPatientSuccess')
      })
    )
  )

export const transferPatientFailedEpic = action$ =>
  action$.pipe(
    ofType(Actions.TRANSFER_PATIENT_FAILED),
    pluck('payload'),
    map(error =>
      Actions.showSnackbar({
        type: 'error',
        text: error?.data?.message || i18n.t('dialogs.transferPatient.transferPatientFailed')
      })
    )
  )

export const requestPatientAppointmentsEpic = action$ =>
  action$.pipe(
    ofType(Actions.REQUEST_PATIENT_APPOINTMENTS),
    pluck('payload'),
    switchMap(patientId =>
      from(API.graphql(graphqlOperation(appointmentsByPatientId, { patientId }))).pipe(
        map(({ data }) => data.appointmentsByPatientId.items.filter(({ _deleted }) => !_deleted)),
        mergeMap(appointments => of(Actions.patientAppointmentsReceived(appointments))),
        catchError(err => of(Actions.patientAppointmentsFailed(err)))
      )
    )
  )

export const requestPatientAppointmentsFailedEpic = action$ =>
  action$.pipe(
    ofType(Actions.PATIENT_APPOINTMENTS_FAILED),
    map(() =>
      Actions.showSnackbar({
        type: 'error',
        text: i18n.t('dialogs.patientInfo.appointments.fetchPatientAppointmentsError')
      })
    )
  )

export const createPatientAppointmentEpic = action$ =>
  action$.pipe(
    ofType(Actions.CREATE_PATIENT_APPOINTMENT),
    pluck('payload'),
    switchMap(appointment =>
      from(API.graphql(graphqlOperation(createAppointment, { input: appointment }))).pipe(
        map(response => response.data.createAppointment),
        mergeMap(appointemnt => of(Actions.createPatientAppointmentReceived(appointemnt))),
        catchError(err => of(Actions.createPatientAppointmentFailed(err)))
      )
    )
  )

export const createPatientAppointmentReceivedEpic = action$ =>
  action$.pipe(
    ofType(Actions.CREATE_PATIENT_APPOINTMENT_RECEIVED),
    tap(() => trackEvent('Patient appointment created')),
    map(() =>
      Actions.showSnackbar({
        type: 'success',
        text: i18n.t('dialogs.patientInfo.appointments.createPatientAppointmentSuccess')
      })
    )
  )

export const createPatientAppointmentFailedEpic = action$ =>
  action$.pipe(
    ofType(Actions.CREATE_PATIENT_APPOINTMENT_FAILED),
    tap(() => trackEvent('Patient appointment creation failed')),
    map(() =>
      Actions.showSnackbar({
        type: 'error',
        text: i18n.t('dialogs.patientInfo.appointments.createPatientAppointmentError')
      })
    )
  )

export const updatePatientAppointmentEpic = action$ =>
  action$.pipe(
    ofType(Actions.UPDATE_PATIENT_APPOINTMENT),
    pluck('payload'),
    switchMap(appointment =>
      from(API.graphql(graphqlOperation(updateAppointment, { input: appointment }))).pipe(
        map(response => response.data.updateAppointment),
        mergeMap(appointemnt => of(Actions.updatePatientAppointmentReceived(appointemnt))),
        catchError(err => of(Actions.updatePatientAppointmentFailed(err)))
      )
    )
  )

export const updatePatientAppointmentReceivedEpic = action$ =>
  action$.pipe(
    ofType(Actions.UPDATE_PATIENT_APPOINTMENT_RECEIVED),
    tap(() => trackEvent('Patient appointment updated')),
    map(() =>
      Actions.showSnackbar({
        type: 'success',
        text: i18n.t('dialogs.patientInfo.appointments.updatePatientAppointmentSuccess')
      })
    )
  )

export const updatePatientAppointmentFailedEpic = action$ =>
  action$.pipe(
    ofType(Actions.UPDATE_PATIENT_APPOINTMENT_FAILED),
    tap(() => trackEvent('Patient appointment update failed')),
    map(() =>
      Actions.showSnackbar({
        type: 'error',
        text: i18n.t('dialogs.patientInfo.appointments.updatePatientAppointmentError')
      })
    )
  )

export const deletePatientAppointmentEpic = action$ =>
  action$.pipe(
    ofType(Actions.DELETE_PATIENT_APPOINTMENT),
    pluck('payload'),
    switchMap(appointment =>
      from(API.graphql(graphqlOperation(deleteAppointment, { input: appointment }))).pipe(
        map(response => response.data.deleteAppointment),
        mergeMap(appointemnt => of(Actions.deletePatientAppointmentReceived(appointemnt))),
        catchError(err => of(Actions.deletePatientAppointmentFailed(err)))
      )
    )
  )

export const deletePatientAppointmentReceivedEpic = action$ =>
  action$.pipe(
    ofType(Actions.DELETE_PATIENT_APPOINTMENT_RECEIVED),
    tap(() => trackEvent('Patient appointment deleted')),
    map(() =>
      Actions.showSnackbar({
        type: 'success',
        text: i18n.t('dialogs.patientInfo.appointments.deletePatientAppointmentSuccess')
      })
    )
  )

export const deletePatientAppointmentFailedEpic = action$ =>
  action$.pipe(
    ofType(Actions.DELETE_PATIENT_APPOINTMENT_FAILED),
    tap(() => trackEvent('Patient appointment deletion failed')),
    map(() =>
      Actions.showSnackbar({
        type: 'error',
        text: i18n.t('dialogs.patientInfo.appointments.deletePatientAppointmentError')
      })
    )
  )

export const deletePatientNoteEpic = action$ =>
  action$.pipe(
    ofType(Actions.DELETE_PATIENT_NOTE),
    pluck('payload'),
    switchMap(input =>
      from(API.graphql(graphqlOperation(deleteUserNote, { input }))).pipe(
        map(res => res.data.deleteUserNote),
        mergeMap(note => of(Actions.deletePatientNoteReceived(note))),
        catchError(err => of(Actions.deletePatientNoteFailed(err)))
      )
    )
  )

export const deletePatientNoteReceivedEpic = (action$, state$) =>
  action$.pipe(
    ofType(Actions.DELETE_PATIENT_NOTE_RECEIVED),
    pluck('payload'),
    withLatestFrom(state$),
    map(mapToNoteAnalyticsPayload),
    map(mixpanelPayload => {
      trackEvent('Patient Note: note deleted', mixpanelPayload)
      return Actions.showSnackbar({
        type: 'success',
        text: i18n.t('dialogs.notes.noteDeleted')
      })
    })
  )

export const deletePatientNoteFailedEpic = action$ =>
  action$.pipe(
    ofType(Actions.DELETE_PATIENT_NOTE_FAILED),
    map(() =>
      Actions.showSnackbar({
        type: 'error',
        text: i18n.t('dialogs.notes.failedToDeleteNote')
      })
    )
  )

export const editPatientNoteEpic = action$ =>
  action$.pipe(
    ofType(Actions.EDIT_PATIENT_NOTE),
    pluck('payload'),
    switchMap(input =>
      from(API.graphql(graphqlOperation(updateUserNote, { input }))).pipe(
        map(res => res.data.updateUserNote),
        mergeMap(note => of(Actions.editPatientNoteReceived(note))),
        catchError(err => of(Actions.editPatientNoteFailed(err)))
      )
    )
  )

export const editPatientNoteReceivedEpic = (action$, state$) =>
  action$.pipe(
    ofType(Actions.EDIT_PATIENT_NOTE_RECEIVED),
    pluck('payload'),
    withLatestFrom(state$),
    map(mapToNoteAnalyticsPayload),
    map(mixpanelPayload => {
      trackEvent('Patient Note: note edited', mixpanelPayload)
      return Actions.showSnackbar({
        type: 'success',
        text: i18n.t('dialogs.notes.noteEdited')
      })
    })
  )

export const editPatientNoteFailedEpic = action$ =>
  action$.pipe(
    ofType(Actions.EDIT_PATIENT_NOTE_FAILED),
    map(() =>
      Actions.showSnackbar({
        type: 'error',
        text: i18n.t('dialogs.notes.failedToEditNote')
      })
    )
  )

export const setPatientNotePinnedEpic = action$ =>
  action$.pipe(
    ofType(Actions.SET_PATIENT_NOTE_PINNED),
    pluck('payload'),
    switchMap(({ noteId, _version, isPinned }) =>
      from(API.graphql(graphqlOperation(updateUserNote, { input: { id: noteId, _version, isPinned } }))).pipe(
        map(res => res.data.updateUserNote),
        mergeMap(updatedNote => of(Actions.setPatientNotePinnedReceived(updatedNote))),
        catchError(err => of(Actions.setPatientNotePinnedFailed(err)))
      )
    )
  )

export const setPatientNotePinnedFailedEpic = action$ =>
  action$.pipe(
    ofType(Actions.SET_PATIENT_NOTE_PINNED_FAILED),
    map(() =>
      Actions.showSnackbar({
        type: 'error',
        text: i18n.t('dialogs.notes.failedToToggleNotePin')
      })
    )
  )

export const fetchPatientsWithTagEpic = action$ =>
  action$.pipe(
    ofType(Actions.FETCH_PATIENTS_WITH_TAG),
    pluck('payload'),
    switchMap(tag =>
      from(
        API.graphql(
          graphqlOperation(searchPatientsByTag, {
            limit: 500,
            filter: { tagKeywords: { eq: tag.value } }
          })
        )
      ).pipe(
        map(res => res.data?.searchPatientSearchModels?.total || 0),
        mergeMap(numOfPatients => of(Actions.fetchPatientsWithTagReceived(numOfPatients))),
        catchError(({ response }) => of(Actions.fetchPatientsWithTagFailed(response)))
      )
    )
  )

export const fetchPatientsWithTagFailedEpic = action$ =>
  action$.pipe(
    ofType(Actions.FETCH_PATIENTS_WITH_TAG_FAILED),
    map(() =>
      Actions.showSnackbar({
        type: 'error',
        text: i18n.t('pages.patients.selectedPatient.tags.somethingWentWrong')
      })
    )
  )

export const searchGuardian = (action$, state$) =>
  action$.pipe(
    ofType(Actions.SEARCH_GUARDIAN),
    withLatestFrom(state$),
    map(([action, state]) => {
      return { searchTerm: action.payload, doctorId: state.practiceReducer.accountOwner.id }
    }),
    filter(({ searchTerm }) => !!searchTerm),
    switchMap(({ searchTerm, doctorId }) =>
      from(
        API.graphql(
          graphqlOperation(invitePatientSearchPatientSearchModel, {
            limit: 1,
            filter: {
              and: [
                {
                  parentEmail: {
                    eq: searchTerm
                  }
                },
                {
                  doctorId: {
                    eq: doctorId
                  }
                }
              ]
            }
          })
        )
      ).pipe(
        map(res => mapLeadsSMToPatientsSM(res.data?.searchPatientSearchModels).items),
        mergeMap(data => {
          return of(Actions.searchGuardianReceived(data))
        }),
        catchError(err => {
          return of(Actions.searchGuardianFailed(err))
        })
      )
    )
  )

export const searchSiblingPatients = (action$, state$) =>
  action$.pipe(
    ofType(Actions.SEARCH_SIBLINGS_PATIENTS),
    withLatestFrom(state$),
    map(([action, state]) => {
      return { searchTerm: action.payload, doctorId: state.practiceReducer.accountOwner.id }
    }),
    filter(({ searchTerm }) => !!searchTerm),
    switchMap(({ searchTerm, doctorId }) =>
      from(
        API.graphql(
          graphqlOperation(invitePatientSearchPatientSearchModel, {
            limit: 100,
            filter: {
              and: [
                {
                  or: [
                    {
                      email: { matchPhrasePrefix: searchTerm }
                    },
                    {
                      name: { matchPhrasePrefix: searchTerm }
                    },
                    {
                      parentName: { matchPhrasePrefix: searchTerm }
                    },
                    {
                      parentEmail: { matchPhrasePrefix: searchTerm }
                    }
                  ]
                },
                {
                  statusType: { ne: 'deleted' }
                },
                {
                  statusType: { ne: 'transferred' }
                },
                {
                  program: { ne: 'whitening' }
                },
                {
                  doctorId: { eq: doctorId }
                }
              ]
            }
          })
        )
      ).pipe(
        map(res => mapLeadsSMToPatientsSM(res.data?.searchPatientSearchModels).items),
        mergeMap(data => {
          return of(Actions.searchSiblingPatientsReceived(data))
        }),
        catchError(err => {
          return of(Actions.searchSiblingPatientsFailed(err))
        })
      )
    )
  )

export const requestInvitationCodeModalEpic = action$ =>
  action$.pipe(
    ofType(Actions.REQUEST_INVITATION_CODE_MODAL),
    pluck('payload'),
    switchMap(({ guardianId, invitationCode, isOldInvitation }) =>
      from(
        API.graphql(
          graphqlOperation(searchPatientSearchModelForLinkedAccounts, {
            filter: {
              guardianId: {
                eq: guardianId
              }
            }
          })
        )
      ).pipe(
        mergeMap(({ data }) =>
          of(
            Actions.toggleInvitationCodeModal({
              isModalOpen: true,
              siblingEmail: (data?.searchPatientSearchModels?.items || []).find(patientSM => !!patientSM.patient)
                ?.email,
              invitationCode,
              isOldInvitation
            })
          )
        ),
        catchError(err =>
          of(
            Actions.showSnackbar({
              type: 'error',
              text: i18n.t('dialogs.invitationCode.failedToOpen')
            })
          )
        )
      )
    )
  )

export const fetchLeadScansEpic = action$ =>
  action$.pipe(
    ofType(Actions.FETCH_LEAD_SCANS),
    pluck('payload'),
    switchMap(({ leadId }) =>
      from(API.graphql(graphqlOperation(grinScansByLeadId, { patientId: leadId }))).pipe(
        mergeMap(response =>
          of(Actions.fetchLeadScansReceived(mapToGrinScansDto(response.data?.grinScansByPatientId?.items || [])))
        ),
        catchError(error => of(Actions.fetchLeadScansFailed(error)))
      )
    )
  )

export const fetchLeadScansFailedEpic = action$ =>
  action$.pipe(
    ofType(Actions.FETCH_LEAD_SCANS_FAILED),
    map(() =>
      Actions.showSnackbar({
        type: 'error',
        text: i18n.t('messages.somethingWentWrongContactSupport')
      })
    )
  )

export const uploadBulkInviteFormEpic = action$ =>
  action$.pipe(
    ofType(Actions.UPLOAD_BULK_INVITE_FORM),
    pluck('payload'),
    tap(() => trackEvent('bulk invite - form submitted')),
    switchMap(({ content, fileName }) =>
      from(
        API.post('grinServerlessApi', '/accounts/v2/leads/invite/bulk', {
          body: {
            content,
            fileName
          }
        })
      ).pipe(
        mergeMap(respose => {
          trackEvent('bulk invite - form submitted - success')
          return of(Actions.uploadBulkInviteFormReceived())
        }),
        catchError(error => {
          trackEvent('bulk invite - form submitted - error', {
            generalError: error?.response?.data?.validations?.form,
            invalidRows: error?.response?.data?.validations?.rows?.length,
            statusCode: error?.response?.status
          })
          return of(
            Actions.uploadBulkInviteFormFailed({
              errors: error?.response?.data?.validations,
              statusCode: error?.response?.status
            })
          )
        })
      )
    )
  )

export const uploadBulkInviteFailEpic = action$ =>
  action$.pipe(
    ofType(Actions.UPLOAD_BULK_INVITE_FORM_FAILED),
    pluck('payload', 'statusCode'),
    filter(statusCode => statusCode !== 422),
    mapTo(
      Actions.showSnackbar({
        type: 'error',
        text: i18n.t('general.genericError')
      })
    )
  )

export const resolveScanUrgencyEpic = (action$, state$) =>
  action$.pipe(
    ofType(Actions.RESOLVE_SCAN_URGENCY),
    withLatestFrom(state$),
    switchMap(([{ payload }, { patientsReducer }]) =>
      from(
        API.post('grinServerlessApi', '/treatments/v2/urgency/resolve', {
          body: {
            patientId: patientsReducer.patient.id,
            ...payload
          }
        })
      ).pipe(
        mergeMap(({ removedTag }) => of(Actions.resolveScanUrgencyReceived({ removedTag }))),
        catchError(error => of(Actions.resolveScanUrgencyFailed(error)))
      )
    )
  )

export const resolveScanUrgencyReceived = action$ =>
  action$.pipe(
    ofType(Actions.RESOLVE_SCAN_URGENCY_RECEIVED),
    map(() =>
      Actions.showSnackbar({
        type: 'success',
        text: i18n.t('pages.patients.selectedPatient.chat.resolveScanUrgencyResolved')
      })
    )
  )

export const resolveScanUrgencyFailed = action$ =>
  action$.pipe(
    ofType(Actions.RESOLVE_SCAN_URGENCY_FAILED),
    map(() =>
      Actions.showSnackbar({
        type: 'error',
        text: i18n.t('pages.patients.selectedPatient.chat.resolveScanUrgencyFailed')
      })
    )
  )

export const fetchPatientSiblingsEpic = action$ =>
  action$.pipe(
    ofType(Actions.FETCH_PATIENT_SIBLINGS),
    pluck('payload'),
    switchMap(({ guardianId }) =>
      from(
        API.graphql(
          graphqlOperation(searchSiblingSearchModels, {
            filter: {
              guardianId: { eq: guardianId }
            }
          })
        )
      ).pipe(
        mergeMap(res => of(Actions.fetchPatientSiblingsReceived(res.data?.searchPatientSearchModels?.items))),
        catchError(res => of(Actions.fetchPatientSiblingsFailed(res)))
      )
    )
  )

export const fetchPatientSiblingsForResendEpic = action$ =>
  action$.pipe(
    ofType(Actions.FETCH_PATIENT_SIBLINGS_FOR_RESEND),
    pluck('payload'),
    switchMap(({ guardianId }) =>
      from(
        API.graphql(
          graphqlOperation(searchSiblingSearchModelsForResendInvite, {
            filter: {
              guardianId: { eq: guardianId }
            }
          })
        )
      ).pipe(
        mergeMap(res => of(Actions.fetchPatientSiblingsForResendReceived(res.data?.searchPatientSearchModels?.items))),
        catchError(res => of(Actions.fetchPatientSiblingsForResendFailed(res)))
      )
    )
  )

export const createPatientBriefEpic = (action$, state$) =>
  action$.pipe(
    ofType(Actions.CREATE_PATIENT_BRIEF),
    withLatestFrom(state$),
    map(([action, state]) => ({
      payload: action.payload,
      creatorDoctorId: state.profileReducer.doctor.id
    })),
    switchMap(({ payload, creatorDoctorId }) =>
      from(
        API.graphql(
          graphqlOperation(createPatientBrief, {
            input: {
              customTitle: payload.customTitle,
              practiceNotes: payload.practiceNotes,
              categories: JSON.stringify(payload.categories),
              patientId: payload.patientId,
              doctorId: payload.doctorId,
              a_doctor: payload.a_doctor,
              creatorDoctorId
            }
          })
        )
      ).pipe(
        mergeMap(res => of(Actions.createPatientBriefReceived({ patientBrief: res.data.createPatientBrief }))),
        catchError(err => of(Actions.createPatientBriefFailed(err)))
      )
    )
  )

export const createPatientBriefFailedEpic = action$ =>
  action$.pipe(
    ofType(Actions.CREATE_PATIENT_BRIEF_FAILED),
    map(() =>
      Actions.showSnackbar({
        type: 'error',
        text: i18n.t('messages.somethingWentWrongContactSupport')
      })
    )
  )

export const createPatientBriefReceviedEpic = action$ =>
  action$.pipe(
    ofType(Actions.CREATE_PATIENT_BRIEF_RECEIVED),
    map(() =>
      Actions.showSnackbar({
        type: 'success',
        text: i18n.t('dialogs.patientBrief.patientBriefSaved')
      })
    )
  )

export const fetchPatientNotes = action$ =>
  action$.pipe(
    ofType(Actions.FETCH_PATIENT_NOTES),
    pluck('payload'),
    switchMap(({ patientGrinUserId }) =>
      from(fetchAll(userNotesSorted, { grinUserId: patientGrinUserId }, 'userNotesSorted')).pipe(
        map(notes => notes.filter(note => !note._deleted)),
        mergeMap(userNotes => of(Actions.fetchPatientNotesReceived(userNotes))),
        catchError(err =>
          concat(
            of(Actions.fetchPatientNotesFailed(err)),
            of(
              Actions.showSnackbar({
                type: 'error',
                text: i18n.t('dialogs.notes.fetchPatientNotesError')
              })
            )
          )
        )
      )
    )
  )

export const createRecordsPatientEpic = (action$, state$) =>
  action$.pipe(
    ofType(Actions.CREATE_RECORDS_PATIENT),
    withLatestFrom(state$),
    map(([action, state]) => ({
      ...action.payload,
      doctorUsername: state.profileReducer.doctor.username
    })),
    switchMap(({ firstName, lastName, birthDate, note, doctorUsername }) =>
      from(
        API.post('grinServerlessApi', '/accounts/v2/leads/patients', {
          body: {
            firstName,
            lastName,
            note,
            birthdate: birthDate,
            email: `recordsAppTemp+${v4()}@get-grin.com`,
            conversionStatus: 'added',
            postConfirmationStatusKey: 'observation_serviceAccount',
            doctorUsername,
            program: 'serviceAccount'
          }
        })
      ).pipe(
        mergeMap(res =>
          concat(
            of(Actions.createRecordsPatientReceived(res)),
            of(
              Actions.showSnackbar({
                type: 'success',
                text: i18n.t('dialogs.createRecordsPatientModal.successSnackbar')
              })
            )
          )
        ),
        catchError(err => concat(of(Actions.createRecordsPatientFailed(err))))
      )
    )
  )

export const addRecordsPatientToChatEpic = action$ =>
  action$.pipe(
    ofType(Actions.CREATE_RECORDS_PATIENT_RECEIVED),
    pluck('payload'),
    mergeMap(({ id }) =>
      from(API.graphql(graphqlOperation(getPatientSearchModel, { id }))).pipe(
        map(res => res.data.getPatientSearchModel),
        map(patientSM => mapLeadSMToPatientSM(patientSM)),
        mergeMap(patientSM => of(Actions.addNewPatientChat(patientSM))),
        catchError(error => {
          logError(`addRecordsPatientToChatEpic: failed to fetch patientSM`, { patientId: id, error })
          return of(Actions.fetchRejected(error))
        })
      )
    )
  )

export const updatePatientGenderEpic = (action$, state$) =>
  action$.pipe(
    ofType(Actions.UPDATE_PATIENT_GENDER),
    pluck('payload'),
    switchMap(({ id, _version, gender }) =>
      from(
        mutateEntity({
          id,
          mutationType: GraphQLMutationTypes.Update,
          entityType: 'Patient',
          input: {
            gender
          },
          fields: ['id', '_version', 'gender']
        })
      ).pipe(
        mergeMap(updatedFields =>
          concat(
            of(Actions.updatePatientGenderReceived(updatedFields)),
            of(
              Actions.showSnackbar({
                type: 'success',
                text: i18n.t('messages.patientUpdatedSuccessfully')
              })
            )
          )
        ),
        catchError(error => of(Actions.updatePatientGenderFailed(error)))
      )
    )
  )

const patientInvitedSuccess = name => ({
  text: i18n.t('messages.users.patientInvitedSuccess', { name }),
  time: 5000
})

const invitationWentWrongError = {
  type: 'error',
  text: i18n.t('messages.users.invitationWentWrongError'),
  time: 7000
}

const userAlreadyExistsError = {
  type: 'error',
  text: i18n.t('messages.users.userAlreadyExistsError'),
  time: 7000
}
