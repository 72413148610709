import { makeStyles } from '@material-ui/core'
import GrinFloatingPopup from 'components/common/menu/GrinFloatingPopup'
import React, { useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { trackEvent } from 'utils/analyticsUtils'
import NotifyPracticeForm from '../NotifyPractice/NotifyPracticeForm'
import { NOTIFY_PRACTICE_REASONS } from 'consts/giConsts'
import { useDispatch } from 'react-redux'
import actions from 'actions'

const useStyles = makeStyles(theme => ({
  title: {
    fontWeight: 'bold'
  },
  floatingPopup: {
    width: 374
  }
}))

const NotifyPracticeFloatingPopup = ({
  triggerComponent,
  children,
  onOpen,
  onClose,
  isOpen,
  patientId,
  practiceName
}) => {
  const { t } = useTranslation()
  const classes = useStyles()
  const dispatch = useDispatch()

  const [reason, setReason] = useState(NOTIFY_PRACTICE_REASONS.CLINICAL)
  const [selectedPracticeMembers, setSelectedPracticeMembers] = useState([])
  const [note, setNote] = useState('')

  const handleCloseMenu = useCallback(() => {
    onClose()
    setSelectedPracticeMembers([])
    trackEvent('Notify Practice - menu closed', {
      origin: 'Patients header',
      patientId,
      practiceName
    })
  }, [onClose, patientId, practiceName])

  const handleSubmit = useCallback(() => {
    const selectedPracticeMembersIds = selectedPracticeMembers.map(memberOption => memberOption.value)

    trackEvent('Notify Practice - submit info', {
      reason,
      patientId,
      practiceName,
      selectedPracticeMembersIds,
      withNote: !!note,
      noteLength: note?.length
    })
    dispatch(
      actions.hiNotifyPractice({
        patientId,
        reason,
        note,
        selectedPracticeMembersIds
      })
    )
    setReason(NOTIFY_PRACTICE_REASONS.CLINICAL)
    setSelectedPracticeMembers([])
    setNote('')
    onClose()
  }, [reason, patientId, practiceName, selectedPracticeMembers, note, dispatch, onClose])

  return (
    <GrinFloatingPopup
      className={classes.floatingPopup}
      title={t('dialogs.notifyPractice.title')}
      titleClassName={classes.title}
      triggerComponent={triggerComponent}
      secondaryBtnLabel={t('general.cancel')}
      primaryBtnLabel={t('general.confirm')}
      buttonProps={{
        width: 128,
        height: 36
      }}
      onPrimaryBtnClick={handleSubmit}
      onSecondaryBtnClick={handleCloseMenu}
      open={isOpen}
      onOpen={onOpen}
      onClose={handleCloseMenu}
    >
      <NotifyPracticeForm
        reason={reason}
        note={note}
        reasons={NOTIFY_PRACTICE_REASONS}
        onChangeReason={setReason}
        onChangeNote={setNote}
        selectMemberInputFullWidth
        selectedPracticeMembers={selectedPracticeMembers}
        setSelectedPracticeMembers={setSelectedPracticeMembers}
      />
    </GrinFloatingPopup>
  )
}

export default NotifyPracticeFloatingPopup
