/**
 *
 * @param {Object} args
 * @param {Array<{items: Array<any>}>} args.pages - The InfiniteQuery pages list.
 * @param {Function} args.predicate - A function that receives an item and returns a boolean. If the function returns `true`, the item will be updated.
 * @param {Object} args.itemPatch - The object with the properties to be patched.
 */
export const patchPaginatedGraphQueryState = ({ pages = [], predicate = item => true, itemPatch = {} }) => {
  pages.forEach(page => {
    page.items.forEach(item => {
      if (predicate(item)) {
        Object.assign(item, { ...item, ...itemPatch })
      }
    })
  })
}
