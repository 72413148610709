import * as React from 'react'

const specificMembersAttention = () => (
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M13 14V13C13 11.3431 11.6569 10 10 10H6C4.34315 10 3 11.3431 3 13V14" stroke="#001335" />
    <circle cx="8" cy="5" r="3" stroke="#001335" />
    <path d="M10 10L8 14L12.5 11L10 10Z" fill="#001335" />
    <path d="M6 10L8 14L4 11L6 10Z" fill="#001335" />
  </svg>
)

export default specificMembersAttention
