import React, { useCallback, useState } from 'react'
import GrinMenu from 'components/common/menu/GrinMenu'
import { trackEvent } from 'utils/analyticsUtils'
import { Grid, makeStyles } from '@material-ui/core'
import ScanNavigationArrows from 'components/common/ScanNavigationArrows'
import ScanItem from './ScanItem'
import GrinMenuItem from 'components/common/menu/GrinMenuItem'
import useScans from 'hooks/useScans'
import { ArrowDropdownDown, ArrowDropdownUp } from 'components/common/icons'

const useStyles = makeStyles(theme => ({
  menu: {
    boxShadow: '0px 4px 30px rgba(0, 0, 0, 0.15)',
    borderRadius: '10px',
    maxHeight: 350
  },
  menuItemText: {
    fontSize: 14
  }
}))

const ScansNavigation = ({
  scanNumber,
  alignerNumber,
  scanCreatedAt,
  nextDisabled,
  onNextClicked,
  previousDisabled,
  onPreviousClicked,
  allTxTrackerScans,
  navigateToScanByScanId
}) => {
  const classes = useStyles()
  const { scanIdToNumberDict } = useScans()

  const [isMenuOpen, setIsMenuOpen] = useState(false)

  const handleOpen = useCallback(() => {
    trackEvent('Treatment tracker modal - Scan navigation - Menu opened')
    setIsMenuOpen(true)
  }, [])

  const handleClose = useCallback(() => {
    setIsMenuOpen(false)
  }, [])

  const handleNavigateToScan = useCallback(
    scanId => {
      trackEvent('Treatment tracker modal - Scan navigation - Navigate to scan clicked', {
        scanId
      })
      navigateToScanByScanId(scanId)
      setIsMenuOpen(false)
    },
    [navigateToScanByScanId]
  )

  return (
    <Grid container alignItems="center" spacing={1}>
      <Grid item style={{ marginRight: 10 }}>
        <ScanNavigationArrows
          nextDisabled={nextDisabled}
          onNextClicked={onNextClicked}
          previousDisabled={previousDisabled}
          onPreviousClicked={onPreviousClicked}
        />
      </Grid>
      <Grid item>
        <GrinMenu
          className={classes.menu}
          onOpen={handleOpen}
          onClose={handleClose}
          open={isMenuOpen}
          transformOrigin={{ vertical: -50, horizontal: 'left' }}
          triggerComponent={
            <Grid container alignItems="center" spacing={1}>
              <Grid item>
                <ScanItem
                  alignerNumber={alignerNumber}
                  scanCreatedAt={scanCreatedAt}
                  scanNumber={scanNumber}
                  withDaysAgo
                />
              </Grid>
              <Grid item>{isMenuOpen ? <ArrowDropdownUp size={8} /> : <ArrowDropdownDown size={8} />}</Grid>
            </Grid>
          }
        >
          {allTxTrackerScans.map(currScan => (
            <GrinMenuItem onClick={() => handleNavigateToScan(currScan.id)} key={`scan-item-${currScan.id}`}>
              <ScanItem
                textClassName={classes.menuItemText}
                alignerNumber={currScan.applianceIndex}
                scanCreatedAt={currScan.createdAt}
                scanNumber={scanIdToNumberDict?.[currScan?.id] + 1}
                withDaysAgo={false}
                createdAtFormat="LL"
              />
            </GrinMenuItem>
          ))}
        </GrinMenu>
      </Grid>
    </Grid>
  )
}

export default ScansNavigation
