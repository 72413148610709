import { NOTIFICATION_CENTER_POLLING_INTERVAL_MS } from 'consts/notificationCenterConsts'
import { useCallback, useMemo } from 'react'
import { useSelector } from 'react-redux'
import { useFetchGrinNotificationsInfiniteQuery } from 'store/modules/NotificationCenter'
import { mapNotificationStatus } from 'utils/notificationCenterUtils'

export default () => {
  const { isOpen, filters } = useSelector(state => state.notificationCenterReducer)
  const grinUserId = useSelector(state => state.profileReducer.doctor?.user?.id)

  const {
    currentData: data,
    isLoading,
    isFetching,
    isError,
    refetch,
    fetchNextPage
  } = useFetchGrinNotificationsInfiniteQuery(
    { grinUserId, filters },
    {
      skip: !grinUserId || !isOpen,
      initialPageParam: 0,
      pollingInterval: NOTIFICATION_CENTER_POLLING_INTERVAL_MS,
      skipPollingIfUnfocused: true
    }
  )

  const mapNotification = useCallback(
    grinNotification => ({
      ...grinNotification,
      status: mapNotificationStatus({
        originalStatus: grinNotification.status,
        eventDate: grinNotification.eventDate,
        lastClearAllDate: filters.lastClearAllDate,
        lastMarkAllAsReadDate: filters.lastMarkAllAsReadDate
      })
    }),
    [filters]
  )

  const grinNotifications = useMemo(
    () => data?.pages.map(page => page.items.map(mapNotification)).flat(),
    [data, mapNotification]
  )
  const hasMorePages = useMemo(() => data?.pages[data.pages.length - 1].hasMore, [data])

  return {
    hasData: grinNotifications?.length > 0,
    grinNotifications,
    isLoading: isLoading || isFetching,
    isError,
    hasMorePages,
    refetch,
    fetchNextPage
  }
}
