import { Grid, TextField } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { Autocomplete } from '@material-ui/lab'
import SelectInput from 'components/common/SelectInput'
import TextArea from 'components/common/TextArea'
import reasonIcons from 'components/common/icons/notifyPractice/index'
import DazzedParagraph14 from 'components/common/text/DazzedParagraph14'
import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useAssignPatient } from '../AssignPatient/useAssignPatient'

const useStyles = makeStyles({
  noteInput: {
    borderRadius: 10
  },
  checkbox: {
    paddingLeft: 0
  },
  checkboxCaption: {
    fontSize: 14
  }
})

const NotifyPracticeForm = ({
  note,
  reason,
  reasons,
  onChangeNote,
  onChangeReason,
  selectedPracticeMembers,
  setSelectedPracticeMembers
}) => {
  const { t } = useTranslation()
  const classes = useStyles()

  const { practiceMembersSelectOptions } = useAssignPatient({
    withUnassignOption: false
  })

  const reasonOptions = useMemo(
    () =>
      Object.values(reasons).map(reason => ({
        label: t(`dialogs.notifyPractice.reasons.${reason}`),
        value: reason,
        icon: React.createElement(reasonIcons[reason])
      })),
    [t, reasons]
  )
  const isSpecificMemberOptionSelected = useMemo(
    () => reason === reasons.SPECIFIC_MEMBERS_ATTENTIION,
    [reason, reasons]
  )

  return (
    <Grid container direction="column" spacing={1}>
      <Grid item>
        <Grid container alignItems="center">
          <Grid item>
            <DazzedParagraph14 bold>{t(`dialogs.notifyPractice.reasonLabel`)}</DazzedParagraph14>
          </Grid>
          <Grid item>
            <SelectInput
              selectStyle={{ border: 'none', fontSize: 14, fontWeight: 500, marginTop: -2 }}
              showArrow={false}
              dropdownClassName={classes.dropdown}
              style={{ bright: true, thick: true }}
              value={reason}
              options={reasonOptions}
              keepErrorContainerWhenInactive={false}
              onChange={({ value }) => onChangeReason(value)}
            />
          </Grid>
        </Grid>
      </Grid>
      {isSpecificMemberOptionSelected && (
        <>
          <Grid item>
            <Autocomplete
              multiple
              disableCloseOnSelect
              filterSelectedOptions
              options={practiceMembersSelectOptions.filter(opt => !opt.disabled)}
              value={selectedPracticeMembers}
              onChange={(e, values) => setSelectedPracticeMembers(values)}
              getOptionLabel={option => option.label}
              getOptionDisabled={option => option.disabled}
              getOptionSelected={option => selectedPracticeMembers.some(member => member.value === option.value)}
              renderInput={params => (
                <TextField
                  {...params}
                  label={t('dialogs.notifyPractice.selectMembersPlaceholder')}
                  InputProps={{
                    ...params.InputProps,
                    disableUnderline: true
                  }}
                />
              )}
              classes={{
                root: classes.root
              }}
            />
          </Grid>
        </>
      )}
      <Grid item xs={12}>
        <TextArea
          inputStyle={{ height: 135, padding: 16 }}
          value={note}
          setValue={onChangeNote}
          style={{ bright: true }}
          placeholder={t('dialogs.notifyPractice.notePlaceholder')}
          className={classes.noteInput}
        />
      </Grid>
    </Grid>
  )
}

export default NotifyPracticeForm
