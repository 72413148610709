import { Grid, makeStyles } from '@material-ui/core'
import React, { useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import Actions from 'actions'
import usePatientTags from 'hooks/usePatientTags'
import SecondaryButton from '../../common/buttons/SecondaryButton'
import useRolePermissions from '../../../hooks/useRolePermissions'
import PatientStatusUpdate from '../PatientStatusUpdate'
import { MarkReadLarge, MarkUnreadLarge, UploadRecords } from 'components/common/icons'
import { PATIENT_CARD_TABS } from 'consts/patientCard'
import { trackEvent } from 'utils/analyticsUtils'
import DazzedHeading18 from 'components/common/text/DazzedHeading18'
import GrinLabel from 'components/common/text/GrinLabel'
import Assignee from './Assignee'
import PatientsDetails from './PatientsDetails'
import PatientsHeaderSkeleton from './PatientsHeaderSkeleton'
import NotifyPracticeButton from '../NotifyPractice/NotifyPracticeButton'
import useGaFlags from 'hooks/useGaFlags'
import PinnedNotes from './PinnedNotes/PinnedNotes'
import { StatusTypes } from '@grin-rnd/grin-api-sdk/dist/Enums/Statuses'
import useSpecificMemberAttention from 'hooks/useSpecificMemberAttention'

const useStyles = makeStyles({
  container: {
    zIndex: 1,
    background: 'white',
    boxShadow: '1px 4px 15px rgba(0, 0, 0, 0.08)',
    height: 'var(--patient-header-height)'
  },
  actionsLabel: {
    fontSize: 13,
    whiteSpace: 'pre-line',
    background: 'transparent'
  },
  divider: {
    height: 68,
    borderLeft: '1px solid var(--border-color-10)'
  },
  headerItem: {
    display: 'flex',
    justifyContent: 'center',
    borderLeft: '1px solid var(--border-color-10)'
  },
  headerItemWithHover: {
    display: 'flex',
    justifyContent: 'center',
    borderLeft: '1px solid var(--border-color-10)',
    cursor: 'pointer',
    background: 'white',
    transition: '0.4s',
    '&:hover': {
      background: '#f6f6f6'
    }
  },
  patientName: {
    lineHeight: '32px',
    cursor: 'pointer'
  },
  statusContainer: {
    padding: '8px 0px 8px 10px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between'
  },
  assigneeContainer: {
    padding: '8px 0px 0px 10px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between'
  },
  patientDetailsContainer: {
    display: 'flex'
  },
  broadcastHeading: {
    marginLeft: 20
  },
  pinnedNotesContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignSelf: 'baseline'
  }
})

const PatientsHeader = () => {
  const classes = useStyles()

  const { t } = useTranslation()
  const dispatch = useDispatch()
  const { permissions } = useRolePermissions()

  const gaFlags = useGaFlags()
  const isBroadcastMode = useSelector(state => state.chatReducer.broadcast.isModeOn)
  const isUploadingStls = useSelector(state => state.treatmentReducer.uploadStl.isSaving)
  const { patient, isLoadingPatient } = useSelector(state => state.patientsReducer)
  const { activeRoomId, rooms } = useSelector(state => state.chatReducer)

  const { tags, assigneeTag, needsAttentionTag, needsAttentionTagDetails } = usePatientTags({ patient })
  const { isAwaitingCurrentMembersAttention } = useSpecificMemberAttention({
    selectedPracticeMembersIds: needsAttentionTagDetails?.selectedPracticeMembersIds
  })
  const { isHiUser } = useRolePermissions()

  const isChatRoomResolved = useMemo(
    () => rooms.find(room => room.roomId === activeRoomId)?.isChatRoomResolved,
    [activeRoomId, rooms]
  )
  const isPatientActive = useMemo(() => {
    const patientStatusType = patient?.treatments?.items?.[0]?.currentStatus?.status?.type

    return ![StatusTypes.Archived, StatusTypes.Invited, StatusTypes.Transferred].includes(patientStatusType)
  }, [patient])

  const canDeleteNeedsAttention = useMemo(() => {
    if (!needsAttentionTagDetails) {
      return true
    }

    return isAwaitingCurrentMembersAttention
  }, [isAwaitingCurrentMembersAttention, needsAttentionTagDetails])

  const handleDeleteTag = useCallback(
    tagValue => {
      trackEvent('Patient tags - tag deleted', {
        tag: tagValue
      })
      dispatch(Actions.removePatientTag({ patientId: patient.id, tag: tagValue }))
    },
    [patient, dispatch]
  )

  const handleOpenPatientCardRecordsTab = useCallback(() => {
    trackEvent('Patients header - Upload records modal opened')
    dispatch(
      Actions.togglePatientCard({
        isModalOpen: true,
        patientId: patient.id,
        selectedTab: PATIENT_CARD_TABS.records.index
      })
    )
  }, [dispatch, patient.id])

  const handleMarkReadUnread = useCallback(() => {
    trackEvent('Patients header - mark as read/unread', {
      type: isChatRoomResolved ? 'mark read' : 'mark unread'
    })

    if (isChatRoomResolved) {
      dispatch(
        Actions.markRoomUnread({
          roomId: activeRoomId,
          analytics: {
            reason: 'manual',
            patientId: patient.id
          }
        })
      )
    } else {
      dispatch(
        Actions.markRoomRead({
          roomId: activeRoomId,
          analytics: {
            reason: 'manual',
            patientId: patient.id
          }
        })
      )
    }
  }, [activeRoomId, dispatch, isChatRoomResolved, patient])

  const handleOpenPatientCard = useCallback(() => {
    if (isLoadingPatient) {
      return
    }

    trackEvent('Patinets header - Patient card opened')
    dispatch(Actions.togglePatientCard({ isModalOpen: true, patientId: patient.id }))
  }, [dispatch, patient.id, isLoadingPatient])

  return (
    <Grid container alignItems="center" className={classes.container}>
      {isLoadingPatient && !patient?.details?.name ? (
        <PatientsHeaderSkeleton />
      ) : (
        (patient?.details?.name || isBroadcastMode) && (
          <Grid item xs={12}>
            {isBroadcastMode ? (
              <DazzedHeading18 className={classes.broadcastHeading}>
                {t('pages.patients.selectedPatient.header.createBroadcastMessage')}
              </DazzedHeading18>
            ) : (
              <Grid container>
                <Grid item className={classes.patientDetailsContainer} xs={4} md={5} lg={5} xl={7}>
                  <PatientsDetails
                    {...{
                      patient,
                      handleOpenPatientCard,
                      tags,
                      canDeleteNeedsAttention,
                      handleDeleteTag,
                      permissions,
                      isLoading: isLoadingPatient
                    }}
                  />
                </Grid>
                {!isLoadingPatient && (
                  <Grid item xs={8} md={7} lg={7} xl={5}>
                    <Grid container alignItems="stretch" spacing={1}>
                      <Grid item xs={1} className={classes.pinnedNotesContainer}>
                        <PinnedNotes />
                      </Grid>
                      <Grid item xs={2} className={classes.headerItemWithHover}>
                        <SecondaryButton
                          useContentHeight
                          useContentWidth
                          iconMargin="0px"
                          disableRipple={true}
                          hideBorder={true}
                          icon={isChatRoomResolved ? <MarkUnreadLarge /> : <MarkReadLarge />}
                          iconPosition="top"
                          label={t(
                            `pages.patients.selectedPatient.header.${isChatRoomResolved ? 'markUnread' : 'markRead'}`
                          )}
                          className={classes.actionsLabel}
                          onClick={handleMarkReadUnread}
                        />
                      </Grid>
                      {gaFlags.notifyPractice && isPatientActive && isHiUser ? (
                        <Grid
                          item
                          xs={2}
                          className={needsAttentionTag ? classes.headerItem : classes.headerItemWithHover}
                        >
                          <NotifyPracticeButton patientId={patient?.id} isDisabled={!!needsAttentionTag} />
                        </Grid>
                      ) : (
                        <Grid item xs={2} className={classes.headerItemWithHover}>
                          <SecondaryButton
                            useContentHeight
                            useContentWidth
                            disableRipple={true}
                            hideBorder={true}
                            icon={<UploadRecords />}
                            iconPosition="top"
                            label={t('pages.patients.selectedPatient.header.manageRecords')}
                            className={classes.actionsLabel}
                            onClick={handleOpenPatientCardRecordsTab}
                            isLoading={isUploadingStls}
                          />
                        </Grid>
                      )}
                      <Grid item xs={3} className={classes.headerItem}>
                        <Grid container className={classes.statusContainer}>
                          <Grid item>
                            <GrinLabel fontSize={10}>{t('pages.patients.selectedPatient.header.status')}</GrinLabel>
                          </Grid>
                          <Grid item>
                            <PatientStatusUpdate
                              id="patient-room-header-status-button"
                              variant="noBorder"
                              patient={patient}
                              analyticsSource="Patient header"
                            />
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid item xs={4} className={classes.headerItem}>
                        <Grid container className={classes.assigneeContainer}>
                          <Grid item>
                            <GrinLabel fontSize={10}>{t('pages.patients.selectedPatient.header.assignedTo')}</GrinLabel>
                          </Grid>
                          <Grid item>
                            <Assignee patient={patient} assigneeTag={assigneeTag} />
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                )}
              </Grid>
            )}
          </Grid>
        )
      )}
    </Grid>
  )
}

export default PatientsHeader
