import React, { useEffect, useMemo, useState, useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Grid } from '@material-ui/core'
import { useStyles } from './EditDialogStyle'
import moment from 'moment'
import momenttz from 'moment-timezone'
import { useTranslation } from 'react-i18next'
import Actions from 'actions'
import { TIME_FORMAT_4 } from 'consts/dateTimeConsts'
import { toUTC, beautifyPhoneNumber } from 'utils/stringUtils'
import PhoneNumberInput from 'components/common/PhoneNumberInput'
import PrimaryButton from 'components/common/buttons/PrimaryButton'
import GeneralInfoGridItem from 'components/Support/SupportDashboard/GeneralInfoGridItem'
import FormInput from 'components/common/FormInput'
import SelectInput from 'components/common/SelectInput'
import StateInput from 'components/common/StateInput'
import { COUNTRY_CA } from 'consts/countryConsts'
import { useCountry } from 'hooks/useCountry'
import TextAreaAutosize from 'components/common/TextareaAutosize'
import GrinLabel from 'components/common/text/GrinLabel'
import GrinCheckbox from 'components/common/GrinCheckbox'
import { trackEvent } from 'utils/analyticsUtils'

const GeneralInfoTab = () => {
  const classes = useStyles()
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const accountOwner = useSelector(state =>
    state.supportReducer.doctorsDashboard.selectedDoctor.accountOwnerId
      ? state.supportReducer.doctorsDashboard.selectedDoctor.accountOwner
      : state.supportReducer.doctorsDashboard.selectedDoctor
  )
  const userId = useSelector(state => state.supportReducer.doctorsDashboard.selectedDoctor.user.id)

  const notes = useSelector(state => state.supportReducer.doctorsDashboard.notes.data)
  const lastNote = useMemo(() => (notes?.length ? notes[notes?.length - 1] : {}), [notes])

  const UTC = useMemo(() => toUTC(accountOwner.user?.timezone), [accountOwner])
  const localTime = useMemo(
    () =>
      `${momenttz()
        .tz(accountOwner.user?.timezone || '')
        ?.format('h:mm A')} (${UTC})`,
    [UTC, accountOwner]
  )

  const [additionalPhone, setAdditionalPhone] = useState(accountOwner?.clinic?.additionalPhone)
  const [address, setAddress] = useState(accountOwner?.clinic?.address1)
  const [city, setCity] = useState(accountOwner?.clinic?.city)
  const [state, setState] = useState(accountOwner?.clinic?.state)
  const [country, setCountry] = useState(accountOwner?.clinic?.country)
  const [zip, setZip] = useState(accountOwner?.clinic?.zip)
  const [bio, setBio] = useState(accountOwner?.bio)
  const [hasLocatorConsent, setHasLocatorConsent] = useState(accountOwner?.hasLocatorConsent)

  const { countriesOptions, countriesWithRequiredStates, hasZipcode } = useCountry(country)

  const isValid = useMemo(
    () => !address || !city || (countriesWithRequiredStates.includes(country) && !state) || !country || !zip,
    [address, city, state, country, zip, countriesWithRequiredStates]
  )

  const handleSave = useCallback(() => {
    trackEvent('Support - Edit doctor General Info - saved clicked', {
      accountOwnerId: accountOwner.id,
      userId,
      practiceName: accountOwner.clinic?.practiceName
    })
    dispatch(
      Actions.supportUpdateDoctor({
        id: accountOwner.id,
        _version: accountOwner._version,
        bio,
        hasLocatorConsent,
        clinic: {
          ...accountOwner.clinic,
          additionalPhone,
          address1: address,
          city,
          state,
          country,
          zip
        }
      })
    )
  }, [accountOwner, additionalPhone, address, city, state, country, zip, dispatch, bio, hasLocatorConsent, userId])

  useEffect(() => {
    if (!notes) {
      dispatch(Actions.supportFetchDoctorNotesByUserId(userId))
    }
  }, [notes, dispatch, userId])

  useEffect(() => {
    if (!hasZipcode) {
      setZip('')
    }
  }, [hasZipcode])

  return (
    <Grid
      container
      justifyContent="space-between"
      direction="column"
      alignItems="center"
      className={classes.tabWithButtonContainer}
    >
      <Grid item className={classes.tabWithButtonContent}>
        <Grid container spacing={2}>
          <GeneralInfoGridItem label={t('dialogs.doctorCardEditor.anchorUserEmailLabel')}>
            {accountOwner?.email}
          </GeneralInfoGridItem>
          <Grid item xs={12} sm={6}>
            <Grid container spacing={4}>
              <GeneralInfoGridItem label={t('dialogs.doctorCardEditor.registrationDateLabel')}>
                {moment(accountOwner?.createdAt).format(TIME_FORMAT_4)}
              </GeneralInfoGridItem>
              <GeneralInfoGridItem label={t('dialogs.doctorCardEditor.termsVersionLabel')}>
                {accountOwner?.user?.termsVersion?.replace('v', '')}
              </GeneralInfoGridItem>
            </Grid>
          </Grid>
          <GeneralInfoGridItem label={t('dialogs.doctorCardEditor.practiceNameLabel')}>
            {accountOwner?.clinic?.practiceName}
          </GeneralInfoGridItem>
          <GeneralInfoGridItem label={t('dialogs.doctorCardEditor.practiceEmailLabel')}>
            {accountOwner?.clinic?.email}
          </GeneralInfoGridItem>
          <Grid item xs={12} sm={6}>
            <FormInput
              title={t('dialogs.editPatient.address.label')}
              placeholder={t('dialogs.editPatient.address.placeholder')}
              style={{ bright: true, thick: true }}
              required
              value={address}
              setValue={setAddress}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormInput
              title={t('dialogs.editPatient.city.label')}
              placeholder={t('dialogs.editPatient.city.placeholder')}
              style={{ bright: true, thick: true }}
              required
              value={city}
              setValue={setCity}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Grid container spacing={4}>
              <Grid item xs={12} sm={6}>
                <SelectInput
                  label={t('dialogs.editPatient.country')}
                  style={{ bright: true, thick: true }}
                  value={country}
                  options={countriesOptions}
                  required
                  keepErrorContainerWhenInactive={false}
                  onChange={({ value }) => {
                    setState('')
                    setCountry(value)
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <StateInput
                  country={country}
                  label={t(`dialogs.editPatient.${country === COUNTRY_CA ? 'province' : 'state.label'}`)}
                  placeholder={t('dialogs.editPatient.state.placeholder')}
                  disabled={!country}
                  value={state}
                  setValue={setState}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Grid container spacing={4}>
              <Grid item xs={12} sm={6}>
                <FormInput
                  validationRules={{
                    required: hasZipcode
                  }}
                  isDisabled={!hasZipcode}
                  title={t('dialogs.editPatient.zip.label')}
                  placeholder={hasZipcode ? t('dialogs.editPatient.zip.placeholder') : ''}
                  style={{ bright: true, thick: true }}
                  required
                  value={zip}
                  setValue={setZip}
                />
              </Grid>
              <GeneralInfoGridItem label={t('dialogs.doctorCardEditor.localTimeLabel')}>
                {localTime}
              </GeneralInfoGridItem>
            </Grid>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Grid container spacing={4}>
              <GeneralInfoGridItem label={t('dialogs.doctorCardEditor.practicePhoneLabel')}>
                {`+${beautifyPhoneNumber(accountOwner?.clinic?.countryCode, accountOwner?.clinic?.phone)}`}
              </GeneralInfoGridItem>
              <Grid item xs={12} sm={6}>
                <PhoneNumberInput
                  title={t('dialogs.doctorCardEditor.additionalPhoneLabel')}
                  style={{ bright: true, thick: true }}
                  required
                  value={additionalPhone}
                  setValue={setAdditionalPhone}
                />
              </Grid>
            </Grid>
          </Grid>
          <GeneralInfoGridItem label={t('dialogs.doctorCardEditor.lastNoteAddedLabel')}>
            {lastNote?.body}
          </GeneralInfoGridItem>
        </Grid>
        <Grid item className={classes.divider50}></Grid>
        <Grid item sm={6}>
          <GrinLabel>
            <strong>{t('dialogs.doctorCardEditor.doctorLocatorSettings.sectionLabel')}</strong>
          </GrinLabel>
          <div className={classes.divider20}></div>
          <GrinCheckbox
            caption={t('dialogs.doctorCardEditor.doctorLocatorSettings.showOnLocator')}
            checked={hasLocatorConsent}
            onChange={value => setHasLocatorConsent(value)}
            variant="filled"
          />
          <div className={classes.bioInputContainer}>
            <TextAreaAutosize
              rowsMin={3}
              rowsMax={3}
              style={{ bright: true }}
              title={t('dialogs.doctorCardEditor.doctorLocatorSettings.bioInputLabel')}
              keepErrorContainerWhenInactive={false}
              value={bio}
              setValue={setBio}
              id="doctor-bio-input"
            />
          </div>
        </Grid>
      </Grid>
      <Grid item>
        <PrimaryButton label={t('general.save')} onClick={handleSave} disabled={isValid} />
      </Grid>
    </Grid>
  )
}

export default GeneralInfoTab
