import { formatDynamicFields } from 'utils/graphqlUtils'

const scanReviewFields = `
  id
  createdAt
  grinScanId
  video {
    bucket
    region
    key
  }
  reviewer {
    id
    name
    roleDescription
  }
`

const grinScanFields = `
  id
  _version
  withAligner
  scanSummaryStatus
  scanSummaryData
  trackingStatus
  trackingDetails
  treatmentTrackerData
  treatmentTrackerStatus
  applianceIndex
  createdAt
  metadata
  video {
    key
    bucket
    region
  }
  referrals {
    items {
      id
      referralData
      notificationMedium
      notificationDestination
      grinDoctorNote
      doctorId
      doctor {
        name
        roleDescription
      }
      relatedEntityId
      referralReview
      reviewStatus
      entityType
      createdAt
      reviewSubmissionDate
    }
  }
  scanReviews {
    items {
      ${scanReviewFields}
    }
  }
`

export const mainFeed = {
  timelineItem: `
    id
    type
    eventDate
    payload
    _deleted
  `
}

export const scanFeed = {
  timelineItem: `
    id
    type
    eventDate
    payload
    grinScan {
      ${grinScanFields}
    }
    grinScans(filter: {status: {ne: "uploading"}}) {
      items {
        ${grinScanFields}
      }
    }
  `,
  scanReview: scanReviewFields,
  grinScan: grinScanFields,
  initialScan: `
    id
    date
    oralImages {
      key
      bucket
      region
    }
  `
}

export const timelineItemsByPatientIdSorted = fields => /* GraphQL */ `
  query TimelineItemsByPatientIdSorted(
    $patientId: ID
    $eventDate: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $limit: Int
    $nextToken: String
  ) {
    timelineItemsByPatientIdSorted(
      patientId: $patientId, 
      eventDate: $eventDate, 
      limit: $limit, 
      sortDirection: $sortDirection, 
      nextToken: $nextToken
    ) {
      items {
        ${formatDynamicFields(fields)}
      }
      nextToken
    }
  }
`

export const getTimelineItem = fields => /* GraphQL */ `
  query GetTimelineItem(
    $id: ID!
  ) {
    getTimelineItem(
      id: $id  
    ) {
      ${formatDynamicFields(fields)}
    }
  }
`

export const getScanReview = fields => /* GraphQL */ `
  query GetScanReview($id: ID!) {
    getScanReview(id: $id) {
      ${formatDynamicFields(fields)}
    }
  }
`

export const getInitialScan = fields => /* GraphQL */ `
  query GetInitialScan($id: ID!) {
    getInitialScan(id: $id) {
      ${formatDynamicFields(fields)}
    }
  }
`
