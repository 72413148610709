import moment from 'moment'
import React, { useMemo } from 'react'
import { makeStyles } from '@material-ui/styles'
import { Grid } from '@material-ui/core'
import DazzedParagraph12 from 'components/common/text/DazzedParagraph12'
import { useSelector } from 'react-redux'
import ProfilePicture from 'components/common/ProfilePicture'

const useStyles = makeStyles(theme => ({
  circle: {
    margin: '0 6px',
    fontSize: 5,
    color: 'rgba(0, 0, 0, 0.3)',
    verticalAlign: 'middle'
  },
  createdAtAgo: {
    color: 'var(--text-color-11)'
  }
}))

const CreatorInfo = ({ userId, name, roleDescription, updatedAt }) => {
  const classes = useStyles()

  const { data: practiceMembers } = useSelector(state => state.profileReducer.practiceMembers)

  const currentMember = useMemo(() => practiceMembers.find(member => member.grinUserId), [practiceMembers])

  return (
    <Grid container alignItems="center" spacing={1}>
      <Grid item>
        <ProfilePicture size="small" photo={currentMember.avatar} className={classes.profilePicture} />
      </Grid>
      <Grid item>
        <Grid container direction="column">
          <Grid item>
            <DazzedParagraph12>
              <strong>{name}</strong>
              {roleDescription ? (
                <>
                  <span className={classes.circle}>{`●`}</span>
                  <span style={{ fontWeight: 400 }}>{roleDescription}</span>
                </>
              ) : (
                ''
              )}
            </DazzedParagraph12>
          </Grid>
          <Grid item>
            <DazzedParagraph12 className={classes.createdAtAgo}>{moment(updatedAt).fromNow()}</DazzedParagraph12>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  )
}

export default CreatorInfo
