import { makeStyles, Menu } from '@material-ui/core'
import Actions from 'actions'
import {
  AccountSettings,
  DesktopVersion,
  GrinAssets,
  Help,
  MobileInvitePatient,
  OrderGrinScopes,
  PracticeAnalytics
} from 'components/common/icons'
import ShareFeedback from 'components/common/icons/ShareFeedbackIcon'
import { ROUTES } from 'consts'
import useOrderScopes from 'hooks/useOrderScopes'
import usePlanLimits from 'hooks/usePlanLimits'
import useRolePermissions from 'hooks/useRolePermissions'
import React, { Fragment, useCallback, useMemo } from 'react'
import { isMobile as isMobileDevice } from 'react-device-detect'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { trackEvent } from 'utils/analyticsUtils'
import { isDesktopForced, isMobile, setForceDesktop } from 'utils/mobileUtils'
import { getKistOrder } from 'utils/storageUtils'
import AppProfileMenuItem from './AppProfileMenuItem'
import AddAccount from 'components/common/icons/AddAccount'
import AuthProfiles from './AuthProfiles/AuthProfiles'
import useGaFlags from 'hooks/useGaFlags'
import { countAuthProfiles } from 'logic/loginProfilesLogic'

const useStyles = makeStyles({
  menu: {
    width: ({ authProfilesCount, switchAccountsGaFlag }) =>
      !switchAccountsGaFlag ? '246px' : authProfilesCount > 1 ? '338px' : '280px'
  },
  appProfiles: {
    margin: '12px 0'
  }
})

const AppProfileMenu = ({ anchorEl, handleClick, handleClose, doctorId }) => {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const { permissions } = useRolePermissions()
  const grinAssetsLink = useSelector(state => state.appReducer.appconfig.app?.grinAssetsLink)
  const planLimits = usePlanLimits()
  const hasDraftOrder = getKistOrder()
  const gaFlags = useGaFlags()

  const isOpen = useMemo(() => Boolean(anchorEl), [anchorEl])
  const authProfilesCount = useMemo(() => countAuthProfiles(), [])

  const { openOrderScopesModal } = useOrderScopes()

  const handleLoginToAnotherAccount = useCallback(() => {
    window.open(ROUTES.SIGN_IN, '_blank')
    trackEvent('App Profile - login to another account clicked')
  }, [])

  const classes = useStyles({ switchAccountsGaFlag: gaFlags?.switchAccounts, authProfilesCount })

  return (
    <Fragment>
      <Menu
        anchorEl={anchorEl}
        keepMounted
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
        open={isOpen}
        onClose={handleClose}
        classes={{ paper: classes.menu }}
      >
        {permissions.opsDashboard && (
          <AppProfileMenuItem
            label={t('common.appMenu.opsDashboard')}
            to={ROUTES.SUPPORT_DASHBOARD}
            handleClose={handleClose}
          />
        )}
        {!isMobile() && (
          <AppProfileMenuItem
            label={t('common.appMenu.accountSettings')}
            icon={<AccountSettings />}
            to={ROUTES.ACCOUNT_SETTINGS}
            handleClose={handleClose}
          />
        )}
        {permissions.billing && (
          <AppProfileMenuItem
            label={t('common.appMenu.orderScopes')}
            hasIndicator={isMobile() && hasDraftOrder}
            disabled={planLimits.maxScopes <= 0}
            handleClose={handleClose}
            icon={<OrderGrinScopes />}
            onClickExtra={openOrderScopesModal}
          />
        )}
        {isMobile() && permissions.invitePatient && (
          <AppProfileMenuItem
            label={t('common.appMenu.invitePatient')}
            handleClose={handleClose}
            icon={<MobileInvitePatient />}
            to={ROUTES.INVITE_PATIENT}
          />
        )}
        {(permissions.practiceAnalytics || permissions.dsoAnalytics || permissions.distributorAnalytics) &&
          !isMobile() && (
            <AppProfileMenuItem
              label={t('common.appMenu.practiceAnalytics')}
              icon={<PracticeAnalytics />}
              to={ROUTES.NEW_DOC_STATS}
              handleClose={handleClose}
            />
          )}
        {permissions.quickSignUp && (
          <AppProfileMenuItem
            label={t('common.appMenu.quickDoctorSignup')}
            onClickExtra={() => window.open(ROUTES.QUICK_SIGN_UP, '_blank')}
            handleClose={handleClose}
          />
        )}
        {isMobileDevice && (
          <AppProfileMenuItem
            label={isDesktopForced() ? t('common.appMenu.mobileVersion') : t('common.appMenu.desktopVersion')}
            onClickExtra={() => setForceDesktop(!isDesktopForced())}
            icon={<DesktopVersion />}
            handleClose={handleClose}
          />
        )}
        <AppProfileMenuItem
          label={t('common.appMenu.marketingAssets')}
          icon={<GrinAssets />}
          handleClose={handleClose}
          onClickExtra={() => window.open(grinAssetsLink, '_blank')}
          // to={ROUTES.ASSETS} // Enable when we have the new hubspot page and remove the onClick
        />
        <AppProfileMenuItem
          label={t('common.appMenu.shareFeedback')}
          icon={<ShareFeedback />}
          handleClose={handleClose}
          onClickExtra={() => {
            trackEvent('App Feedback Modal - modal opened', {
              source: 'Profile menu'
            })
            dispatch(
              Actions.toggleAppFeedbackDialog({
                value: true,
                description: t('dialogs.shareFeedback.feedbackDescription')
              })
            )
          }}
        />
        <AppProfileMenuItem
          label={t('common.appMenu.help')}
          onClickExtra={() => dispatch(Actions.openHelpPopup())}
          icon={<Help />}
          handleClose={handleClose}
        />
        {gaFlags?.switchAccounts && !isMobile() && <AuthProfiles className={classes.appProfiles} />}
        {gaFlags?.switchAccounts && !isMobile() && (
          <AppProfileMenuItem
            label={t('common.appMenu.logInToAnotherAccount.button')}
            subtitle={t('common.appMenu.logInToAnotherAccount.subtitle')}
            onClickExtra={handleLoginToAnotherAccount}
            handleClose={handleClose}
            icon={<AddAccount />}
          />
        )}
        <AppProfileMenuItem
          id="header-menu-logout"
          label={t('common.appMenu.logOut')}
          variant="button"
          handleClose={handleClose}
          onClickExtra={() => dispatch(Actions.requestSignOut())}
        />
      </Menu>
    </Fragment>
  )
}

export default AppProfileMenu
