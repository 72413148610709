import React from 'react'
import { Grid, makeStyles } from '@material-ui/core'
import MessageTypeWrapper from '../MessageTypeWrapper'
import { getObjectUrl } from 'utils/mediaUtils'
import { isMobile } from 'utils/mobileUtils'
import DazzedParagraph10 from 'components/common/text/DazzedParagraph10'
import VideoThumbnail from 'components/common/icons/SavedFiles/VideoThumbnail'

const useStyles = makeStyles(() => ({
  message: {
    backgroundColor: '#FFFFFF'
  },
  messageUserInfo: {
    marginLeft: 5
  },
  timestamp: {
    position: 'absolute',
    bottom: 0,
    left: 0,
    right: 0,
    height: 41,
    padding: '12px 18px 0 18px',
    background: 'linear-gradient(0deg, rgba(0, 0, 0, .5), rgba(0, 0, 0, 0))',
    borderRadius: '0 0 8px 8px',
    color: 'white',
    fontSize: 10,
    textAlign: 'right'
  },
  fileTitleContainer: {
    paddingTop: 5
  }
}))

const VideoMessage = ({
  id,
  content,
  isLocal,
  cache,
  alignLeft,
  name,
  userType,
  isFirstMessage,
  isLastMessage,
  timestamp,
  isBroadcast,
  fileTitle,
  withMessageActions
}) => {
  const classes = useStyles()

  return (
    <MessageTypeWrapper
      id={id}
      isFirstMessage={isFirstMessage}
      isLastMessage={isLastMessage}
      alignLeft={alignLeft}
      name={name}
      userType={userType}
      infoClassName={classes.messageUserInfo}
      isBroadcast={isBroadcast}
      withMessageActions={withMessageActions}
    >
      <video width={isMobile() ? '250' : '350'} height="200" preload="metadata" muted controls>
        <source src={`${isLocal ? cache : getObjectUrl(cache || content)}#t=0.01`} type="video/mp4" />
      </video>
      {fileTitle && (
        <Grid container alignItems="center" spacing={1} className={classes.fileTitleContainer}>
          <Grid item>
            <VideoThumbnail />
          </Grid>
          <Grid item>
            <DazzedParagraph10>{fileTitle}</DazzedParagraph10>
          </Grid>
        </Grid>
      )}
      <div className={classes.timestamp}>{timestamp}</div>
    </MessageTypeWrapper>
  )
}

export default React.memo(VideoMessage)
