import actions from 'actions'
import { useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'

/**
 * A hook that makes navigating to a specific patient easy.
 * @param {Object} props
 * @param {string} props.patientId - The ID of the patient to navigate to.
 * @param {boolean} [props.forceNavigation=false] - If true, the navigation will occur even if the patient is already selected.
 */
export default ({ patientId, forceNavigation = false }) => {
  const dispatch = useDispatch()

  const currentPatientId = useSelector(state => state.patientsReducer.patient?.id)
  const patientSM = useSelector(state => state.chatReducer.rooms.find(patientSM => patientSM.id === patientId))

  const selectPatient = useCallback(() => {
    if (!patientId || (currentPatientId === patientId && !forceNavigation)) {
      return
    }

    dispatch(
      actions.chatRoomSelected({
        patientId,
        roomId: patientSM?.roomId,
        previewDetails: {
          name: patientSM?.name,
          photo: patientSM?.profilePicture
        }
      })
    )
  }, [dispatch, patientSM, patientId, currentPatientId, forceNavigation])

  return selectPatient
}
