import { GrinNotificationStatuses, GrinNotificationBulkActions } from '@grin-rnd/grin-api-sdk/dist/Enums/Notifications'

const notNull = value => !!value

/**
 * Builds a filter object to use for `searchGrinNotifications` query.
 *
 * @param {Object} args
 * @param {Boolean} args.unreadOnly - Whether to fetch only unread notifications or not.
 * @param {Array} args.notificationTypes - Array of notification types to filter by. @see GrinNotificationTypes
 * @param {String} args.grinUserId - Recipient Grin user ID.
 * @param {String} args.lastMarkAllAsReadDate - Date of the last time the user marked all notifications as read.
 * @param {String} args.lastClearAllDate - Date of the last time the user cleared all notifications.
 * @returns {Object} AppSync filter object to use for `searchGrinNotifications` query.
 */
export const buildGrinNotificationFilter = ({
  unreadOnly,
  notificationTypes = [],
  grinUserId,
  lastMarkAllAsReadDate,
  lastClearAllDate
}) => ({
  and: [
    {
      recipientGrinUserId: {
        eq: grinUserId
      }
    },
    notificationTypes.length && {
      or: notificationTypes.map(type => ({
        type: { eq: type }
      }))
    },
    // If the user has cleared all notifications, only fetch notifications after that date.
    lastClearAllDate && {
      eventDate: {
        gte: lastClearAllDate
      }
    },
    unreadOnly
      ? {
          and: [
            // If the user has marked all notifications as read, only fetch notifications on status "New" after that date.
            {
              status: { eq: GrinNotificationStatuses.New }
            },
            lastMarkAllAsReadDate && {
              eventDate: {
                gte: lastMarkAllAsReadDate
              }
            }
          ].filter(notNull)
        }
      : {
          // If we want all notifications, fetch both "New" and "Read" notifications regardless to the lastMarkAllAsReadDate.
          or: [
            {
              status: { eq: GrinNotificationStatuses.New }
            },
            {
              status: { eq: GrinNotificationStatuses.Read }
            }
          ]
        }
  ].filter(notNull)
})

export const extractBulkActionSettings = appSettingsJson => {
  const appSettings = JSON.parse(appSettingsJson || '{}')
  const bulkActionSettings = appSettings.notificationCenter?.bulkActions || {}

  return {
    lastMarkAllAsReadDate: bulkActionSettings[GrinNotificationBulkActions.MarkAllAsRead]?.lastRequestedAt,
    lastClearAllDate: bulkActionSettings[GrinNotificationBulkActions.ClearAll]?.lastRequestedAt
  }
}

/**
 * Gets the original notification status and calculates the actual status based on the lastClearAllDate and lastMarkAllAsReadDate.
 * @param {Object} args
 * @param {String} args.originalStatus - Original notification status.
 * @param {String} args.eventDate - Notification event date.
 * @param {String} args.lastClearAllDate - Date of the last time the user cleared all notifications.
 * @param {String} args.lastMarkAllAsReadDate - Date of the last time the user marked all notifications as read.
 * @returns {String} Actual notification status.
 */
export const mapNotificationStatus = ({ originalStatus, eventDate, lastClearAllDate, lastMarkAllAsReadDate }) => {
  if (lastClearAllDate && eventDate < lastClearAllDate) {
    return GrinNotificationStatuses.Deleted
  }

  if (lastMarkAllAsReadDate && eventDate < lastMarkAllAsReadDate) {
    return GrinNotificationStatuses.Read
  }

  return originalStatus
}
