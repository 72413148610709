import React, { useCallback, useMemo, useState } from 'react'
import { Trans } from 'react-i18next'
import { birthdateFormat } from '../../../../utils/patientUtils'
import DazzedParagraph14 from 'components/common/text/DazzedParagraph14'
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers'
import DateFnsUtils from '@date-io/date-fns'
import moment from 'moment'
import { trackEvent } from 'utils/analyticsUtils'
import { makeStyles } from '@material-ui/core'

const useStyles = makeStyles({
  date: {
    padding: '2px 4px 2px 4px',
    cursor: 'pointer',
    transition: 'background-color 0.5s',
    '&:hover': {
      backgroundColor: 'var(--bg-color-patient-card-field)'
    }
  }
})

const PatientAge = ({ age, birthdate, onChange }) => {
  const classes = useStyles()

  const [currentBirthdate, setCurrentBirthdate] = useState(birthdate)
  const [isEditMode, setIsEditMode] = useState(false)

  const utcBirthdate = useMemo(() => moment(birthdate).utcOffset(0, false).startOf('day').toISOString(), [birthdate])

  const handleAgeUpdated = useCallback(
    newBirthdate => {
      const parsedNewBirthdate = moment(newBirthdate).toISOString()
      setCurrentBirthdate(parsedNewBirthdate)

      trackEvent('Patient card - Age updated', {
        newBirthdate: parsedNewBirthdate
      })
      onChange({ birthdate: newBirthdate })
    },
    [onChange]
  )

  return (
    <>
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <DatePicker
          variant="dialog"
          open={isEditMode}
          disableFuture
          value={utcBirthdate}
          onChange={handleAgeUpdated}
          onClose={() => setIsEditMode(false)}
          TextFieldComponent={() => (
            <DazzedParagraph14 onClick={() => setIsEditMode(true)} className={classes.date}>
              <Trans
                values={{
                  age,
                  birthdate: birthdateFormat(currentBirthdate)
                }}
                i18nKey={'pages.patients.selectedPatient.header.age'}
                components={{ bold: <b>{''}</b> }}
              />
            </DazzedParagraph14>
          )}
        />
      </MuiPickersUtilsProvider>
    </>
  )
}

export default PatientAge
