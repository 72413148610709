import { UrgencyStatuses } from '@grin-rnd/grin-api-sdk/dist/Enums/Timeline'
import { Grid } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import ScanDateLabel from 'components/common/ScanDateLabel'
import DazzedParagraph14 from 'components/common/text/DazzedParagraph14'
import Tooltip from 'components/common/Tooltip'
import { OralScoreCategories } from 'consts/oralScoreConsts'
import { ScanFeedPageSectionNames } from 'consts/timelineConsts'
import useFeatureFlags from 'hooks/useFeatureFlags'
import React, { useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { trackEvent } from 'utils/analyticsUtils'
import { getObjectUrl } from 'utils/mediaUtils'
import AlignerNumber from '../../AlignerNumber'
import OralStatus from '../../MiniTimeline/OralStatus'
import ScanStats from '../../ScanStats'
import ScanThumbnail from '../../ScanThumbnail'
import OralScoreTag from '../../Tags/OralScoreTag'
import TrackStatusTag from '../../Tags/TrackStatusTag'
import UrgentScanTag from '../../Tags/UrgentScanTag'
import useScanOralScore from '../../useScanOralScore'
import useScanStats from '../../useScanStats'
import Divider from './Divider'

const useStyles = makeStyles(theme => ({
  grinScanTimelineItemRoot: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-end',
    padding: '8px 8px 0px 8px',
    borderRadius: 10,
    '&:hover': {
      background: '#eaf3fd',
      cursor: 'pointer',
      transition: '0.3s'
    }
  },
  scanDetails: {
    alignSelf: 'flex-start',
    display: 'flex',
    flexDirection: 'column'
  },
  scanInfo: {
    display: 'flex',
    marginBottom: 5
  },
  scanInfoSection: {
    marginLeft: 8
  },
  scanDate: {
    marginBottom: 5
  },
  scanStats: {
    marginTop: 15,
    marginBottom: 5
  },
  scanVideos: {
    display: 'flex',
    flexDirection: 'row',
    height: '100%',
    justifyContent: 'flex-end',
    alignItems: 'flex-end'
  },
  thumbnailContainer: {
    width: 177,
    marginLeft: 5,
    marginBottom: 5
  }
}))

const GrinScanTimelineItem = ({ timelineItem, onSelect = () => {} }) => {
  const { t } = useTranslation()
  const classes = useStyles()
  const { oralHygieneScore: oralHygieneScoreFF } = useFeatureFlags()
  const oralScores = useScanOralScore({ oralScores: timelineItem?.payload?.oralScores })

  const { treatment } = useSelector(state => state.treatmentReducer)
  const { numberOfReviews, numberOfShares } = useScanStats({ timelineItem })

  const {
    scanNumber,
    alignerNumber,
    scans,
    trackStatus,
    urgency = {}
  } = useMemo(() => timelineItem?.payload, [timelineItem])

  const totalAlignerNumber = useMemo(() => treatment?.totalApplianceNumber, [treatment])
  const withOralScore = useMemo(() => oralHygieneScoreFF && oralScores?.oralScore, [oralHygieneScoreFF, oralScores])
  const timelineItemOffTrackReasons = useMemo(
    () =>
      scans
        .map(scan => scan?.offTrackReasons)
        .filter(reasons => !!reasons)
        .reduce((final, currReasons) => [...final, ...currReasons], []),
    [scans]
  )

  const handleReviewsClicked = useCallback(
    e => {
      e.preventDefault()
      e.stopPropagation()
      onSelect({ section: ScanFeedPageSectionNames.Reviews })
      trackEvent('Timeline - Main feed - Scan item - Reviews clicked')
    },
    [onSelect]
  )

  const handleSharesClicked = useCallback(
    e => {
      e.preventDefault()
      e.stopPropagation()
      onSelect({ section: ScanFeedPageSectionNames.Shares })
      trackEvent('Timeline - Main feed - Scan item - Shares clicked')
    },
    [onSelect]
  )

  const handleTrackingReasonsClicked = useCallback(
    e => {
      e.preventDefault()
      e.stopPropagation()
      onSelect({ section: ScanFeedPageSectionNames.ScanTracking })
      trackEvent('Timeline - Main feed - Scan item - Tracking reasons clicked')
    },
    [onSelect]
  )

  return (
    <div>
      <div className={classes.grinScanTimelineItemRoot} onClick={onSelect}>
        <div className={classes.scanDetails}>
          <div className={classes.scanInfo}>
            <DazzedParagraph14 strong>
              {t('pages.patients.selectedPatient.timelineV2.mainFeed.grinScanItem.title', { scanNumber })}
            </DazzedParagraph14>
            {alignerNumber && (
              <AlignerNumber
                alignerNumber={alignerNumber}
                totalAlignerNumber={totalAlignerNumber}
                className={classes.scanInfoSection}
              />
            )}
            {urgency.urgencyStatus !== UrgencyStatuses.None && (
              <div className={classes.scanInfoSection}>
                <UrgentScanTag urgencyStatus={urgency.urgencyStatus} reason={urgency.reason} denseMode />
              </div>
            )}
          </div>
          <ScanDateLabel
            timestamp={timelineItem?.eventDate}
            color="var(--text-color-11)"
            className={classes.scanDate}
          />
          <Grid container alignItems="flex-start" spacing={1} direction="row">
            <TrackStatusTag
              trackStatus={trackStatus}
              reasons={timelineItemOffTrackReasons}
              reasonsOnClick={handleTrackingReasonsClicked}
            />
            {withOralScore && (
              <Grid item>
                <Tooltip placement="right" variant="light" arrow value={<OralStatus oralScores={oralScores} />}>
                  <OralScoreTag
                    scoreValue={oralScores.hygieneScore}
                    category={OralScoreCategories.Hygiene}
                    withBackground
                    withIcon
                  />
                </Tooltip>
              </Grid>
            )}
          </Grid>
          <ScanStats
            numberOfReviews={numberOfReviews}
            numberOfShares={numberOfShares}
            onReviewsClicked={handleReviewsClicked}
            onSharesClicked={handleSharesClicked}
            fontSize="12px"
            iconBackgroundColor={'rgba(220, 233, 248, 1)'}
            className={classes.scanStats}
          />
        </div>
        <div className={classes.scanVideos}>
          {scans.map(scan => (
            <ScanThumbnail
              key={`scan-thumbnail-${scan.id}`}
              mediaType="image"
              src={getObjectUrl(scan.thumbnail)}
              renderAlignerIconOverlay={scan?.withAligner !== null}
              withAligner={scan?.withAligner}
              containerClassName={classes.thumbnailContainer}
            />
          ))}
        </div>
      </div>
      <Divider />
    </div>
  )
}

export default React.memo(GrinScanTimelineItem)
