import React, { useCallback, useState } from 'react'
import { Grid, makeStyles } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import Actions from 'actions'
import VideoWrapper from 'components/common/VideoWrapper'
import { isMobile } from 'utils/mobileUtils'
import MessageTypeWrapper from '../MessageTypeWrapper'
import { trackEvent } from 'utils/analyticsUtils'
import LeaveModal from 'components/common/modals/LeaveModal'
import { AsyncStatus } from 'consts'
import DazzedParagraph10 from 'components/common/text/DazzedParagraph10'
import LinkButton from 'components/common/buttons/LinkButton'
import Refresh from 'components/common/icons/Refresh'
import Download from 'components/common/icons/Download1'
import { downloadFile } from 'utils/fileUtils'
import ScanVideoPlaceholder from './ScanVideoPlaceholder'
import { generateMediaPath } from 'utils/mediaUtils'

const useStyles = ({ alignLeft }) =>
  makeStyles({
    container: {
      position: 'relative',
      textAlign: 'center',
      color: 'white',
      cursor: 'pointer',
      height: '100%',
      fontSize: 0
    },
    text: {
      position: 'absolute',
      top: 4,
      left: 14,
      fontSize: 10,
      fontWeight: 500
    },
    video: {
      maxWidth: '90%',
      height: '141px',
      alignSelf: 'center',
      outline: 'none',
      borderRadius: 20
    },
    timestamp: {
      position: 'absolute',
      bottom: 2,
      right: 18,
      fontSize: 10,
      textAlign: 'right',
      color: 'white'
    },
    messageUserInfo: {
      color: 'rgba(0, 19, 53, 0.7)',
      marginBottom: 3,
      marginLeft: 5
    },
    errorLabel: {
      justifySelf: 'flex-end',
      color: 'red'
    }
  })({ alignLeft })

const ScanReviewMessage = ({
  id,
  content,
  type,
  isLocal,
  cache,
  alignLeft,
  timestamp,
  name,
  userType,
  isFirstMessage = false,
  isLastMessage = false,
  patientId,
  uploadStatus,
  s3UniqueId,
  patientName = ''
}) => {
  const classes = useStyles({ alignLeft })
  const dispatch = useDispatch()
  const { t } = useTranslation()

  const uploadData = useSelector(state => state.treatmentReducer.scanReviewEditorDialog.uploads[s3UniqueId])

  const [isVideoInitiated, setisVideoInitiated] = useState(false)

  const handleClick = useCallback(() => {
    trackEvent('Chat - scan review video clicked', {
      isMobile: isMobile(),
      patientId,
      messageId: id
    })

    if (isMobile()) {
      setisVideoInitiated(true)
    } else {
      dispatch(
        Actions.openScanViewDialog({
          s3Object: content,
          type,
          cache,
          isLocal
        })
      )
    }
  }, [content, dispatch, type, cache, isLocal, patientId, id])

  const handleDownloadVideo = useCallback(() => {
    trackEvent('Scan Review - download clicked')
    downloadFile(cache, `Scan Review - ${patientName}.mp4`)
  }, [cache, patientName])

  const handleRetryUpload = useCallback(async () => {
    if (uploadData) {
      trackEvent('Scan Review - retry upload clicked')

      const { s3UniqueId } = uploadData.originalPayload
      const path = await generateMediaPath({
        type: 'scanReview',
        objectIdToAuthorize: uploadData.originalPayload.scan.id,
        keySuffix: s3UniqueId
      })

      dispatch(
        Actions.requestSendScanReview({
          ...uploadData.originalPayload,
          path,
          attempts: 1,
          isRetry: true
        })
      )
    }
  }, [uploadData, dispatch])

  return (
    <Grid container direction="column">
      <Grid item>
        <MessageTypeWrapper
          id={id}
          isFirstMessage={isFirstMessage}
          isLastMessage={isLastMessage}
          alignLeft={alignLeft}
          name={name}
          userType={userType}
          useBubble
          infoClassName={classes.messageUserInfo}
        >
          {isVideoInitiated ? (
            <VideoWrapper s3Object={content} isLocal={isLocal} cache={cache} className={classes.video} autoplay />
          ) : (
            <div className={classes.container} onClick={handleClick}>
              <ScanVideoPlaceholder isScanReview />
              <div className={classes.text}>{t('pages.patients.selectedPatient.chat.scanReviewWasUploadedLabel')}</div>
              {timestamp && <div className={classes.timestamp}>{timestamp}</div>}
            </div>
          )}
        </MessageTypeWrapper>
      </Grid>
      <LeaveModal blockNavigation={uploadStatus === AsyncStatus.Loading || uploadStatus === AsyncStatus.Failed} />
      {uploadStatus === AsyncStatus.Failed && (
        <Grid container alignItems="center" style={{ padding: '10px 0' }}>
          <Grid item xs={4}>
            <LinkButton
              style={{ lineHeight: 0.8 }}
              icon={<Refresh />}
              label={t('general.retry')}
              onClick={handleRetryUpload}
              fontSize={12}
              underline
            />
          </Grid>
          <Grid item xs={5}>
            <LinkButton
              style={{ lineHeight: 0.8 }}
              icon={<Download />}
              label={t('general.download')}
              onClick={handleDownloadVideo}
              fontSize={12}
              underline
            />
          </Grid>
          <Grid item xs={3}>
            <DazzedParagraph10 className={classes.errorLabel}>
              {t('pages.patients.selectedPatient.chat.failed')}
            </DazzedParagraph10>
          </Grid>
        </Grid>
      )}
    </Grid>
  )
}

export default ScanReviewMessage
