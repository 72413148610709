import { Grid, makeStyles } from '@material-ui/core'
import React, { useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { NotificationMediums } from '@grin-rnd/grin-api-sdk/dist/Enums/Notifications'
import GrinSwitch from '../../common/GrinSwitch'
import DazzedParagraph14 from 'components/common/text/DazzedParagraph14'
import GrinCheckbox from 'components/common/GrinCheckbox'
import { trackEvent } from 'utils/analyticsUtils'
import { NOTIFY_PRACTICE_REASONS } from 'consts/giConsts'
import useRolePermissions from 'hooks/useRolePermissions'

const FilterTypes = {
  Assignee: 'assignee',
  NeedsAttentionClinical: NOTIFY_PRACTICE_REASONS.CLINICAL,
  NeedsAttentionScheduling: NOTIFY_PRACTICE_REASONS.SCHEDULING,
  NeedsAttentionMissingInfo: NOTIFY_PRACTICE_REASONS.MISSING_INFO,
  NeedsAttentionPatientQuestion: NOTIFY_PRACTICE_REASONS.PATIENT_QUESTION,
  NeedsAttentionSpecificMembersAttention: NOTIFY_PRACTICE_REASONS.SPECIFIC_MEMBERS_ATTENTIION,
  NeedsAttentionOther: NOTIFY_PRACTICE_REASONS.OTHER
}

const PREFERENCES_TEXTS = {
  Message: 'pages.accountSettings.notificationSettings.preferences.message',
  Scan: 'pages.accountSettings.notificationSettings.preferences.scan',
  Patient: 'pages.accountSettings.notificationSettings.preferences.patient',
  Assignee: 'pages.accountSettings.notificationSettings.preferences.assignee',
  RCLead: 'pages.accountSettings.notificationSettings.preferences.rcLead',
  ServiceAccountNewLead: 'pages.accountSettings.notificationSettings.preferences.ServiceAccountNewLead',
  NeedsAttention: 'pages.accountSettings.notificationSettings.preferences.needsAttention'
}

const entityFilterOptions = {
  Message: [FilterTypes.Assignee],
  Scan: [FilterTypes.Assignee],
  NeedsAttention: [
    FilterTypes.NeedsAttentionClinical,
    FilterTypes.NeedsAttentionMissingInfo,
    FilterTypes.NeedsAttentionPatientQuestion,
    FilterTypes.NeedsAttentionScheduling,
    FilterTypes.NeedsAttentionSpecificMembersAttention,
    FilterTypes.NeedsAttentionOther
  ]
}

const useStyles = makeStyles(theme => ({
  checkboxLabel: {
    fontSize: 14
  }
}))

const Preference = ({ entity, updateSubscriptions, withGrinNotificationOption }) => {
  const { t } = useTranslation()
  const classes = useStyles()
  const { permissions } = useRolePermissions()

  const entitiesMediumsOptions = useMemo(() => {
    const allMediumOptions = [
      NotificationMediums.Email,
      NotificationMediums.SMS,
      withGrinNotificationOption ? NotificationMediums.GrinNotification : null
    ].filter(m => !!m)

    return {
      Message: allMediumOptions,
      Scan: allMediumOptions,
      Patient: [NotificationMediums.Email, NotificationMediums.SMS],
      Assignee: allMediumOptions,
      RCLead: [NotificationMediums.Email, NotificationMediums.SMS],
      ServiceAccountNewLead: [NotificationMediums.Email, NotificationMediums.SMS],
      NeedsAttention: allMediumOptions
    }
  }, [withGrinNotificationOption])

  const handleToggleFilter = useCallback(
    (filter, value) => {
      trackEvent(`Notifications settings - notification filter toggled`, {
        notificationType: entity.type,
        filter,
        toggle: value ? 'on' : 'off'
      })

      const filters = entity.filters || []

      updateSubscriptions({
        ...entity,
        filters: value ? [...new Set([...filters, filter])] : filters.filter(curr => curr !== filter)
      })
    },
    [entity, updateSubscriptions]
  )

  const handleMediumChanged = useCallback(
    ({ target, medium }) => {
      trackEvent('Notifications settings - medium changed', {
        entityType: entity.type,
        medium,
        action: target.checked ? 'on' : 'off'
      })
      updateSubscriptions({
        ...entity,
        type: entity.type,
        medium: target.checked ? [...entity.medium, medium] : entity.medium.filter(m => m !== medium)
      })
    },
    [updateSubscriptions, entity]
  )

  const renderMediumCheckbox = useCallback(
    medium => (
      <div style={{ textAlign: 'center' }}>
        <GrinSwitch
          checked={entity.medium.includes(medium)}
          onChange={({ target }) => handleMediumChanged({ target, medium })}
        />
      </div>
    ),
    [entity, handleMediumChanged]
  )

  return (
    <Grid container direction="column" wrap="nowrap">
      <Grid item xs={12}>
        <Grid container alignItems="center">
          <Grid item xs={6}>
            <DazzedParagraph14 strong>{t(PREFERENCES_TEXTS[entity.type])}</DazzedParagraph14>
          </Grid>
          <Grid item xs={2}>
            {entitiesMediumsOptions[entity.type].includes(NotificationMediums.Email) &&
              renderMediumCheckbox(NotificationMediums.Email)}
          </Grid>
          <Grid item xs={2}>
            {entitiesMediumsOptions[entity.type].includes(NotificationMediums.SMS) &&
              renderMediumCheckbox(NotificationMediums.SMS)}
          </Grid>
          <Grid item xs={2}>
            {entitiesMediumsOptions[entity.type].includes(NotificationMediums.GrinNotification) &&
              renderMediumCheckbox(NotificationMediums.GrinNotification)}
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        {entityFilterOptions[entity.type] && (
          <Grid container direction="column">
            {entity.type === 'NeedsAttention' && !entity?.filters?.length && (
              <Grid item style={{ marginBottom: 5 }}>
                <DazzedParagraph14 strong color="red">
                  {t(`pages.accountSettings.notificationSettings.needsAttentionNote`)}
                </DazzedParagraph14>
              </Grid>
            )}
            <Grid item>
              {entityFilterOptions[entity.type]
                .filter(filterKey => permissions.notificationSettings.filters[filterKey] !== false)
                .map((filterOption, i) => (
                  <GrinCheckbox
                    key={i}
                    variant="filled"
                    checked={entity.filters?.includes(filterOption)}
                    onChange={value => handleToggleFilter(filterOption, value)}
                    caption={t(`pages.accountSettings.notificationSettings.filters.${filterOption}`)}
                    captionClassName={classes.checkboxLabel}
                  />
                ))}
            </Grid>
          </Grid>
        )}
      </Grid>
    </Grid>
  )
}

export default Preference
