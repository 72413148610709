import React from 'react'
import { makeStyles } from '@material-ui/styles'
import { useTranslation } from 'react-i18next'
import { CrossedCircle } from 'components/common/icons'
import DazzedParagraph14 from 'components/common/text/DazzedParagraph14'

const useStyles = makeStyles(theme => ({
  deletedMessage: {
    display: 'flex',
    alignItems: 'center',
    '& svg': {
      marginRight: 10
    }
  },
  timestamp: {
    marginTop: 5,
    fontSize: 10,
    textAlign: 'right',
    color: 'rgba(0, 19, 53, 0.45)',
    fontWeight: 500
  }
}))

const DeletedMessageContent = ({ timestamp }) => {
  const classes = useStyles()
  const { t } = useTranslation()

  return (
    <div>
      <div className={classes.deletedMessage}>
        <CrossedCircle color="var(--text-color-11)" />
        <DazzedParagraph14 strong color={'var(--text-color-11)'}>
          {t('pages.patients.selectedPatient.chat.messageDeleted')}
        </DazzedParagraph14>
      </div>
      <div className={classes.timestamp}>{timestamp}</div>
    </div>
  )
}

export default DeletedMessageContent
