import { Grid, makeStyles } from '@material-ui/core'
import GrinHighlighter from 'components/common/GrinHighlighter'
import ProfilePicture from 'components/common/ProfilePicture'
import Tooltip from 'components/common/Tooltip'
import { Check1, Copy } from 'components/common/icons'
import React from 'react'
import { useTranslation } from 'react-i18next'

const useStyles = makeStyles(theme => ({
  resultsItem: {
    fontSize: 12,
    padding: 10,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginTop: 3,
    marginBottom: 10,
    cursor: 'pointer',
    transition: 'background 0.3s ease',
    '&:hover': {
      background: '#e4e4e4',
      borderRadius: 6
    }
  },
  name: {
    fontSize: 14,
    fontWeight: 'bold',
    marginRight: 5
  },
  email: {
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    overflow: 'hidden'
  },
  avatar: {
    marginRight: 20
  },
  detailsContainer: {
    width: '90%',
    display: 'flex',
    alignItems: 'center'
  },
  actionContainer: {
    width: '10%',
    display: 'flex',
    justifyContent: 'end'
  }
}))

const DoctorResultItem = ({ searchTerm, doctorSM, setSelectedDoctor, copiedItemId, onCopyId }) => {
  const classes = useStyles()
  const { t } = useTranslation()

  return (
    <Grid container className={classes.resultsItem}>
      <Grid xs={11} className={classes.detailsContainer} onClick={() => setSelectedDoctor(doctorSM)}>
        <Grid item xs={2}>
          <ProfilePicture photo={null} name={doctorSM.name} size="medium" className={classes.avatar} />
        </Grid>
        <Grid item xs={7}>
          <Grid direction="column">
            <Grid>
              <GrinHighlighter
                className={classes.name}
                searchWords={[searchTerm]}
                autoEscape={true}
                textToHighlight={doctorSM.name}
              />
              <GrinHighlighter
                searchWords={[searchTerm]}
                autoEscape={true}
                textToHighlight={`(${doctorSM.practiceName})`}
              />
            </Grid>
            <GrinHighlighter searchWords={[searchTerm]} autoEscape={true} textToHighlight={doctorSM.email} />
          </Grid>
        </Grid>
        <Grid item direction="column">
          <Grid className={classes.accessType}>
            {doctorSM.accessType ? t(`common.practiceMemberTypes.${doctorSM.accessType}`) : t('general.NA')}
          </Grid>
          <Grid>({doctorSM.status ? doctorSM.status : t('general.NA')})</Grid>
        </Grid>
      </Grid>
      <Grid xs={1} className={classes.actionContainer}>
        <Tooltip value={t('dialogs.quickActions.copyId')} placement="top" arrow>
          {doctorSM.id === copiedItemId ? (
            <Check1 color={'green'} />
          ) : (
            <Copy onClick={() => onCopyId({ id: doctorSM.id, itemType: 'doctor' })} />
          )}
        </Tooltip>
      </Grid>
    </Grid>
  )
}

export default DoctorResultItem
