import { prettyError } from '@grin-rnd/grin-api-sdk/dist/utils/ErrorUtils'
import { NOTIFICATION_CENTER_PAGE_SIZE } from 'consts/notificationCenterConsts'
import * as notificationCenterGraphql from 'graphql/notificationCenter.graphql'
import { api, REQUEST_TYPE } from 'store/api'
import { logError } from 'utils/logUtils'
import { patchPaginatedGraphQueryState } from 'utils/rtkUtils'
import { ModuleTags } from './consts'
import { buildGrinNotificationFilter } from 'utils/notificationCenterUtils'

export const NotificationCenter = api.injectEndpoints({
  endpoints: build => ({
    fetchTotalUnreadNotifications: build.query({
      query: () => ({
        type: REQUEST_TYPE.GET,
        path: '/notifications/v1/grinNotifications/aggregation'
      })
    }),
    fetchGrinNotifications: build.infiniteQuery({
      infiniteQueryOptions: {
        initialPageParam: 0,
        getNextPageParam: (lastPage, allPages, lastPageParam) => lastPageParam + 1
      },
      providesTags: [ModuleTags.NotificationCenter],
      query: ({ queryArg, pageParam }) => {
        const { grinUserId, filters } = queryArg
        return {
          type: REQUEST_TYPE.GRAPHQL,
          query: notificationCenterGraphql.searchGrinNotifications(),
          queryName: 'searchGrinNotifications',
          variables: {
            limit: NOTIFICATION_CENTER_PAGE_SIZE,
            from: pageParam * NOTIFICATION_CENTER_PAGE_SIZE,
            sort: {
              field: 'eventDate',
              direction: 'desc'
            },
            filter: buildGrinNotificationFilter({
              grinUserId: grinUserId,
              unreadOnly: filters.unreadOnly,
              notificationTypes: filters.notificationTypes,
              lastMarkAllAsReadDate: filters.lastMarkAllAsReadDate,
              lastClearAllDate: filters.lastClearAllDate
            })
          }
        }
      },
      transformResponse: res => ({
        items: res.searchGrinNotifications.items.map(notification => ({
          ...notification,
          payload: JSON.parse(notification.payload || '{}')
        })),
        hasMore: !!res.searchGrinNotifications.nextToken
      })
    }),
    resolveGrinNotification: build.mutation({
      query: ({ grinNotificationId, status }) => ({
        type: REQUEST_TYPE.GRAPHQL_MUTATION,
        entityType: 'GrinNotification',
        id: grinNotificationId,
        variables: {
          status,
          resolveMethod: 'user-action'
        }
      }),
      onQueryStarted: async ({ grinNotificationId, status }, { dispatch, queryFulfilled, getState }) => {
        const state = getState()
        const fetchGrinNotificationsArgs = {
          filters: state.notificationCenterReducer.filters,
          grinUserId: state.profileReducer.doctor?.user?.id
        }

        const patch = dispatch(
          api.util.updateQueryData('fetchGrinNotifications', fetchGrinNotificationsArgs, notifications => {
            patchPaginatedGraphQueryState({
              pages: notifications.pages,
              predicate: item => item.id === grinNotificationId,
              itemPatch: { status }
            })
          })
        )

        try {
          await queryFulfilled
        } catch (error) {
          patch.undo()
          logError('An error occured while trying to update a GrinNotification status', {
            grinNotificationId,
            status,
            error: prettyError(error)
          })
        }
      }
    }),
    bulkAction: build.mutation({
      query: ({ actionType, filters }) => ({
        type: REQUEST_TYPE.POST,
        path: `/notifications/v1/grinNotifications/bulkAction/${actionType}`,
        data: {
          filters
        }
      }),
      invalidatesTags: [ModuleTags.NotificationCenter]
    })
  })
})

export const {
  useFetchTotalUnreadNotificationsQuery,
  useFetchGrinNotificationsInfiniteQuery,
  useResolveGrinNotificationMutation,
  useBulkActionMutation
} = NotificationCenter

export const { bulkAction } = NotificationCenter.endpoints
