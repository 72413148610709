import React from 'react'
import { makeStyles } from '@material-ui/styles'
import useNotificationsList from './useNotificationsFeed'
import NotificationsGroup from './NotificationsGroup'
import InfiniteScroll from 'react-infinite-scroll-component'
import { CircularProgress } from '@material-ui/core'
import { scrollBar } from 'styles/theme'

const useStyles = makeStyles(theme => ({
  notificationsFeedRoot: {
    height: '100%',
    overflowY: 'scroll',
    ...scrollBar
  },
  loaderContainer: {
    display: 'flex',
    justifyContent: 'center',
    marginBottom: 20
  }
}))

const NotificationsFeed = () => {
  const classes = useStyles()
  const { notificationGroups, totalNotifications, fetchNextPage, hasMorePages } = useNotificationsList()

  return (
    <div id="notifications-feed" className={classes.notificationsFeedRoot}>
      <InfiniteScroll
        dataLength={totalNotifications}
        scrollableTarget="notifications-feed"
        next={fetchNextPage}
        hasMore={hasMorePages}
        loader={
          <div className={classes.loaderContainer}>
            <CircularProgress size={12} style={{ color: 'var(--text-color-25)' }} />
          </div>
        }
      >
        {notificationGroups.map(group => (
          <NotificationsGroup key={`notifications-group-${group.key}`} notificationGroup={group} />
        ))}
      </InfiniteScroll>
    </div>
  )
}

export default NotificationsFeed
