import { Dialog, DialogActions, DialogContent, Grid, IconButton, makeStyles } from '@material-ui/core'
import React, { useRef, useEffect } from 'react'
import { isMobile } from 'utils/mobileUtils'
import PrimaryButton from '../buttons/PrimaryButton'
import SecondaryButton from '../buttons/SecondaryButton'
import CloseIcon from '../icons/Close'
import DazzedHeading24 from '../text/DazzedHeading24'
import DazzedParagraph16 from '../text/DazzedParagraph16'
import OggHeading40 from '../text/OggHeading40'
import LinkButton from '../buttons/LinkButton'

const useStyles = ({ isMobile }) =>
  makeStyles({
    container: {
      borderRadius: 20
    },
    alertContainer: {
      width: 480
    },
    fullScreenContainer: {
      top: isMobile ? 0 : 40,
      minWidth: '100vw',
      height: isMobile ? '100%' : window.innerHeight - 80,
      maxHeight: isMobile ? '100%' : 'inherit',
      borderRadius: 0,
      boxShadow: 'none',
      borderTopWidth: 1,
      borderTop: '1px solid var(--border-color-3)'
    },
    cardContainer: {
      borderRadius: 0,
      borderBottomRightRadius: 80
    },
    fuzzyContainer: {
      background: 'transparent',
      boxShadow: 'none'
    },
    mobileCardContainer: {
      maxHeight: 'calc(100% - 40px)',
      width: 'calc(100% - 40px)',
      margin: 0
    },
    topBarContainer: {
      backgroundColor: '#E1E5FE',
      minHeight: 44
    },
    topBarText: {
      color: 'var(--text-color-16)'
    },
    closeIconContainer: {
      position: 'absolute',
      right: 10,
      top: 10,
      cursor: 'pointer',
      zIndex: 999
    },
    dialogHeader: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      textAlign: 'center',
      padding: '36px 36px 26px 36px',
      '& img': {
        fontSize: 10,
        fontFamily: 'Dazzed',
        maxHeight: 96,
        maxWidth: 96,
        marginBottom: 16
      }
    },
    dialogHeaderLeft: {
      flexDirection: 'row',
      alignItems: 'center',
      padding: '36px 36px 0 36px',
      '& img': {
        minHeight: 60,
        minWidth: 60,
        marginBottom: 0
      }
    },
    dialogHeaderWithTopBarText: {
      padding: '28px 36px 26px 36px'
    },
    dialogHeaderWithTopBarTextSmoothBorders: {
      padding: '28px 36px 0 36px'
    },
    headerAssetContainer: {
      marginBottom: 17
    },
    headerAssetLeftContainer: {
      marginBottom: 0
    },
    headerTagContainer: {
      marginTop: 7,
      marginLeft: 12
    },
    dialogContent: {
      padding: '0 24px 40px 24px'
    },
    contentNoActions: {
      padding: '0 24px 24px 24px'
    },
    mobileFullscreenDialogContent: {
      padding: '0px 10px 24px 10px'
    },
    dialogActions: {
      padding: '0 32px 32px 32px',
      justifyContent: 'center',
      position: 'relative'
    },
    hiddenScroll: {
      overflow: 'hidden'
    },
    smothBorderTop: {
      position: 'relative',
      '&:after': {
        content: "''",
        position: 'absolute',
        left: 0,
        top: 0,
        width: 'calc(100% - 8px);',
        height: 30,
        background: 'linear-gradient(180deg, #FFFFFF 0, rgba(255, 255, 255, 0) 130%)',
        zIndex: 100
      }
    },
    smothBorderBottom: {
      position: 'relative',
      '&:after': {
        content: "''",
        position: 'absolute',
        left: 0,
        bottom: 0,
        width: 'calc(100% - 8px);',
        height: 30,
        background: 'linear-gradient(0deg, #FFFFFF 0, rgba(255, 255, 255, 0) 130%)',
        zIndex: 100
      }
    },
    contentWrapper: {
      height: '100%',
      '&.smoothBorders': {
        marginTop: 25
      }
    },
    reverseActionsOrder: {
      flexDirection: 'row-reverse !important'
    },
    secondaryBtnSpacing: {
      marginLeft: 5
    },
    rightLinkButtonContainer: {
      position: 'absolute',
      right: '40px'
    },
    backDrop: {
      backdropFilter: 'blur(2px)'
    }
  })({ isMobile })

const BaseModal = ({
  open,
  children,
  topBarText,
  title,
  titleImageComponent: headerAssetComponent,
  titleTagComponent: headerTagComponent,
  variant = 'primary',
  primaryLabel,
  primaryType,
  secondaryLabel,
  onPrimaryBtnClick,
  onSecondaryBtnClick,
  isPrimaryDisabled = false,
  primaryBtnIcon,
  isLoading = false,
  placeLoaderOn = 'primary',
  handleClose = () => {},
  className,
  rootClassName = '',
  contentClassName = '',
  actionsClassName = '',
  titleContainerClassName = '',
  titleClassName = '',
  closeIconClassName = '',
  withCloseIcon = true,
  largerButtons = false,
  largerPrimaryButton = false,
  buttonsHeight,
  buttonsWidth,
  primaryButtonWidth,
  hideBackdrop = false,
  hideScroll = false,
  smothBorders = false,
  formId,
  reverseActionsOrder = false,
  onKeyDown = () => {},
  refTrigger,
  closeButtonId = '',
  primaryButtonId = '',
  secondaryButtonId = '',
  butttonFontSize,
  maxWidth = 'sm',
  disableEnforceFocus = false,
  rightLinkButtonProps,
  blurBackground = false
}) => {
  const classes = useStyles({ isMobile: isMobile() })

  const contentRef = useRef(null)

  useEffect(() => {
    contentRef?.current?.scrollTo(0, 0)
  }, [refTrigger])

  return (
    <Dialog
      open={open}
      maxWidth={maxWidth}
      classes={{
        root: rootClassName,
        paper: [
          classes.container,
          variant === 'alert' && classes.alertContainer,
          variant === 'fullscreen' && classes.fullScreenContainer,
          variant === 'card' && classes.cardContainer,
          variant === 'fuzzy' && classes.fuzzyContainer,
          isMobile() && (variant === 'alert' || variant === 'primary') && classes.mobileCardContainer,
          className
        ].join(' ')
      }}
      hideBackdrop={variant === 'fullscreen' || hideBackdrop}
      onClose={handleClose}
      onKeyDown={e => onKeyDown(e)}
      disableEnforceFocus={disableEnforceFocus}
    >
      {!!topBarText && (
        <Grid container alignItems="center" justifyContent="center" className={classes.topBarContainer}>
          <Grid item xs={12}>
            <DazzedParagraph16 className={classes.topBarText} textAlign="center">
              {topBarText}
            </DazzedParagraph16>
          </Grid>
        </Grid>
      )}
      {withCloseIcon && (
        <IconButton
          className={[classes.closeIconContainer, closeIconClassName].join(' ')}
          aria-label="Close"
          onClick={handleClose}
          id={closeButtonId}
        >
          <CloseIcon color="black" />
        </IconButton>
      )}

      {(!!title || !!headerAssetComponent) && (
        <div
          className={[
            classes.dialogHeader,
            variant === 'card' ? classes.dialogHeaderLeft : '',
            !topBarText
              ? ''
              : smothBorders
              ? classes.dialogHeaderWithTopBarTextSmoothBorders
              : classes.dialogHeaderWithTopBarText,
            titleClassName
          ].join(' ')}
        >
          {headerAssetComponent && (
            <div
              className={[
                classes.headerAssetContainer,
                variant === 'card' ? classes.headerAssetLeftContainer : ''
              ].join(' ')}
            >
              {headerAssetComponent}
            </div>
          )}
          {variant === 'alert' || (isMobile() && variant === 'fullscreen') ? (
            <DazzedHeading24 className={titleClassName}>{title}</DazzedHeading24>
          ) : (
            <OggHeading40 className={titleClassName}>{title}</OggHeading40>
          )}
          {headerTagComponent && <div className={classes.headerTagContainer}>{headerTagComponent}</div>}
        </div>
      )}

      {smothBorders && <div className={classes.smothBorderTop}></div>}
      <DialogContent
        className={[
          classes.dialogContent,
          !secondaryLabel && !primaryLabel ? classes.contentNoActions : '',
          hideScroll ? classes.hiddenScroll : '',
          isMobile() && classes.mobileFullscreenDialogContent,
          contentClassName
        ].join(' ')}
        ref={contentRef}
      >
        <div className={[classes.contentWrapper, smothBorders ? 'smoothBorders' : ''].join(' ')}>{children}</div>
      </DialogContent>
      {smothBorders && <div className={classes.smothBorderBottom}></div>}

      {(secondaryLabel || primaryLabel) && (
        <DialogActions
          disableSpacing={reverseActionsOrder}
          className={[
            classes.dialogActions,
            reverseActionsOrder ? classes.reverseActionsOrder : '',
            actionsClassName
          ].join(' ')}
        >
          {secondaryLabel && (
            <div className={reverseActionsOrder ? classes.secondaryBtnSpacing : ''}>
              <SecondaryButton
                small={!largerButtons}
                label={secondaryLabel}
                onClick={onSecondaryBtnClick}
                isLoading={isLoading && placeLoaderOn === 'secondary'}
                height={buttonsHeight}
                width={buttonsWidth}
                id={secondaryButtonId}
              />
            </div>
          )}
          {primaryLabel && (
            <PrimaryButton
              small={!(largerButtons || largerPrimaryButton)}
              label={primaryLabel}
              onClick={onPrimaryBtnClick}
              isLoading={isLoading && placeLoaderOn === 'primary'}
              disabled={isPrimaryDisabled}
              type={primaryType}
              formId={formId}
              fontSize={butttonFontSize}
              height={buttonsHeight}
              width={buttonsWidth || primaryButtonWidth}
              id={primaryButtonId}
              icon={primaryBtnIcon}
            />
          )}
          {rightLinkButtonProps && (
            <div className={classes.rightLinkButtonContainer}>
              <LinkButton {...rightLinkButtonProps} />
            </div>
          )}
        </DialogActions>
      )}
    </Dialog>
  )
}

export default React.memo(BaseModal)
