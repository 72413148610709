import { Grid } from '@material-ui/core'
import Actions from 'actions'
import ConfirmationModal from 'components/common/ConfirmationModal'
import CustomDatePicker from 'components/common/CustomDatePicker'
import FormInput from 'components/common/FormInput'
import GrayActionBox from 'components/common/GrayActionBox'
import GrinSwitch from 'components/common/GrinSwitch'
import SelectInput from 'components/common/SelectInput'
import TextAreaAutosize from 'components/common/TextareaAutosize'
import PrimaryButton from 'components/common/buttons/PrimaryButton'
import DazzedParagraph16 from 'components/common/text/DazzedParagraph16'
import GrinLabel from 'components/common/text/GrinLabel'
import { Roles } from 'consts/authConsts'
import { HI_GRUOP_IDS } from 'consts/billingConsts'
import useRolePermissions from 'hooks/useRolePermissions'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { assemblePlanDisplayFullName, isHIPlan, isOnChurnedPlan, isOnFreePlan } from 'utils/billingUtils'
import { useStyles } from './EditDialogStyle'

const SubscriptionTab = () => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const { permissions, isUserOfRole } = useRolePermissions()

  const accountOwner = useSelector(state =>
    state.supportReducer.doctorsDashboard.selectedDoctor.accountOwnerId
      ? state.supportReducer.doctorsDashboard.selectedDoctor.accountOwner
      : state.supportReducer.doctorsDashboard.selectedDoctor
  )
  const grinPlans = useSelector(state => state.billingReducer.grinPlans)
  const subscription = useSelector(state => state.supportReducer.subscriptions.data[accountOwner.id])
  const activeUsers = useSelector(state => state.supportReducer.doctorsDashboard.seats.data?.occupied ?? '-')

  const [updatedAccountOwnerUser, setUpdatedAccountOwnerUser] = useState({ ...accountOwner.user })
  const [updatedPlanOverrides, setUpdatedPlanOverrides] = useState(
    accountOwner.user?.planOverrides ? JSON.parse(accountOwner.user?.planOverrides) : {}
  )
  const [updatedAppSettings, setUpdatedAppSettings] = useState(
    accountOwner.user.appSettings ? JSON.parse(accountOwner.user.appSettings) : {}
  )
  const [isChurnedWarningModalOpen, setIsChurnedWarningModalOpen] = useState(false)

  const grinPlan = useMemo(() => grinPlans[updatedAccountOwnerUser.grinPlanKey], [grinPlans, updatedAccountOwnerUser])
  const planOverrides = useMemo(
    () =>
      updatedPlanOverrides[updatedAccountOwnerUser.grinPlanKey] || {
        maxPatients: grinPlan.maxPatients,
        maxScopes: grinPlan.maxScopes
      },
    [grinPlan, updatedAccountOwnerUser, updatedPlanOverrides]
  )

  const freeUsers = useMemo(
    () => (planOverrides.maxPatients - grinPlan.maxPatients > 0 ? planOverrides.maxPatients - grinPlan.maxPatients : 0),
    [grinPlan.maxPatients, planOverrides.maxPatients]
  )

  const HIGroupOptions = useMemo(
    () =>
      HI_GRUOP_IDS.map(groupId => ({
        value: groupId,
        label: t(`grinPlans.HI-groups.${groupId}`)
      })),
    [t]
  )

  const isFreePlan = useMemo(() => isOnFreePlan(grinPlan), [grinPlan])
  const isChurned = useMemo(() => isOnChurnedPlan(grinPlan), [grinPlan])
  const isOnHI = useMemo(() => isHIPlan(updatedAccountOwnerUser?.grinPlanKey), [updatedAccountOwnerUser?.grinPlanKey])

  const isSaveDisabled = useMemo(() => {
    if (!updatedAccountOwnerUser?.grinPlanKey) {
      return true
    }

    if (isOnHI && (!updatedPlanOverrides.general?.hiGroup || !updatedPlanOverrides.general?.primaryHiGroup)) {
      return true
    }

    return false
  }, [updatedAccountOwnerUser, updatedPlanOverrides, isOnHI])

  useEffect(() => {
    if (!isFreePlan && !subscription) {
      dispatch(Actions.fetchDoctorSubscription({ username: accountOwner.username }))
    }
  }, [accountOwner, dispatch, isFreePlan, subscription])

  const handleChangeSelectedPlan = useCallback(
    ({ planKey }) => {
      setUpdatedAccountOwnerUser({
        ...updatedAccountOwnerUser,
        grinPlanKey: planKey
      })

      let planOverridesToSet = {
        ...updatedPlanOverrides
      }
      if (!updatedPlanOverrides[planKey]) {
        const { maxPatients, maxScopes } = grinPlans[planKey]
        planOverridesToSet = {
          ...planOverridesToSet,
          [planKey]: {
            maxPatients,
            maxScopes
          }
        }
      }

      if (!isHIPlan(planKey)) {
        planOverridesToSet = {
          ...planOverridesToSet,
          general: {
            ...(updatedPlanOverrides.general || {}),
            hiGroup: null,
            primaryHiGroup: null
          }
        }
      }

      if (isOnChurnedPlan(grinPlans[planKey])) {
        setIsChurnedWarningModalOpen(true)
      }

      setUpdatedPlanOverrides(planOverridesToSet)
    },
    [updatedPlanOverrides, updatedAccountOwnerUser, grinPlans]
  )

  const handleSave = useCallback(() => {
    dispatch(
      Actions.supportUpdateUser({
        id: accountOwner.user.id,
        _version: accountOwner.user._version,
        grinPlanKey: updatedAccountOwnerUser.grinPlanKey,
        stripeCustomerId: updatedAccountOwnerUser.stripeCustomerId,
        planOverrides: JSON.stringify(updatedPlanOverrides),
        appSettings: JSON.stringify(updatedAppSettings)
      })
    )
  }, [accountOwner, updatedAccountOwnerUser, updatedPlanOverrides, updatedAppSettings, dispatch])

  useEffect(() => {
    if (!Object.keys(grinPlans).length) {
      dispatch(Actions.fetchGrinPlans())
    }
  }, [dispatch, grinPlans])

  return (
    <Grid
      container
      direction="column"
      justifyContent="space-between"
      alignItems="center"
      className={classes.tabWithButtonContainer}
    >
      <Grid item className={classes.tabWithButtonContent}>
        <Grid container spacing={2}>
          <Grid item xs={4}>
            <SelectInput
              label={t('dialogs.doctorCardEditor.planLabel')}
              style={{ bright: true, thick: true }}
              disabled={!permissions.opsDashboardEditDoctor}
              value={updatedAccountOwnerUser.grinPlanKey}
              options={Object.values(grinPlans)
                .sort((a, b) => a.planGroup.localeCompare(b.planGroup))
                .map(grinPlan => ({
                  value: grinPlan.key,
                  label: assemblePlanDisplayFullName(grinPlan)
                }))}
              keepErrorContainerWhenInactive={false}
              onChange={({ value }) => handleChangeSelectedPlan({ planKey: value })}
            />
          </Grid>
          <Grid item xs={3}>
            <SelectInput
              label={`${t('dialogs.doctorCardEditor.assignedHiGroupLabel')}`}
              style={{ bright: true, thick: true }}
              disabled={!permissions.opsDashboardEditDoctor || !isOnHI}
              value={updatedPlanOverrides.general?.hiGroup || ''}
              options={HIGroupOptions}
              keepErrorContainerWhenInactive={false}
              onChange={({ value }) =>
                setUpdatedPlanOverrides({
                  ...updatedPlanOverrides,
                  general: {
                    ...(updatedPlanOverrides.general || {}),
                    hiGroup: value
                  }
                })
              }
            />
          </Grid>
          <Grid item xs={3}>
            <SelectInput
              label={`${t('dialogs.doctorCardEditor.primaryHiGroupLabel')}`}
              style={{ bright: true, thick: true }}
              disabled={!permissions.opsDashboardEditDoctor || !isOnHI}
              value={updatedPlanOverrides.general?.primaryHiGroup || ''}
              options={HIGroupOptions}
              keepErrorContainerWhenInactive={false}
              onChange={({ value }) =>
                setUpdatedPlanOverrides({
                  ...updatedPlanOverrides,
                  general: {
                    ...(updatedPlanOverrides.general || {}),
                    primaryHiGroup: value
                  }
                })
              }
            />
          </Grid>
          <Grid item xs={2}>
            <FormInput
              title={t('dialogs.doctorCardEditor.stripeUserIdLabel')}
              isDisabled={!permissions.opsDashboardEditDoctor}
              style={{ bright: true, thick: true }}
              value={updatedAccountOwnerUser.stripeCustomerId}
              keepErrorContainerWhenInactive={false}
              setValue={value => {
                setUpdatedAccountOwnerUser({
                  ...updatedAccountOwnerUser,
                  stripeCustomerId: value
                })
              }}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <GrayActionBox label={t('dialogs.doctorCardEditor.freeUsersLabel')} height={42}>
              <DazzedParagraph16 className={classes.infoText}>{freeUsers}</DazzedParagraph16>
            </GrayActionBox>
          </Grid>
          {!isFreePlan && subscription && (
            <>
              <Grid item xs={12} sm={6}>
                <CustomDatePicker
                  label={t('dialogs.doctorCardEditor.subscriptionCycleStartDateLabel')}
                  value={subscription.currentPeriodStart}
                  disablePast={false}
                  disabled
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <CustomDatePicker
                  label={t('dialogs.doctorCardEditor.subscriptionCycleEndDateLabel')}
                  value={subscription.currentPeriodEnd}
                  disablePast={false}
                  disabled
                />
              </Grid>
            </>
          )}
          <Grid item xs={12} sm={3}>
            <FormInput
              title={t('dialogs.doctorCardEditor.totalUsersLabel')}
              style={{ bright: true, thick: true }}
              value={planOverrides.maxPatients}
              keepErrorContainerWhenInactive={false}
              isDisabled={!permissions.opsDashboardEditDoctor || isChurned}
              setValue={value => {
                let newValue = !isNaN(value) ? +value : ''
                setUpdatedPlanOverrides({
                  ...updatedPlanOverrides,
                  [updatedAccountOwnerUser.grinPlanKey]: {
                    ...updatedPlanOverrides[updatedAccountOwnerUser.grinPlanKey],
                    maxPatients: newValue
                  }
                })
              }}
            />
          </Grid>
          <Grid item xs={12} sm={3}>
            <GrayActionBox label={t('dialogs.doctorCardEditor.activeUsersLabel')} height={42}>
              <DazzedParagraph16 className={classes.infoText}>{activeUsers}</DazzedParagraph16>
            </GrayActionBox>
          </Grid>
          {!isUserOfRole(Roles.Distributor) && (
            <Grid item xs={12} sm={4}>
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                  paddingTop: 20,
                  borderBottom: '1px solid var(--border-color-7)'
                }}
              >
                <GrinLabel>{t('dialogs.doctorCardEditor.orderScopesManually')}</GrinLabel>
                <GrinSwitch
                  checked={planOverrides.orderScopesManually}
                  disabled={!permissions.opsDashboardEditDoctor || isChurned}
                  onChange={({ target }) => {
                    if (!permissions.opsDashboardBilling) {
                      return
                    }

                    setUpdatedPlanOverrides({
                      ...updatedPlanOverrides,
                      [updatedAccountOwnerUser.grinPlanKey]: {
                        ...updatedPlanOverrides[updatedAccountOwnerUser.grinPlanKey],
                        orderScopesManually: target.checked
                      }
                    })
                  }}
                />
              </div>
            </Grid>
          )}
          {!isUserOfRole(Roles.Distributor) && (
            <Grid item xs={12} sm={4}>
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                  paddingTop: 20,
                  borderBottom: '1px solid var(--border-color-7)'
                }}
              >
                <GrinLabel>{t('dialogs.doctorCardEditor.orderScopesViewWeb')}</GrinLabel>
                <GrinSwitch
                  checked={planOverrides.maxScopes > 0}
                  disabled={!permissions.opsDashboardEditDoctor || isChurned}
                  onChange={({ target }) =>
                    setUpdatedPlanOverrides({
                      ...updatedPlanOverrides,
                      [updatedAccountOwnerUser.grinPlanKey]: {
                        ...updatedPlanOverrides[updatedAccountOwnerUser.grinPlanKey],
                        maxScopes: target.checked ? 300 : 0
                      }
                    })
                  }
                />
              </div>
            </Grid>
          )}
          {!isUserOfRole(Roles.Distributor) && (
            <Grid item xs={12} sm={4}>
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                  paddingTop: 20,
                  borderBottom: '1px solid var(--border-color-7)'
                }}
              >
                <GrinLabel>{t('dialogs.doctorCardEditor.freeScopes')}</GrinLabel>
                <GrinSwitch
                  checked={planOverrides.freeScopes}
                  disabled={!grinPlan.planGroup.includes('grin-subscription')}
                  onChange={({ target }) => {
                    if (!permissions.opsDashboardBilling) {
                      return
                    }

                    setUpdatedPlanOverrides({
                      ...updatedPlanOverrides,
                      [updatedAccountOwnerUser.grinPlanKey]: {
                        ...updatedPlanOverrides[updatedAccountOwnerUser.grinPlanKey],
                        freeScopes: target.checked
                      }
                    })
                  }}
                />
              </div>
            </Grid>
          )}
          <Grid item xs>
            <TextAreaAutosize
              title={t('dialogs.doctorCardEditor.billingNote')}
              rowsMax={4}
              style={{ bright: true }}
              keepErrorContainerWhenInactive={false}
              value={updatedAppSettings.billingSubscriptionNote || ''}
              setValue={value => setUpdatedAppSettings({ ...updatedAppSettings, billingSubscriptionNote: value })}
              className={classes.billingNote}
              rowsMin={3}
              isDisabled={!permissions.opsDashboardEditDoctor}
            />
          </Grid>
        </Grid>
      </Grid>
      {!!permissions.opsDashboardEditDoctor && (
        <Grid item>
          <PrimaryButton label={t('general.save')} onClick={handleSave} disabled={isSaveDisabled} />
        </Grid>
      )}
      <ConfirmationModal
        isOpen={isChurnedWarningModalOpen}
        title={t('dialogs.doctorCardEditor.churnedPlanWarningModal.title')}
        onClose={() => setIsChurnedWarningModalOpen(false)}
        onConfirm={() => setIsChurnedWarningModalOpen(false)}
        withSecondaryButton={false}
        text={<Trans i18nKey={'dialogs.doctorCardEditor.churnedPlanWarningModal.body'} />}
      />
    </Grid>
  )
}

export default SubscriptionTab
