import React, { useCallback, useMemo, useState } from 'react'
import { Grid, makeStyles } from '@material-ui/core'
import MessageTypeWrapper from '../MessageTypeWrapper'
import { getObjectUrl } from 'utils/mediaUtils'
import { Document, Page } from 'react-pdf'
import DazzedParagraph12 from 'components/common/text/DazzedParagraph12'
import PDFViewer from 'components/common/PDFViewer'
import { PDFMessage } from 'components/common/icons'
import DazzedParagraph14 from 'components/common/text/DazzedParagraph14'
import { useTranslation } from 'react-i18next'
import { logError } from 'utils/logUtils'

const useStyles = makeStyles(() => ({
  message: {
    backgroundColor: '#FFFFFF'
  },
  messageUserInfo: {
    marginLeft: 5
  },
  timestamp: {
    position: 'absolute',
    bottom: 0,
    left: 0,
    right: 0,
    height: 30,
    padding: '12px 18px 0 18px',
    borderRadius: '0 0 8px 8px',
    color: 'var(--text-color-52)',
    fontSize: 10,
    textAlign: 'right'
  },
  pdf: {
    cursor: 'pointer'
  },
  pages: {
    color: 'var(--text-color-11)'
  },
  padNumOfPages: {
    paddingBottom: 10
  }
}))

const PdfMessage = ({
  id,
  content,
  isLocal,
  cache,
  alignLeft,
  name,
  userType,
  isFirstMessage,
  isLastMessage,
  timestamp,
  isBroadcast,
  fileTitle,
  withMessageActions
}) => {
  const classes = useStyles()
  const { t } = useTranslation()

  const [showFile, setShowFile] = useState(false)
  const [numOfPages, setNumOfPages] = useState(null)

  const data = useMemo(() => (isLocal ? cache : getObjectUrl(content)), [cache, content, isLocal])

  const handlePdfLoad = useCallback(({ numPages }) => setNumOfPages(numPages), [])

  return (
    <MessageTypeWrapper
      id={id}
      isFirstMessage={isFirstMessage}
      isLastMessage={isLastMessage}
      alignLeft={alignLeft}
      name={name}
      userType={userType}
      infoClassName={classes.messageUserInfo}
      isBroadcast={isBroadcast}
      withMessageActions={withMessageActions}
    >
      <div onClick={() => setShowFile(true)}>
        <Document
          file={data}
          className={classes.pdf}
          onLoadSuccess={handlePdfLoad}
          onLoadError={error => logError('An error occured while loading pdf file', { error, source: 'chat' })}
        >
          <Page pageNumber={1} width={250} height={350} />
        </Document>
        <div className={classes.timestamp}>{timestamp}</div>
      </div>
      <PDFViewer file={data} isOpen={showFile} onClose={setShowFile} />
      <Grid container spacing={1} alignItems="center">
        <Grid item>
          <PDFMessage />
        </Grid>
        <Grid item>
          <Grid container direction="column">
            <Grid item className={!numOfPages ? classes.padNumOfPages : ''}>
              <DazzedParagraph14>
                {fileTitle ?? t('pages.patients.selectedPatient.chat.pdfMessage.title')}
              </DazzedParagraph14>
            </Grid>
            {numOfPages && (
              <Grid item>
                <DazzedParagraph12 className={classes.pages}>
                  {t('pages.patients.selectedPatient.chat.pdfMessage.pages', { number: numOfPages })}
                </DazzedParagraph12>
              </Grid>
            )}
          </Grid>
        </Grid>
      </Grid>
    </MessageTypeWrapper>
  )
}

export default React.memo(PdfMessage)
