import React, { useEffect, useRef } from 'react'
import { makeStyles } from '@material-ui/styles'
import NoteItem from './NoteItem/NoteItem'

const useStyles = makeStyles(theme => ({
  notesListRoot: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    height: '100%',
    padding: 10,
    overflowY: 'auto'
  }
}))

const NotesList = ({ notes }) => {
  const classes = useStyles()
  const notesListRef = useRef(null)

  useEffect(() => {
    if (notesListRef?.current?.scrollIntoView) {
      notesListRef.current.scrollIntoView({
        behavior: 'instant'
      })
    }
  }, [notes.length])

  return (
    <div className={classes.notesListRoot}>
      {notes.map(({ id, body, updatedAt, isPinned, metadata, grinUserId, creatorUserId, doctorUserId }, index) => (
        <NoteItem
          id={id}
          patientGrinUserId={grinUserId}
          ref={index === notes.length - 1 ? notesListRef : null}
          key={id}
          message={body}
          updatedAt={updatedAt}
          isPinned={isPinned}
          metadata={metadata}
          isEditable={creatorUserId === doctorUserId}
        />
      ))}
    </div>
  )
}

export default NotesList
