import React, { useCallback, useState, useEffect, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { makeStyles, CircularProgress } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import useNavigation from '../../../hooks/useNavigation'
import usePms from '../../../hooks/usePms'
import BaseModal from 'components/common/modals/BaseModal'
import GrinTabs from 'components/common/tabs/GrinTabs'
import GrinTab from 'components/common/tabs/GrinTab'
import PatientCardHeader from './PatientCardHeader/PatientCardHeader'
import PrelimPlanTab from './PrelimPlanTab'
import TreatmentPlanTab from './TreatmentPlanTab'
import TimelineTab from './TimelineTab/TimelineTab'
import ScanSettingsTab from './ScanSettingsTab'
import MedicalHistoryTab from './MedicalHistoryTab/MedicalHistoryTab'
import NotesTab from './NotesTab'
import RecordsTab from './RecordsTab/RecordsTab'
import PmsAppointmentsTab from './AppointmentsTab/PmsAppointmentsTab'
import Actions from 'actions'
import { PATIENT_CARD_TABS } from 'consts/patientCard'
import { trackEvent } from 'utils/analyticsUtils'
import FamilyInfoTab from './FamilyInfoTab/FamilyInfoTab'
import useGaFlags from 'hooks/useGaFlags'
import usePmsAutoLinking from 'hooks/usePmsAutoLinking'
import { isMobile } from 'utils/mobileUtils'
import GiTrackSettingsTab from './GiTrackSettingsTab/GiTrackSettingsTab'
import useRolePermissions from 'hooks/useRolePermissions'
import usePlanLimits from 'hooks/usePlanLimits'
import PatientBriefTab from './PatientBriefTab/PatientBriefTab'
import useHiTools from 'hooks/useHiTools'
import TxTrackerSetup from './TxTrackerSetup/TxTrackerSetup'
import usePracticeConfiguration from 'hooks/usePracticeConfiguration'
import useFeatureFlags from 'hooks/useFeatureFlags'

const TAB_QUERY_PARAM = 'patientCardTab'

const useStyles = makeStyles({
  modal: {
    padding: 0,
    width: '80%',
    maxWidth: 1160
  },
  modalContent: {
    padding: '0px !important',
    '&:first-child': {
      paddingTop: 0
    },
    overflowY: 'auto !important'
  },
  content: {
    position: 'relative',
    minHeight: 200,
    minWidth: 430
  },
  tabsContainer: {
    borderBottom: '1px solid var(--border-color-7)'
  },
  loaderContainer: {
    minHeight: 450,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  }
})

const PatientCard = () => {
  const classes = useStyles()
  const { t } = useTranslation()
  const navigation = useNavigation()
  const dispatch = useDispatch()

  const { doctorPms, authenticatePms } = usePms()
  const gaFlags = useGaFlags()
  const { ownerAppFeatureFlags } = usePracticeConfiguration()
  const practiceFFs = useSelector(state => state.multiPracticeReducer.practiceFFs)
  const { permissions, isHiUser } = useRolePermissions()
  const { grinDesignRefresh: grinDesignRefreshFF } = useFeatureFlags()
  const { hiToolsAvailable } = useHiTools()

  const { isModalOpen, patientId, isLoading, patient, selectedTab } = useSelector(
    state => state.patientsReducer.patientCard
  )

  usePmsAutoLinking(patient)

  const notes = useSelector(state => state.patientsReducer.patientCard.notes.data)
  const program = useSelector(state => state.patientsReducer.patientCard.patient?.user?.program)
  const { isGI } = usePlanLimits()

  const [activeTab, setActiveTab] = useState(selectedTab)

  const shouldSeeAppointmentTab = useMemo(
    () => !!doctorPms && patient?.auth_status === 'confirmed',
    [doctorPms, patient]
  )

  const tabs = useMemo(() => {
    return [
      {
        index: PATIENT_CARD_TABS.timeline.index,
        label: t('dialogs.patientInfo.timelineLabel'),
        Component: TimelineTab,
        condition: PATIENT_CARD_TABS.timeline.programs.includes(program)
      },
      {
        index: PATIENT_CARD_TABS.appointments.index,
        label: t('dialogs.patientInfo.appointmentsLabel'),
        Component: PmsAppointmentsTab,
        condition: PATIENT_CARD_TABS.appointments.programs.includes(program) || shouldSeeAppointmentTab
      },
      {
        index: PATIENT_CARD_TABS.prelimPlan.index,
        label: t('dialogs.patientInfo.prelimPlanLabel'),
        Component: PrelimPlanTab,
        condition: PATIENT_CARD_TABS.prelimPlan.programs.includes(program)
      },
      {
        index: PATIENT_CARD_TABS.treatmentPlan.index,
        label: t('dialogs.patientInfo.treatmentPlanLabel'),
        Component: TreatmentPlanTab,
        condition: PATIENT_CARD_TABS.treatmentPlan.programs.includes(program)
      },
      {
        index: PATIENT_CARD_TABS.scanSettings.index,
        label: t('dialogs.patientInfo.scanSettingsLabel'),
        Component: ScanSettingsTab,
        condition: PATIENT_CARD_TABS.scanSettings.programs.includes(program)
      },
      {
        index: PATIENT_CARD_TABS.medicalHistory.index,
        label: t('dialogs.patientInfo.medicalHistoryLabel'),
        Component: MedicalHistoryTab,
        condition: PATIENT_CARD_TABS.medicalHistory.programs.includes(program) || gaFlags?.medicalHistory
      },
      {
        index: PATIENT_CARD_TABS.notes.index,
        label: t('dialogs.patientInfo.notesLabel', {
          notes: notes?.length ? `(${notes.length})` : null
        }),
        Component: NotesTab,
        condition: PATIENT_CARD_TABS.notes.programs.includes(program) && !grinDesignRefreshFF
      },
      {
        index: PATIENT_CARD_TABS.records.index,
        label: t('dialogs.patientInfo.recordsLabel'),
        Component: RecordsTab,
        condition: true
      },
      {
        index: PATIENT_CARD_TABS.txTrackerSetup.index,
        label: t('dialogs.patientInfo.txTrackerSetupLabel'),
        Component: TxTrackerSetup,
        condition: ownerAppFeatureFlags.treatmentTracker || practiceFFs.treatmentTracker
      },
      {
        index: PATIENT_CARD_TABS.brief.index,
        label: t('dialogs.patientInfo.patientBriefLabel'),
        Component: PatientBriefTab,
        condition: hiToolsAvailable || isHiUser
      },
      {
        index: PATIENT_CARD_TABS.legalGuardian.index,
        label: t('dialogs.patientInfo.familyInfoLabel'),
        Component: FamilyInfoTab,
        condition: true
      },
      {
        index: PATIENT_CARD_TABS.giTrackSettings.index,
        label: t('dialogs.patientInfo.giTrackSettingsLabel'),
        Component: GiTrackSettingsTab,
        condition: isGI && (permissions?.viewGiTrackingSettings || permissions?.editGiTrackingSettings)
      }
    ]
      .sort((a, b) => (a.index > b.index ? 1 : -1))
      .filter(tab => tab.condition)
  }, [
    t,
    program,
    grinDesignRefreshFF,
    shouldSeeAppointmentTab,
    gaFlags,
    notes,
    hiToolsAvailable,
    isHiUser,
    isGI,
    permissions,
    practiceFFs,
    ownerAppFeatureFlags
  ])

  const fallbackTabIndex = useMemo(() => tabs[0]?.index, [tabs])

  useEffect(() => {
    if (isModalOpen && patientId !== patient?.id) {
      dispatch(Actions.requestPatient({ patientId }))
    }
  }, [dispatch, isModalOpen, patient, patientId])

  useEffect(() => {
    if (isModalOpen && !notes && patient?.user?.id) {
      dispatch(Actions.requestPatientNotes(patient.user.id))
    }
  }, [dispatch, notes, isModalOpen, patient?.user?.id])

  useEffect(() => {
    const tabQueryParamValue = navigation.getQueryParam(TAB_QUERY_PARAM)

    if (tabQueryParamValue && PATIENT_CARD_TABS[tabQueryParamValue] && patient?.id && !isModalOpen) {
      navigation.removeQueryParam(TAB_QUERY_PARAM)
      !isMobile() &&
        dispatch(
          Actions.togglePatientCard({
            isModalOpen: true,
            patientId: patient.id,
            selectedTab: PATIENT_CARD_TABS[tabQueryParamValue].index
          })
        )
    }
  }, [selectedTab, isModalOpen, patient, activeTab, dispatch, navigation])

  useEffect(() => {
    if (isModalOpen) {
      setActiveTab(selectedTab || fallbackTabIndex)
    }
  }, [isModalOpen, selectedTab, fallbackTabIndex])

  useEffect(() => {
    if (!!doctorPms && isModalOpen) {
      authenticatePms()
    }
  }, [authenticatePms, doctorPms, isModalOpen])

  const handleClose = useCallback(() => {
    dispatch(Actions.togglePatientCard({ isModalOpen: false, selectedTab: 0 }))
  }, [dispatch])

  const onUpdatePatientInfo = useCallback(
    patient => {
      dispatch(Actions.requestPatientDetailsUpdate(patient))
    },
    [dispatch]
  )

  const handleTabSelection = useCallback(
    (event, value) => {
      const selectedTab = tabs.find(tab => tab.index === value)
      trackEvent('Patient card - tab selected', {
        tab: selectedTab.label
      })
      setActiveTab(value)
    },
    [tabs]
  )

  const handleAddGuardianClick = useCallback(() => {
    trackEvent('Patient card - pressed add legal guardian')
    setActiveTab(PATIENT_CARD_TABS.legalGuardian.index)
  }, [])

  if (!patient) {
    return null
  }

  return (
    <BaseModal
      open={isModalOpen}
      handleClose={handleClose}
      withCloseIcon={false}
      variant="card"
      hideScroll
      className={classes.modal}
      contentClassName={classes.modalContent}
      disableEnforceFocus
    >
      {isModalOpen &&
        (isLoading ? (
          <div className={classes.loaderContainer}>
            <CircularProgress color="primary" />
          </div>
        ) : (
          <>
            <PatientCardHeader
              handleAddGuardianClick={handleAddGuardianClick}
              handleClose={handleClose}
              patient={patient}
              onUpdatePatientInfo={onUpdatePatientInfo}
            />
            <div className={classes.tabsContainer}>
              <GrinTabs value={activeTab} variant="fullWidth" centered onChange={handleTabSelection}>
                {tabs.map(tab => (
                  <GrinTab key={tab.index} label={tab.label} marginRight="0px" value={tab.index} />
                ))}
              </GrinTabs>
            </div>
            <div className={classes.content}>
              {tabs
                .filter(tab => tab.index === activeTab)
                .map(Tab => (
                  <Tab.Component
                    setActiveTab={setActiveTab}
                    firstTabIndex={tabs[0].index}
                    onUpdatePatientInfo={onUpdatePatientInfo}
                    key={Tab.index}
                  />
                ))}
            </div>
          </>
        ))}
    </BaseModal>
  )
}

export default PatientCard
