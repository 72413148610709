import React, { useCallback, useMemo } from 'react'
import { makeStyles, Grid } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import { calculateAge } from '../../../../utils/patientUtils'
import { CloseLarge, Mail, Phone, Location, LoginEmail } from '../../../common/icons'
import EditableMultiLabel from './EditableFirstLastNameLabel'
import EditablePhoneLabel from '../../../common/text/EditablePhoneLabel'
import usePatientTags from '../../../../hooks/usePatientTags'
import TagsList from '../../../common/tags/TagsList'
import FieldWithIcon from './FieldWithIcon'
import { useDispatch } from 'react-redux'
import Actions from '../../../../actions'
import PatientAvatar from '../../PatientAvatar'
import LegalGuardianField from './LegalGuardianField'
import useRolePermissions from 'hooks/useRolePermissions'
import GrinLabel from 'components/common/text/GrinLabel'
import PatientStatusUpdate from 'components/Patients/PatientStatusUpdate'
import Assignee from 'components/Patients/PatientsHeader/Assignee'
import PatientAge from './PatientAge'
import PatientGender from './PatientGender'
import { trackEvent } from 'utils/analyticsUtils'

const useStyles = makeStyles({
  container: {
    position: 'relative',
    width: '100%',
    display: 'flex',
    padding: '35px 55px 10px 40px',
    backgroundColor: 'var(--bg-color-19)',
    overflow: 'hidden'
  },
  closeBtnContainer: {
    width: '27.5px',
    height: '27.5px',
    position: 'absolute',
    top: 20,
    right: 20
  },
  closeIcon: {
    cursor: 'pointer'
  },
  date: {
    fontWeight: '500',
    whiteSpace: 'nowrap'
  },
  nameContainer: {
    whiteSpace: 'nowrap'
  },
  name: {
    padding: '0 5px',
    lineHeight: '40px',
    fontFamily: 'Ogg',
    fontSize: '40px'
  },
  birthDate: {
    lineHeight: '40px',
    fontFamily: 'Ogg',
    fontSize: '40px'
  },
  pickerContainer: {
    padding: '0 10px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between'
  },
  statusText: {
    color: 'var(--text-color-6)!important'
  },
  email: {
    paddingLeft: 2
  },
  genderIcon: {
    marginRight: 5,
    marginTop: 4
  },
  unavailable: {
    color: 'var(--text-color-12)'
  }
})

const PatientCardHeader = ({ patient, handleClose, onUpdatePatientInfo, handleAddGuardianClick }) => {
  const classes = useStyles()
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const { permissions } = useRolePermissions()
  const { tags: patientTags, assigneeTag } = usePatientTags({
    patient
  })

  const handleUpdatePatientInfo = useCallback(
    values => {
      onUpdatePatientInfo({
        id: patient.id,
        isLead: patient.isLead,
        _version: patient._version,
        user: {
          program: patient.user.program
        },
        details: {
          ...patient.details,
          ...values
        },
        programData: patient.programData
      })
    },
    [onUpdatePatientInfo, patient]
  )

  const onGenderUpdated = useCallback(
    newGender => {
      trackEvent('Patient card - Gender updated', {
        newGender
      })
      dispatch(
        Actions.updatePatientGender({
          id: patient.id,
          _version: patient._version,
          gender: newGender
        })
      )
    },
    [dispatch, patient._version, patient.id]
  )

  const { age, under18 } = useMemo(() => calculateAge({ birthdate: patient.details?.birthdate }), [patient.details])

  const programData = useMemo(() => JSON.parse(patient?.programData || '{}'), [patient])
  const addressValue = useMemo(() => {
    let addressArray = patient.isLead
      ? [programData.address, programData.address2, programData.city, programData.state, programData.zipcode]
      : [
          patient.details.address,
          patient.details.address2,
          patient.details.city,
          patient.details.state,
          patient.details.zipcode
        ]

    addressArray = addressArray.filter(part => !!part)

    if (addressArray.length) {
      return addressArray.join(', ')
    } else {
      return t('dialogs.patientInfo.na')
    }
  }, [patient, programData, t])

  const ageValue = useMemo(() => age || patient.details.ageRange, [age, patient.details.ageRange])
  const communicationEmail = useMemo(() => patient.user.messagingPreferences?.contacts[0]?.email, [patient])
  const isRecordsOnly = useMemo(() => patient?.auth_status === 'added', [patient?.auth_status])

  return (
    <div className={classes.container}>
      <div className={classes.closeBtnContainer}>
        <CloseLarge onClick={handleClose} className={classes.closeIcon} />
      </div>
      <Grid container>
        <Grid item xs={1}>
          <PatientAvatar name={patient.details.name} photo={patient.photo} />
        </Grid>
        <Grid item xs={11}>
          <Grid container direction="column" spacing={2} wrap="nowrap">
            <Grid item>
              <Grid container justifyContent="space-between" alignItems="center">
                <Grid item xs={6}>
                  <Grid container direction="column" wrap="nowrap">
                    <Grid item>
                      <Grid container alignItems="center" spacing={1}>
                        <Grid item>
                          <EditableMultiLabel
                            firstName={
                              patient.details.firstName ||
                              patient.details.name?.substr(0, patient.details.name?.indexOf(' '))
                            }
                            lastName={
                              patient.details.lastName ||
                              patient.details.name?.substr(patient.details.name?.indexOf(' ') + 1)
                            }
                            firstNameTitle={'First Name'}
                            lastNameTitle={'Last Name'}
                            handleSave={handleUpdatePatientInfo}
                            contentClassName={classes.name}
                            className={classes.nameContainer}
                          />
                        </Grid>
                        <Grid item>
                          {ageValue && (
                            <PatientAge
                              age={ageValue}
                              birthdate={patient.details.birthdate}
                              onChange={handleUpdatePatientInfo}
                            />
                          )}
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item xs={12}>
                      <TagsList
                        tags={patientTags}
                        onDeleteTag={tagValue =>
                          dispatch(Actions.removePatientTag({ patientId: patient.id, tag: tagValue }))
                        }
                        canDeleteCustom={permissions.editPatientTags}
                        canDeleteScan={false}
                        showIcon={permissions.editPatientTags}
                      />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={6}>
                  <Grid container alignItems="center" justifyContent="flex-end" spacing={1}>
                    <Grid item xs={6}>
                      <Grid container className={classes.pickerContainer}>
                        <Grid item>
                          <GrinLabel fontSize={10}>{t('pages.patients.selectedPatient.header.status')}</GrinLabel>
                        </Grid>
                        <Grid item>
                          <PatientStatusUpdate
                            id="patient-room-header-status-button"
                            variant="noBorder"
                            patient={patient}
                            analyticsSource="Patient card"
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item xs={6}>
                      <Grid container className={classes.pickerContainer}>
                        <Grid item>
                          <GrinLabel fontSize={10}>{t('pages.patients.selectedPatient.header.assignedTo')}</GrinLabel>
                        </Grid>
                        <Grid item>
                          <Assignee patient={patient} assigneeTag={assigneeTag} />
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid item>
              <Grid container alignItems="center" spacing={2}>
                {patient.details?.email && !isRecordsOnly && (
                  <Grid item>
                    {communicationEmail && communicationEmail !== patient.details.email && (
                      <FieldWithIcon
                        value={patient.details.email}
                        tooltipText={t('pages.patients.selectedPatient.header.loginEmailTooltip', {
                          email: patient.details.email
                        })}
                        iconComponent={<LoginEmail />}
                      />
                    )}
                    <FieldWithIcon
                      value={communicationEmail ?? patient.details.email}
                      tooltipText={
                        communicationEmail
                          ? t('pages.patients.selectedPatient.header.contactEmailTooltip', {
                              email: communicationEmail
                            })
                          : t('pages.patients.selectedPatient.header.invitedEmailTooltip', {
                              email: communicationEmail
                            })
                      }
                      iconComponent={<Mail style={{ marginBottom: -2 }} />}
                    />
                  </Grid>
                )}
                <Grid item>
                  <EditablePhoneLabel
                    value={{ countryCode: patient.details.countryCode, phone: patient.details.phone }}
                    iconComponent={<Phone />}
                    handleSave={handleUpdatePatientInfo}
                  />
                </Grid>
                <Grid item>
                  <PatientGender gender={patient.gender} editable={!patient.isLead} onChange={onGenderUpdated} />
                </Grid>
                <Grid item>
                  <FieldWithIcon value={addressValue} iconComponent={<Location />} />
                </Grid>
                {under18 && (
                  <Grid item>
                    <LegalGuardianField
                      email={patient.details.parentEmail}
                      lastName={patient.details.parentLastName}
                      firstName={patient.details.parentFirstName}
                      handleSave={handleUpdatePatientInfo}
                      handleClickEdit={handleAddGuardianClick}
                    />
                  </Grid>
                )}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </div>
  )
}

export default PatientCardHeader
