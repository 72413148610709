import React, { useCallback } from 'react'
import { makeStyles } from '@material-ui/styles'
import { useTranslation } from 'react-i18next'
import { Grid } from '@material-ui/core'
import LinkButton from 'components/common/buttons/LinkButton'
import GrinSwitch, { GrinSwitchSizes } from 'components/common/GrinSwitch'
import { useDispatch, useSelector } from 'react-redux'
import DazzedParagraph12 from 'components/common/text/DazzedParagraph12'
import { toggleUnreadOnly } from 'components/NotificationCenter/notificationCenterSlice'
import { trackEvent } from 'utils/analyticsUtils'
import { useBulkActionMutation } from 'store/modules/NotificationCenter'
import { GrinNotificationBulkActions } from '@grin-rnd/grin-api-sdk/dist/Enums/Notifications'

const useStyles = makeStyles(theme => ({
  quickActionsRoot: {
    padding: '13px 16px 16px 16px'
  }
}))

const NotificationCenterQuickActions = ({ disableActionButtons = true }) => {
  const classes = useStyles()
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const { filters, isBulkActionInProgress } = useSelector(state => state.notificationCenterReducer)
  const [requestBulkAction] = useBulkActionMutation()

  const handleClearAllClicked = useCallback(() => {
    trackEvent(`Notification Center - clear all clicked`)
    requestBulkAction({
      actionType: GrinNotificationBulkActions.ClearAll,
      filters
    })
  }, [requestBulkAction, filters])

  const handleMarkAllAsReadClicked = useCallback(() => {
    trackEvent(`Notification Center - mark all as read clicked`)
    requestBulkAction({
      actionType: GrinNotificationBulkActions.MarkAllAsRead,
      filters
    })
  }, [requestBulkAction, filters])

  const handleToggleUnreadOnly = useCallback(
    value => {
      trackEvent(`Notification Center - unread only clicked`, { value })
      dispatch(toggleUnreadOnly(value))
    },
    [dispatch]
  )

  return (
    <Grid container alignItems="center" justifyContent="space-between" spacing={1} className={classes.quickActionsRoot}>
      <Grid item>
        <LinkButton
          fontSize={12}
          label={t('dialogs.notificationCenterDialog.quickActions.clearAll')}
          onClick={handleClearAllClicked}
          bold
          disabled={disableActionButtons || isBulkActionInProgress}
        />
      </Grid>
      <Grid item>
        <LinkButton
          fontSize={12}
          label={t('dialogs.notificationCenterDialog.quickActions.markAllAsRead')}
          onClick={handleMarkAllAsReadClicked}
          bold
          disabled={disableActionButtons || isBulkActionInProgress}
        />
      </Grid>
      <Grid item style={{ flex: 1 }} />
      <Grid item>
        <Grid container alignItems="center" spacing={1}>
          <Grid item>
            <DazzedParagraph12 thickness="strong">
              {t('dialogs.notificationCenterDialog.quickActions.unreadOnly')}
            </DazzedParagraph12>
          </Grid>
          <Grid item>
            <GrinSwitch
              checked={filters.unreadOnly}
              onClick={e => handleToggleUnreadOnly(e.target.checked)}
              size={GrinSwitchSizes.Small}
            />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  )
}

export default NotificationCenterQuickActions
