import { Grid, makeStyles } from '@material-ui/core'
import PatientDetails from 'components/common/PatientDetails'
import React, { useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import usePatientTags from 'hooks/usePatientTags'
import LinkButton from 'components/common/buttons/LinkButton'
import TxTrackerFeedbackIcon from 'components/common/icons/treatmentTracker/TxTrackerFeedbackIcon'
import { useDispatch, useSelector } from 'react-redux'
import Actions from 'actions'
import ScansNavigation from './ScansNavigation/ScansNavigation'

const useStyles = makeStyles(theme => ({
  divider: {
    display: 'block',
    width: 1,
    height: 32,
    margin: '0px 3px 0px 3px',
    backgroundColor: 'var(--text-color-22)'
  },
  feedbackIcon: {
    marginRight: 3
  }
}))

const TreatmentTrackerModalHeader = ({
  hasNextScan,
  navigateToNextScan,
  hasPreviousScan,
  navigateToPreviousScan,
  scanNumber,
  alignerNumber,
  scanCreatedAt,
  patient,
  allTxTrackerScans,
  navigateToScanByScanId
}) => {
  const classes = useStyles()
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const { selectedTimelineItemId } = useSelector(state => state.timelineReducer)

  const { tags } = usePatientTags({ patient, tagsTypes: ['treatment', 'program'] })

  const contextText = useMemo(() => {
    const baseText = 'Tx tracker general feedback'

    return `${baseText} for timeline item: ${selectedTimelineItemId}:`
  }, [selectedTimelineItemId])

  const handleGiveFeedbackClicked = useCallback(() => {
    dispatch(
      Actions.toggleAppFeedbackDialog({
        value: true,
        title: t('dialogs.treatmentTracker.feedback.title'),
        description: t('dialogs.treatmentTracker.feedback.description'),
        inputPlaceholder: t('dialogs.treatmentTracker.feedback.placeholder'),
        displayNote: false,
        contextText
      })
    )
  }, [contextText, dispatch, t])

  return (
    <>
      <Grid container alignItems="flex-end" justifyContent="space-between">
        <Grid item>
          <Grid container alignItems="center" spacing={1}>
            <Grid item>
              <ScansNavigation
                scanNumber={scanNumber}
                alignerNumber={alignerNumber}
                scanCreatedAt={scanCreatedAt}
                nextDisabled={!hasNextScan}
                onNextClicked={navigateToNextScan}
                previousDisabled={!hasPreviousScan}
                onPreviousClicked={navigateToPreviousScan}
                allTxTrackerScans={allTxTrackerScans}
                navigateToScanByScanId={navigateToScanByScanId}
              />
            </Grid>
            <Grid item>
              <span className={classes.divider} />
            </Grid>
            <Grid item>
              <PatientDetails name={patient?.name} photo={patient?.photo} program={patient?.program} tags={tags} />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={4}>
          <LinkButton
            spacing={0}
            label={t('dialogs.treatmentTracker.header.feedbackLabel')}
            icon={<TxTrackerFeedbackIcon />}
            iconContainerClassName={classes.feedbackIcon}
            onClick={handleGiveFeedbackClicked}
          />
        </Grid>
      </Grid>
    </>
  )
}

export default TreatmentTrackerModalHeader
