import React, { useMemo } from 'react'
import { makeStyles } from '@material-ui/styles'
import PatientsChat from '../Chat/PatientsChat'
import PatientSidebar from './PatientSidebar/PatientSidebar'
import { PatientSidebarItemKeys } from './PatientSidebar/patientSidebarConsts'
import { useSelector } from 'react-redux'
import Notes from './Notes/Notes'
import usePatientSwitchableView from './usePatientSwitchableView'

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    height: '100%',
    borderLeft: '1px solid var(--border-color-7)'
  },
  activeTab: {
    width: '100%',
    paddingRight: 'var(--patients-side-bar-collapsed-width)'
  }
}))

const PatientSwitchableView = () => {
  const classes = useStyles()
  usePatientSwitchableView()

  const { activeItem } = useSelector(state => state.patientSidebarReducer)

  const viewsByItemKey = useMemo(
    () => ({
      [PatientSidebarItemKeys.Chat]: PatientsChat,
      [PatientSidebarItemKeys.Notes]: Notes
    }),
    []
  )

  return (
    <div className={classes.root}>
      <div className={classes.activeTab}>
        {React.createElement(viewsByItemKey[activeItem], { hideLeftBorder: true })}
      </div>
      <PatientSidebar />
    </div>
  )
}

export default PatientSwitchableView
