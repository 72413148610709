import React, { useEffect, useRef } from 'react'
import Message from './Message'
import TextAreaAutosize from 'components/common/TextareaAutosize'
import { Grid, makeStyles } from '@material-ui/core'
import { scrollBar } from 'styles/theme'
import { Check2, Close1 } from 'components/common/icons'
import DazzedParagraph14 from 'components/common/text/DazzedParagraph14'
import { useTranslation } from 'react-i18next'

const useStyles = makeStyles(theme => ({
  input: {
    border: 'none !important',
    outline: 'none',
    fontFamily: 'Dazzed',
    fontSize: '14px !important',
    fontWeight: 500,
    padding: '0 !important',
    margin: '0 !important',
    backgroundColor: '#F3F3F4 !important',
    ...scrollBar
  },
  editAction: {
    cursor: 'pointer'
  },
  label: {
    textDecoration: 'underline',
    userSelect: 'none'
  },
  icon: {
    marginRight: 5
  }
}))

const NoteBody = ({ isEditMode, message, setMessage, assigneeName, onMessageSaved, onMessageDiscarded }) => {
  const classes = useStyles()
  const { t } = useTranslation()
  const noteContentRef = useRef(null)

  useEffect(() => {
    if (isEditMode) {
      const end = noteContentRef.current.value.length
      noteContentRef.current.focus()
      noteContentRef.current.setSelectionRange(end, end)
    }
  }, [isEditMode])

  return isEditMode ? (
    <Grid container direction="column">
      <Grid item style={{ marginLeft: 48 }}>
        <TextAreaAutosize
          ref={noteContentRef}
          rowsMax={6}
          style={{ bright: true }}
          className={classes.input}
          keepErrorContainerWhenInactive={false}
          value={message}
          setValue={setMessage}
        />
      </Grid>
      <Grid item xs={12} style={{ marginRight: 5 }}>
        <Grid container justifyContent="flex-end" alignItems="center" spacing={1}>
          <Grid item onClick={onMessageDiscarded} className={classes.editAction}>
            <Grid container alignItems="center">
              <Grid item className={classes.icon}>
                <Close1 className={classes.editNoteActionIcon} />
              </Grid>
              <Grid item>
                <DazzedParagraph14 strong color="var(--text-color-3)" className={classes.label}>
                  {t('general.cancel')}
                </DazzedParagraph14>
              </Grid>
            </Grid>
          </Grid>
          <Grid item onClick={onMessageSaved} className={classes.editAction}>
            <Grid container alignItems="center">
              <Grid item className={classes.icon}>
                <Check2 color="var(--text-color-3)" width={13} height={9} className={classes.editNoteActionIcon} />
              </Grid>
              <Grid item>
                <DazzedParagraph14 strong color="var(--text-color-3)" className={classes.label}>
                  {t('general.done')}
                </DazzedParagraph14>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  ) : (
    <Message message={message} assigneeName={assigneeName} />
  )
}

export default NoteBody
