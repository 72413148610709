import { NotificationCenterTabs } from 'consts/notificationCenterConsts'
import { useDispatch, useSelector } from 'react-redux'
import { switchTab } from '../notificationCenterSlice'
import { useCallback, useMemo } from 'react'
import { trackEvent } from 'utils/analyticsUtils'

export default () => {
  const dispatch = useDispatch()
  const { selectedTab } = useSelector(state => state.notificationCenterReducer)

  const handleSwitchTab = useCallback(
    ({ tab }) => {
      trackEvent(`Notification Center - tab switched`, { newTab: tab, previousTab: selectedTab })
      dispatch(switchTab({ tab }))
    },
    [dispatch, selectedTab]
  )

  const tabs = useMemo(
    () =>
      [
        {
          id: NotificationCenterTabs.All,
          condition: true
        },
        {
          id: NotificationCenterTabs.NeedsAttention,
          condition: true
        },
        {
          id: NotificationCenterTabs.Scans,
          condition: true
        },
        {
          id: NotificationCenterTabs.Messages,
          condition: true
        },
        {
          id: NotificationCenterTabs.Assigned,
          condition: true
        },
        {
          id: NotificationCenterTabs.NewPatients,
          condition: true
        }
      ].filter(t => t.condition),
    []
  )

  return {
    tabs,
    selectedTab,
    handleSwitchTab
  }
}
