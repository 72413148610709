import { Auth } from 'aws-amplify'
import jwt_decode from 'jwt-decode'
import { intersection } from 'lodash-es'
import {
  AdminRoleGroups,
  EIGHT_PLUS_CHARACTERS,
  INVALID_PASSWORD,
  ONE_LOWER_CASE_LETTER,
  ONE_NUMBER,
  ONE_SPECIAL_CHARACTER,
  ONE_UPPER_CASE_LETTER,
  PASSWORD_CONFIRMATION_DONT_MATCH,
  PracticeRoles,
  RolePermissionsMap,
  Roles
} from '../consts/authConsts'
import i18n from '../resources/locales/i18n'
import { getEnvironment } from './awsUtils'
import { logError } from './logUtils'
import { getIdTokenFromStorage } from './storageUtils'

const eightPlusCharactersReg = /.{8,}$/
const oneUpperCaseLetterReg = /(?=.*?[A-Z])/
const oneNumberReg = /(?=.*?[0-9])/
const oneLowerCaseLetterReg = /(?=.*?[a-z])/
const oneSpecialCharacterReg = /(?=.*?[!"#$%&'()*+,-./:;<=>?@[\\\]^_`{|}~])/

export const validatePasswords = (password, passwordConfirmation) => {
  if (
    password.includes(' ') ||
    !/^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[!"#$%&'()*+,-./:;<=>?@\\[\\\]^_`{|}~]).{8,}$/.test(password)
  ) {
    return {
      errors: {
        password: INVALID_PASSWORD
      }
    }
  } else if (password !== passwordConfirmation) {
    return {
      errors: {
        passwordConfirmation: PASSWORD_CONFIRMATION_DONT_MATCH
      }
    }
  }

  return {
    errors: null
  }
}

export const PASSWORD_REQUIREMENTS = [
  {
    code: EIGHT_PLUS_CHARACTERS,
    title: i18n.t('common.passwordStrength.characters')
  },
  {
    code: ONE_UPPER_CASE_LETTER,
    title: i18n.t('common.passwordStrength.uppercase')
  },
  {
    code: ONE_NUMBER,
    title: i18n.t('common.passwordStrength.number')
  },
  {
    code: ONE_LOWER_CASE_LETTER,
    title: i18n.t('common.passwordStrength.lower')
  },
  {
    code: ONE_SPECIAL_CHARACTER,
    title: i18n.t('common.passwordStrength.special')
  }
]

export const validatePasswordDetails = password => {
  const eightPlusCharacters = eightPlusCharactersReg.test(password)
  const oneUpperCaseLetter = oneUpperCaseLetterReg.test(password)
  const oneNumber = oneNumberReg.test(password)
  const oneLowerCaseLetter = oneLowerCaseLetterReg.test(password)
  const oneSpecialCharacter = oneSpecialCharacterReg.test(password)

  return {
    isValid:
      eightPlusCharacters &&
      oneUpperCaseLetter &&
      oneNumber &&
      oneLowerCaseLetter &&
      oneSpecialCharacter &&
      !password.includes(' '),
    [EIGHT_PLUS_CHARACTERS]: eightPlusCharacters,
    [ONE_UPPER_CASE_LETTER]: oneUpperCaseLetter,
    [ONE_NUMBER]: oneNumber,
    [ONE_LOWER_CASE_LETTER]: oneLowerCaseLetter,
    [ONE_SPECIAL_CHARACTER]: oneSpecialCharacter
  }
}

export const changeAuthForm = payload => {
  return Array.isArray(payload)
    ? payload.reduce((o, e) => ({ ...o, [e.key]: e.value }), {})
    : { [payload?.key]: payload?.value }
}

export const isUserOfRole = (roles, decodedToken = null) => {
  let idToken

  if (decodedToken) {
    idToken = decodedToken
  } else {
    const undecodedToken = getIdTokenFromStorage()

    if (undecodedToken) {
      idToken = jwt_decode(undecodedToken)
    } else {
      return false
    }
  }

  if (idToken) {
    return roles.find(role =>
      idToken['cognito:groups']
        ?.map(cognitoGroup => cognitoGroup.toLocaleLowerCase())
        .includes(role.toLocaleLowerCase())
    )
  }
  return false
}

export const getUserGroups = (jwtToken = '') => {
  const idToken = jwtToken || getIdTokenFromStorage()
  if (!idToken) {
    return []
  }

  const token = jwt_decode(idToken)
  return token['cognito:groups']
}

export const isAnalystUser = () => getUserGroups().find(group => group.includes('Analysts'))

export const isUserOfAnyAdminRole = () => {
  const adminGroups = AdminRoleGroups.map(roleGroup => roleGroup.toLocaleLowerCase())
  return (
    getUserGroups()
      .map(group => group.toLocaleLowerCase())
      .filter(group => adminGroups.find(roleGroup => roleGroup === group)).length > 0
  )
}

export const isDevAdmin = () => getUserGroups().includes(Roles.DevAdmin)

export const isUserOfAnyPracticeRole = () => {
  const roles = PracticeRoles.map(roleGroup => roleGroup.toLocaleLowerCase())
  return (
    getUserGroups()
      .map(group => group.toLocaleLowerCase())
      .filter(group => roles.find(roleGroup => roleGroup === group)).length > 0
  )
}

export const isMfaRequiredForCurrentUser = () => {
  const userGroups = getUserGroups().map(group => group.toLocaleLowerCase())

  const requiredRolesByEnv = {
    production: [Roles.Admin, Roles.DevAdmin, Roles.BillingAdmin, Roles.OpsAdmin].map(r => r.toLocaleLowerCase()),
    staging: [Roles.BillingAdmin, Roles.OpsAdmin].map(r => r.toLocaleLowerCase())
  }

  const env = getEnvironment() || 'staging'

  return userGroups.some(group => requiredRolesByEnv[env].includes(group))
}

export const evaluateRolePermissions = (userGroups = []) => {
  if (!userGroups || !userGroups.length) {
    userGroups = getUserGroups()
  }

  // We begin iterating through the entries of RolePermissionsMap.
  // If the value is an object, we evaluate it recursively.

  const evaluatePermissionsDict = dict =>
    Object.keys(dict).reduce((acc, curr, i, featuresList) => {
      const featureKey = featuresList[i]
      const isNestedDict = typeof dict[featureKey] === 'object' && !(dict[featureKey] instanceof Array)
      const isCallback = typeof dict[featureKey] === 'function'
      return {
        ...acc,
        [featureKey]: isNestedDict
          ? evaluatePermissionsDict(dict[featureKey])
          : isCallback
          ? dict[featureKey](userGroups) || false
          : intersection(dict[featureKey], userGroups).length > 0
      }
    }, {})

  return evaluatePermissionsDict(RolePermissionsMap)
}

export const exportRolesPermissionsToCSV = () => {
  const headers = ['', ...Object.keys(Roles).sort((a, b) => (Roles[a] > Roles[b] ? 1 : -1))]

  let csv = Object.keys(RolePermissionsMap).map(capability =>
    [
      capability,
      ...Object.values(Roles)
        .sort((a, b) => (a > b ? 1 : -1))
        .map(role => {
          const roleCapability = RolePermissionsMap[capability]

          return !!roleCapability.includes ? (roleCapability.includes(role) ? 'V' : 'X') : '?'
        })
    ].join(',')
  )
  csv.unshift(headers.join(',')) // add header column
  csv = csv.join('\r\n')

  const res = 'data:text/csv;charset=utf-8,' + csv
  var encodedUri = encodeURI(res)
  var link = document.createElement('a')
  link.setAttribute('href', encodedUri)
  link.setAttribute('download', 'role_capabilities.csv')
  document.body.appendChild(link) // Required for FF

  link.click()
}

export const validateTokenExpiration = token => {
  if (!token) {
    return null
  }

  const decodedToken = jwt_decode(token)

  if (Date.now() >= decodedToken.exp * 1000) {
    return null
  }

  return token
}

export const getParsedIdToken = () => jwt_decode(getIdTokenFromStorage())

export const getIdToken = async () => {
  try {
    const authUser = await Auth.currentAuthenticatedUser()

    const signInUserSession = await authUser.getSignInUserSession()

    return signInUserSession.idToken.jwtToken
  } catch (error) {
    logError('An error occured while trying to get user id token')
    return null
  }
}
