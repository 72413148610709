import { GrinNotificationTypes } from '@grin-rnd/grin-api-sdk/dist/Enums/Notifications'
import BaseGrinNotification from './BaseGrinNotification/BaseGrinNotification'
import { logError } from 'utils/logUtils'
import NewMessageNotification from './NewMessageNotification'

const notificationComponentByType = {
  [GrinNotificationTypes.NewMessage]: NewMessageNotification
}

const factory = notificationType => {
  const notificationComponent = notificationComponentByType[notificationType]
  if (!notificationComponent) {
    logError(`Notification center - missing notification component for type: ${notificationType}`)
    return BaseGrinNotification
  }

  return notificationComponent
}

export default factory
