import React, { useMemo } from 'react'
import { makeStyles } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import { urlRegex } from 'utils/stringUtils'
import MessageTypeWrapper from '../MessageTypeWrapper'

const useStyles = makeStyles(({ alignLeft, isFirstMessage }) => ({
  bubble: {
    padding: '8px 15px 5px 15px !important'
  },
  timestamp: {
    marginTop: 5,
    fontSize: 10,
    textAlign: 'right',
    color: 'rgba(0, 19, 53, 0.45)'
  }
}))

const TextMessage = ({
  id,
  content,
  alignLeft,
  isDeleted,
  name,
  userType,
  timestamp,
  isFirstMessage,
  isLastMessage,
  isBroadcast,
  backgroundColor,
  metadata,
  lastEditedAt,
  className = '',
  withMessageActions
}) => {
  const { t } = useTranslation()

  const classes = useStyles({ alignLeft, isFirstMessage })

  const handledContent = useMemo(() => {
    return content?.split(urlRegex)
  }, [content])

  return (
    <MessageTypeWrapper
      id={id}
      isFirstMessage={isFirstMessage || metadata?.isGiAutomatedMessage} // In case this is GI automated message to the patient, we want to show the practice how it looks
      isLastMessage={isLastMessage}
      alignLeft={alignLeft}
      name={name}
      userType={userType}
      className={[classes.bubble, className].join(' ')}
      isBroadcast={isBroadcast}
      backgroundColor={backgroundColor}
      lastEditedAt={lastEditedAt}
      withMessageActions={withMessageActions}
    >
      {handledContent
        .filter(word => word)
        .map((word, i) =>
          word.match(urlRegex) ? (
            <a key={word + i} href={word} target="_blank" rel="noopener noreferrer">
              {word}
            </a>
          ) : (
            <span key={word + i}>{word}</span>
          )
        )}
      <div className={classes.timestamp}>
        {lastEditedAt ? `${t('pages.patients.selectedPatient.chat.edited')} • ` : ''}
        {timestamp}
      </div>
    </MessageTypeWrapper>
  )
}

export default TextMessage
