import React, { useCallback } from 'react'
import useLoadGrinNotifications from './useLoadGrinNotifications'
import NotificationCenterEmptyState from './States/NotificationCenterEmptyState'
import NotificationCenterLoadingState from './States/NotificationCenterLoadingState'
import NotificationCenterErrorState from './States/NotificationCenterErrorState'
import { trackEvent } from 'utils/analyticsUtils'
import NotificationsFeed from './NotificationsFeed'

const NotificationCenterContent = () => {
  const { isLoading, isError, hasData, refetch } = useLoadGrinNotifications()

  const handleRetry = useCallback(async () => {
    trackEvent(`Notification Center - error state - retry clicked`)
    await refetch()
  }, [refetch])

  return (
    <div style={{ height: '100%' }}>
      {isError ? (
        <NotificationCenterErrorState onRetry={handleRetry} />
      ) : hasData ? (
        <NotificationsFeed />
      ) : isLoading ? (
        <NotificationCenterLoadingState />
      ) : (
        <NotificationCenterEmptyState />
      )}
    </div>
  )
}

export default NotificationCenterContent
