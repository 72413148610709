import { makeStyles, useMediaQuery } from '@material-ui/core'
import React, { useEffect, useMemo } from 'react'
import PatientsChat from './Chat/PatientsChat'
import TimelineV2 from './Timeline/V2/Timeline'
import PatientsHeader from './PatientsHeader/PatientsHeader'
import useHideHelpWidget from 'hooks/useHideHelpWidget'
import { isEmpty } from 'lodash'
import ChatEmptyState from './Chat/ChatEmptyState/ChatEmptyState'
import ChatSkeletonV2 from './Timeline/V2/Skeletons/ChatSkeleton'
import { useDispatch, useSelector } from 'react-redux'
import Actions from 'actions'
import useFeatureFlags from 'hooks/useFeatureFlags'
import PatientSwitchableView from './PatientSwitchableView/PatientSwitchableView'

const useStyles = ({ isFilterOpen }) =>
  makeStyles({
    treatmentContainer: {
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
      backgroundColor: 'white'
    },
    chatAndMilestonesDivider: {
      display: 'flex',
      flexDirection: 'row',
      height: `calc(100% - var(--patient-header-height))`,
      position: 'relative',
      backgroundColor: 'var(--bg-color-timelineV2)'
    },
    shade: {
      position: 'absolute',
      left: 0,
      top: 0,
      width: '100%',
      height: '100%',
      backgroundColor: '#333333',
      opacity: isFilterOpen ? '.2' : 0,
      zIndex: 50,
      transition: '.2s',
      pointerEvents: 'none'
    },
    emptyStateContainer: {
      width: '100%'
    }
  })({})

const Patient = ({ isFilterOpen }) => {
  const dispatch = useDispatch()
  useHideHelpWidget()
  const { grinDesignRefresh: grinDesignRefreshFF } = useFeatureFlags()

  const xl = useMediaQuery('(max-width:2560px)') // Check if the viewport max-width is *up to* 2560px
  const { patient, isLoadingPatient, requestedPatientId } = useSelector(state => state.patientsReducer)

  const classes = useStyles({ isFilterOpen })

  const isPatientNotFound = useMemo(() => requestedPatientId && isEmpty(patient), [patient, requestedPatientId])

  useEffect(() => {
    const patientId = patient?.id
    if (patientId) {
      dispatch(
        Actions.subscribeToContextNotifications({
          context: `patient/${patientId}`
        })
      )

      return () =>
        dispatch(
          Actions.unsubscribeFromContextNotifications({
            context: `patient/${patientId}`
          })
        )
    }
  }, [dispatch, patient?.id])

  return (
    <div className={classes.treatmentContainer}>
      <PatientsHeader />
      <div className={classes.chatAndMilestonesDivider}>
        {isPatientNotFound && !isLoadingPatient ? (
          <div className={classes.emptyStateContainer}>
            <ChatEmptyState showNoPatientSelected />
          </div>
        ) : (
          <>
            <div style={{ width: xl ? '55%' : '50%' }}>
              <TimelineV2 />
            </div>
            <div style={{ width: xl ? '45%' : '50%' }}>
              {isLoadingPatient ? (
                <ChatSkeletonV2 />
              ) : grinDesignRefreshFF ? (
                <PatientSwitchableView />
              ) : (
                <PatientsChat />
              )}
            </div>
          </>
        )}
        <div className={classes.shade} />
      </div>
    </div>
  )
}

export default React.memo(Patient)
