import React from 'react'
import { makeStyles } from '@material-ui/styles'
import { ButtonBase } from '@material-ui/core'
import ArrowRight from 'components/common/icons/timelineV2/scan-feed/ArrowRight'
import ArrowLeft from 'components/common/icons/timelineV2/scan-feed/ArrowLeft'

const useStyles = makeStyles(theme => ({
  tabScrollButtonRoot: {
    minWidth: '20px',
    textAlign: 'center'
  }
}))

const TabScrollButton = ({ direction, disabled, onClick }) => {
  const classes = useStyles()

  return (
    <ButtonBase onClick={onClick} className={classes.tabScrollButtonRoot} disabled={disabled} ripple={false}>
      {disabled ? <></> : direction === 'left' ? <ArrowLeft /> : <ArrowRight />}
    </ButtonBase>
  )
}

export default TabScrollButton
