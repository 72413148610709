import _ from 'lodash'
import { Grid, makeStyles } from '@material-ui/core'
import Actions from 'actions'
import PrimaryButton from 'components/common/buttons/PrimaryButton'
import SecondaryButton from 'components/common/buttons/SecondaryButton'
import LeaveModal from 'components/common/modals/LeaveModal'
import usePracticeConfiguration from 'hooks/usePracticeConfiguration'
import { isEqual } from 'lodash'
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { trackEvent } from 'utils/analyticsUtils'
import { removePlus } from 'utils/stringUtils'
import Contact from './Contact'
import Preference from './Preference'
import useRolePermissions from 'hooks/useRolePermissions'
import DazzedParagraph16 from 'components/common/text/DazzedParagraph16'
import DazzedParagraph12 from 'components/common/text/DazzedParagraph12'
import usePlanLimits from 'hooks/usePlanLimits'
import useFeatureFlags from 'hooks/useFeatureFlags'
import { NotificationMediums } from '@grin-rnd/grin-api-sdk/dist/Enums/Notifications'

const NotificationSettings = () => {
  const classes = makeStyles({
    content: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-start',
      paddingBottom: 20,
      paddingRight: 20,
      width: '700px'
    },
    title: {
      color: 'black',
      fontSize: 40,
      lineHeight: '40px',
      fontFamily: 'Ogg',
      textAlign: 'center',
      marginBottom: 50
    },
    subscription: {
      width: '100%',
      display: 'flex',
      flexDirection: 'column'
    },
    line: {
      color: 'var(--text-color-41)',
      backgroundColor: 'var(--bg-color-54)',
      height: 0.5,
      borderColor: 'var(--border-color-25)',
      marginTop: 20,
      marginBottom: 20,
      width: '100%'
    },
    contacts: {
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
      marginTop: 30
    },
    contactsNote: {
      display: 'flex',
      justifyContent: 'space-between',
      marginBottom: 20
    },
    contactsNoteText: {
      marginRight: 15
    },
    saveButtonContainer: {
      position: 'sticky',
      width: '100%',
      bottom: 0,
      padding: '10px 0 20px 0',
      backgroundColor: 'white'
    }
  })()

  const dispatch = useDispatch()
  const { t } = useTranslation()
  const { notificationCenter: notificationCenterFF } = useFeatureFlags()
  const { isRcFFEnabled } = usePracticeConfiguration()
  const { permissions, isDsoUser } = useRolePermissions()
  const { isHI: isHiPractice } = usePlanLimits()

  const preferences = useSelector(state => state.profileReducer.doctor.user?.messagingPreferences)
  const isLoading = useSelector(state => state.profileReducer.isLoading)

  const [entitiesSubscriptions, setEntitiesSubscriptions] = useState(null)
  const [contacts, _setContacts] = useState(null)
  const [errorIndexes, setErrorIndexes] = useState([])
  const contactsRef = useRef(contacts)
  const setContacts = x => {
    contactsRef.current = x
    _setContacts(x)
  }
  const [canAddContact, setCanAddContact] = useState(
    preferences?.contacts != null ? preferences.contacts.length < 3 : true
  )

  const ALL_PREFERENCES_OPTIONS_SORTED = useMemo(
    () => ({
      NeedsAttention: {
        sortValue: 1,
        // DSO users are not any plan so it makes no sense to use isHiPractice.
        // However they still need the option to receive "nneeds attention" notifications.
        condition: isHiPractice || isDsoUser
      },
      Patient: {
        sortValue: 2,
        condition: true
      },
      Scan: {
        sortValue: 3,
        condition: true
      },
      Message: {
        sortValue: 4,
        condition: true
      },
      Assignee: {
        sortValue: 5,
        condition: permissions.notificationSettings.preferenceOptions.assignee
      },
      RCLead: {
        sortValue: 6,
        condition: permissions.notificationSettings.preferenceOptions.RCLead ? isRcFFEnabled : true
      },
      ServiceAccountNewLead: {
        sortValue: 7,
        condition: true
      }
    }),
    [isDsoUser, isHiPractice, isRcFFEnabled, permissions]
  )

  const mediumOptions = useMemo(
    () =>
      [
        NotificationMediums.Email,
        NotificationMediums.SMS,
        notificationCenterFF ? NotificationMediums.GrinNotification : null
      ].filter(m => !!m),
    [notificationCenterFF]
  )

  const preferenceOptionsFiltered = useMemo(
    () =>
      Object.keys(ALL_PREFERENCES_OPTIONS_SORTED)
        .map(
          entityType =>
            entitiesSubscriptions?.find(e => e.type === entityType) || { type: entityType, medium: [], filters: [] }
        )
        .filter(entity => ALL_PREFERENCES_OPTIONS_SORTED[entity.type]?.condition),
    [entitiesSubscriptions, ALL_PREFERENCES_OPTIONS_SORTED]
  )

  const isDataUnsaved = useMemo(
    () =>
      preferences &&
      (!isEqual(preferences.entities, entitiesSubscriptions) || !isEqual(preferences.contacts, contacts)),
    [contacts, entitiesSubscriptions, preferences]
  )

  useEffect(() => {
    setEntitiesSubscriptions(preferences?.entities)
    setContacts(preferences?.contacts)
  }, [preferences])

  const isEmailInvalid = email => !email || !email.includes('@')

  const validateContacts = useCallback(() => {
    let val = true
    const indexes = []
    contacts &&
      contacts.forEach((contact, index) => {
        const invalidEmail = isEmailInvalid(contact.email)
        const invalidPhone = !contact.phone

        if (invalidEmail && invalidPhone) {
          indexes.push(index)
          val = false
        }
      })

    !val && setErrorIndexes(indexes)
    return val
  }, [contacts])

  const handleUpdateSubscriptions = useCallback(
    updatedEntity => {
      const updatedEntitiesSubscriptions = Object.keys(ALL_PREFERENCES_OPTIONS_SORTED)
        .map(
          preference =>
            entitiesSubscriptions.find(e => e.type === preference) || { type: preference, medium: [], filters: [] }
        )
        .map(currentEntity =>
          currentEntity.type === updatedEntity.type ? { ...updatedEntity, medium: updatedEntity.medium } : currentEntity
        )

      setEntitiesSubscriptions(updatedEntitiesSubscriptions)
    },
    [entitiesSubscriptions, ALL_PREFERENCES_OPTIONS_SORTED]
  )

  const saveChanges = useCallback(() => {
    if (validateContacts()) {
      const patchedSubscriptions = entitiesSubscriptions.map(sub =>
        sub.medium.length ? sub : { ...sub, medium: [''] }
      )

      trackEvent('Notifications settings - messaging preferences updated', {
        entities: patchedSubscriptions
      })
      dispatch(
        Actions.requestUpdateMessagingPreferences({
          id: preferences.id,
          _version: preferences._version,
          entities: _.uniqBy(patchedSubscriptions, 'type'),
          contacts: contacts?.length ? contacts : null
        })
      )
    }
  }, [entitiesSubscriptions, preferences, dispatch, contacts, validateContacts])

  const addContact = () => {
    setCanAddContact(!contacts || contacts.length < 2)
    setContacts([...(contacts ?? []), { countryCode: '1' }])
  }

  const updateContactValue = (index, key, value) => {
    setErrorIndexes([])
    setContacts(
      contactsRef.current.map((contact, i) =>
        i === index && !(key === 'phone' && value === contact.countryCode)
          ? {
              ...contact,
              [key]: key === 'phone' ? value.substring(removePlus(contact.countryCode).length) : value
            }
          : contact
      )
    )
  }

  const deleteContact = i => {
    const filteredItems = contactsRef.current.filter(item => item !== contactsRef.current[i])
    setContacts(filteredItems)
    setCanAddContact(true)
  }

  return entitiesSubscriptions ? (
    <>
      <LeaveModal blockNavigation={isDataUnsaved} />
      <div className={classes.content}>
        <div className={classes.title}>{t('pages.accountSettings.notificationSettings.title')}</div>
        <Grid container>
          <Grid item xs={6}>
            <DazzedParagraph16 bold>
              {t('pages.accountSettings.notificationSettings.pleaseNotifyLabel')}
            </DazzedParagraph16>
          </Grid>
          {mediumOptions.map(medium => (
            <Grid item xs={2} key={`medium-${medium}`}>
              <DazzedParagraph12 thickness="bold" textAlign="center">
                {t(`pages.accountSettings.notificationSettings.mediumOptionLabels.${medium}`)}
              </DazzedParagraph12>
            </Grid>
          ))}
        </Grid>
        <div className={classes.line} />
        {preferenceOptionsFiltered.map((entity, i) => (
          <div key={i} className={classes.subscription}>
            <Preference
              key={entity.type}
              entity={entity}
              updateSubscriptions={handleUpdateSubscriptions}
              withGrinNotificationOption={notificationCenterFF}
            />
            <div className={classes.line} />
          </div>
        ))}
        <div className={classes.contacts}>
          <div className={classes.contactsNote}>
            <div className={classes.contactsNoteText}>
              {t('pages.accountSettings.notificationSettings.contactsNotice')}
            </div>
            {canAddContact && <SecondaryButton label="+ Add" width="94px" height="48px" onClick={addContact} />}
          </div>
          {contacts &&
            contacts.map((contact, index) => (
              <Contact
                index={index}
                contact={contact}
                error={
                  errorIndexes.includes(index)
                    ? t('pages.accountSettings.notificationSettings.fillValidEmailOrPhoneError')
                    : null
                }
                updateContactValue={updateContactValue}
                deleteContact={deleteContact}
                key={index}
              />
            ))}
        </div>
        <div className={classes.saveButtonContainer}>
          <PrimaryButton
            label="Save"
            width="94px"
            height="48px"
            onClick={saveChanges}
            isLoading={isLoading}
            disabled={isLoading || !isDataUnsaved}
          />
        </div>
      </div>
    </>
  ) : (
    <div></div>
  )
}

export default NotificationSettings
